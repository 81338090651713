<template>
    <div>
        <div class="main-title">题库列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">题库添加修改可在excel操作</li>
                    </ul>
                </div>
            </div>
            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" aria-label="题库名称">
                   <el-input v-model="name" placeholder="请输入课程名称"></el-input>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
					<el-button type="primary" size="small" @click="addQuestionBank()">添加</el-button>
                    <el-button type="primary" size="small" plain icon="el-icon-upload2" :loading="importing" @click="importQuestion">题库导入</el-button>
					<cover  ref="importFile"  style="display: none" uploadPath="import" @success="uploadSuccess"></cover>
                </span>

            </div>

            <div class="table-content">
                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                        label="题库名称"
                        property="name"

                    >
                    </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            width="300">
                    </el-table-column>
					<el-table-column
					        property="updated_at"
					        label="更新时间"
					        width="300">
					</el-table-column>
                    <el-table-column
                        label="操作员"
                        property="user.name"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column
                            property="address"
                            width="300"
                            label="操作">
                        <template slot-scope="scope">
                            <span style="margin-right: 1rem;">
                              <el-link slot="reference" type="primary" @click="showDetail(scope.row.id)">预览</el-link>
                            </span>
						  <span style="margin-right: 1rem;">
							  <el-link slot="reference" type="primary" @click="exportData(scope.row.id,scope.row.name)">导出</el-link>
						  </span>
                            <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row.id)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch" >
                <span class="fl-l" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Cover from "../../../components/cover"
    export default {
        name: "list",
		components:{ Cover },
        data(){
            return {
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                name: '',
                tableData: [],
                currentRow: null,
				importing:false,
            }
        },
      created() {
          this.getList();
      },
      methods:{
		    ...mapActions('common',['importTask',]),
            ...mapActions('questionBank',['getQuestionBankList','delQuestionBank','exportQuestionBank']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            // 获得列表
            async getList(){
              this.loading = true
              var vm = this
              const { res_info, data } = await this.getQuestionBankList({ name:vm.name ,page: vm.page, pageSize: vm.pageSize});
              this.loading = false

              if (res_info !== 'ok') return

              this.total = data.total
              this.tableData = data.list
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            // 预览
            showDetail(val){
              this.$router.push({ path: '/question/detail', query: {id : val}})
            },
           // 删除
            async deleted(val){
              const { res_info, data } = await this.delQuestionBank(val)
              if (res_info !== 'ok') return
              this.$message.success('删除成功!')
              await this.getList()
            },
            exportData(id,name){
              this.exportQuestionBank({id:id,name:name});
            },
			importQuestion(){
			    console.log( this.$refs.importFile)
			    this.$refs.importFile.$el.getElementsByClassName('el-upload el-upload--picture-card')[0].click()
			},
			uploadSuccess(file){
			    this.importing = true
			    let data = {
			        data:file,
			        name:'题库导入',
			        type:'importQuestionBank'
			    }
				var vm = this;
			    vm.importTask(data).then(res => {
			        vm.$message.success('导入成功！')
			        vm.$refs.importFile.$refs.fileList.clearFiles(),
					setTimeout(function(){
						vm.getList()
					},5000);
			    }).finally(()=>{
			        vm.importing = false
			    })
			},
			addQuestionBank(){
				this.$router.push('/question/add')
			}
        }
    }
</script>

<style>

</style>
