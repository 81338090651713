import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'

import { Message } from 'element-ui'

// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: () => import('../Index'),
		children: [
			{
				path: '/',
				component: () => import('../views/other/work/list')
			},
			{
				path: '/slideShow',
				component: () => import('../views/setting/slideShow/list')
			},
			{
				path: '/slideShow/add',
				component: () => import('../views/setting/slideShow/add')
			},
			{
				path: '/excerpt',
				component: () => import('../views/content/excerpt/list')
			},
			{
				path: '/excerpt/add',
				component: () => import('../views/content/excerpt/add')
			},
			{
				path: '/excerpt/edit/:id',
				component: () => import('../views/content/excerpt/edit')
			},
			{
				path: '/fileDownload',
				component: () => import('../views/content/fileDownload/list')
			},
			{
				path: '/fileDownload/add',
				component: () => import('../views/content/fileDownload/add')
			},
			{
				path: '/fileDownload/edit/:id',
				component: () => import('../views/content/fileDownload/edit')
			},
			{
				path: '/file',
				component: () => import('../views/resource/file/list')
			},
			{
				path: '/file/add',
				component: () => import('../views/resource/file/add')
			},
			{
				path: '/video',
				component: () => import('../views/resource/video/list')
			},
			{
				path: '/video/add',
				component: () => import('../views/resource/video/add')
			},
			{
				path: '/work',
				component: () => import('../views/other/work/list')
			},
			{
				path: '/userList',
				component: () => import('../views/user/userList/list')
			},
			{
				path: "/transfer",
				component: () => import("../views/user/transfer/list"),
			  },
			{
				path: '/userSocial',
				component: () => import('../views/user/userSocial/list')
			},
			{
				path: '/userSocial/detail/:id',
				component: () => import('../views/user/userSocial/detail')
			},
			{
				path: '/question',
				component: () => import('../views/resource/question/list')
			},
			{
				path: '/question/detail',
				component: () => import('../views/resource/question/detail')
			},
			{
				path: '/question/add',
				component: () => import('../views/resource/question/add')
			},
			{
				path: '/course',
				component: () => import('../views/classroom/course/list')
			},
			{
				path: '/course/add',
				component: () => import('../views/classroom/course/add')
			},
			{
				path: '/course/edit/:id',
				component: () => import('../views/classroom/course/edit')
			},
			{
				path: '/course/editClass/courseId/:courseId/classId/:classId',
				component: () => import('../views/classroom/course/editClass')
			},
			{
				path: '/course/detail/:id',
				component: () => import('../views/classroom/course/detail'),
				children: [
					{
						path: 'manage',
						component: () => import('../views/classroom/course/manage')
					},
					{
						path: 'student',
						component: () => import('../views/classroom/course/student')
					},
					{
						path: 'file',
						component: () => import('../views/classroom/course/file')
					},
					{
						path: 'notification',
						component: () => import('../views/classroom/course/notification')
					}
				]
			},
			{
				path: '/studyProgress/:courseId/userId/:userId',
				component: () => import('../views/classroom/course/studyProgress')
			},
			{
				path: '/course/addClass/:id',
				component: () => import('../views/classroom/course/addClass')
			},
			{
				path: '/discount',
				component: () => import('../views/marketing/discount/list')
			},
			{
				path: '/discount/add',
				component: () => import('../views/marketing/discount/add')
			},
			{
				path: '/vip',
				component: () => import('../views/marketing/vip/list')
			},
			{
				path: '/vip/add',
				component: () => import('../views/marketing/vip/add')
			},
			{
				path: '/vip/detail/:id',
				component: () => import('../views/marketing/vip/detail')
			},
			{
				path: '/promotion',
				component: () => import('../views/marketing/promotion/list')
			},
			{
				path: '/promotion/add',
				component: () => import('../views/marketing/promotion/add')
			},
			{
				path: '/promotion/edit/:id',
				component: () => import('../views/marketing/promotion/edit')
			},
			{
				path: '/promotion/detail/:id',
				component: () => import('../views/marketing/promotion/detail'),
				children: [
					{
						path: 'promotionUsers',
						component: () =>
							import('../views/marketing/promotion/promotionUsers')
					}
				]
			},
			{
				path: '/goods',
				component: () => import('../views/store/goods/list')
			},
			{
				path: '/goods/add',
				component: () => import('../views/store/goods/add')
			},
			{
				path: '/goods/edit/:id',
				component: () => import('../views/store/goods/edit')
			},
			{
				path: '/order',
				component: () => import('../views/store/order/list')
			},
			{
				path: '/lose',
				component: () => import('../views/store/lose/index')
			},
			{
				path: '/order/detail/:id',
				component: () => import('../views/store/order/detail')
			},
			{
				path: '/expressage',
				component: () => import('../views/salesManage/expressage/list')
			},
			{
				path: '/sales',
				component: () => import('../views/salesManage/sales/list')
			},
			{
				path: '/sales/add',
				component: () => import('../views/salesManage/sales/add')
			},
			{
				path: '/sales/edit/:id',
				component: () => import('../views/salesManage/sales/edit')
			},
			{
				path: '/salesStatistics',
				component: () => import('../views/salesManage/salesStatistics/list')
			},
			{
				path: '/salesStatisticsChart',
				component: () => import('../views/salesManage/salesStatisticsChart')
			},
			{
				path: '/power',
				component: () => import('../views/salesManage/power/list')
			},
			{
				path: '/power/add',
				component: () => import('../views/salesManage/power/add')
			},
			{
				path: '/power/edit/:id',
				component: () => import('../views/salesManage/power/edit')
			},
			{
				path: '/groupBuying',
				component: () => import('../views/marketing/groupBuying/list')
			},
			{
				path: '/groupBuying/add',
				component: () => import('../views/marketing/groupBuying/add')
			},
			{
				path: '/groupBuying/edit/:id',
				component: () => import('../views/marketing/groupBuying/edit')
			},

			//教务
			{
				path: '/teacherCourse',
				component: () => import('../views/teacher/course/list')
			},
			{
				path: '/teacherCourse/add',
				component: () => import('../views/teacher/course/add')
			},
			{
				path: '/teacherCourse/edit/:id',
				component: () => import('../views/teacher/course/edit')
			},
			{
				path: '/teacherCourse/addClass/:id',
				component: () => import('../views/teacher/course/addClass')
			},
			{
				path: '/teacherCourse/addFile/:id',
				component: () => import('../views/teacher/course/addFile')
			},
			{
				path: '/teacherCourse/editClass/teacherCourseId/:teacherCourseId/classId/:id',
				component: () => import('../views/teacher/course/editClass')
			},
			{
				path: '/teacherCourse/detail/:id',
				component: () => import('../views/teacher/course/detail'),
				children: [
					{
						path: 'manage',
						component: () => import('../views/teacher/course/manage')
					},
					{
						path: 'file',
						component: () => import('../views/teacher/course/file')
					}
				]
			},
			//专栏
			{
				path: '/specialColumn',
				component: () => import('../views/specialColumn/recommend/list')
			},
			{
				path: '/specialColumn/add',
				component: () => import('../views/specialColumn/recommend/add')
			},
			{
				path: '/specialColumn/edit/:id',
				component: () => import('../views/specialColumn/recommend/edit')
			},
			{
				path: '/specialColumn/detail/:id',
				component: () => import('../views/specialColumn/recommend/detail'),
				children: [
					{
						path: 'content',
						component: () => import('../views/specialColumn/recommend/content')
					}
				]
			},
			{
				path: '/specialColumn/addContent/:id',
				component: () => import('../views/specialColumn/recommend/addContent')
			},
			{
				path: '/specialColumn/editContent/:id/:specialColumnId',
				component: () => import('../views/specialColumn/recommend/editContent')
			},

			// 班级管理

			{
				path: '/manage',
				component: () => import('../views/classManage/manage')
			},
			{
				path: '/classList',
				component: () => import('../views/classManage/classList')
			},
			{
				path: '/classList/add',
				component: () => import('../views/classManage/classList/add')
			},

			{
				path: '/classList/edit',
				component: () => import('../views/classManage/classList/edit')
			},

			{
				path: '/message',
				component: () => import('../views/message')
			},

			{
				path: '/checkCourse',
				component: () => import('../views/classroom/checkCourse/list')
			},
			{
				path: '/checkCourse/add',
				component: () => import('../views/classroom/checkCourse/add')
			},
			{
				path: '/checkCourse/edit/:id',
				component: () => import('../views/classroom/checkCourse/edit')
			},
			{
				path: '/checkCourse/addClass/:planId/',
				component: () => import('../views/classroom/checkCourse/addClass')
			},
			{
				path: '/checkCourse/editClass/:id/',
				component: () => import('../views/classroom/checkCourse/editClass')
			},
			{
				path: '/checkCourse/detail/:id',
				component: () => import('../views/classroom/checkCourse/detail'),
				children: [
					{
						path: 'manage',
						component: () => import('../views/classroom/checkCourse/manage')
					},
					{
						path: 'student',
						component: () => import('../views/classroom/checkCourse/student')
					},

				]
			},
		]
	},

	{
		path: '/login',
		component: () => import('../Login')
	},
	{
		path: '/wxLogin',
		component: () => import('../views/wxLogin')
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
	if (from.path == '/' && to.path == '/' && to.query.code != undefined)
		next('/wxLogin?code=' + to.query.code)
	else {
		const isLogin = VueCookies.get('isLogin')

		if (to.path == '/login') next()
		else {
			if (isLogin == 'true') return next()

			if (to.path == '/wxLogin' && to.query.code != '') return next()

			Message({
				message: '请先登录!',
				type: 'error'
			})

			next('/login')
		}
	}
})

export default router
