<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >运营和营销</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/vip' }">会员卡</el-breadcrumb-item>
                <el-breadcrumb-item>会员卡详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="course-detail-title" style="">
            <div style="padding:10px">
                <div class="fl-l" style="width: 150px;"><img width="150px" :src="imageUrl + pic" /></div>
                <div class="fl-l margin-l-sm">
                    <p class="title">{{name}} ( {{type === 1 ? '课程折扣卡' : '课程免费卡'}} <span v-if="discount>0">{{discount}} 折</span> ) </p>
                    <p class="price">{{intro}}</p>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="margin-sm">

            <el-tabs v-model="activeName" type="card" @tab-click="change">
                <el-tab-pane label="会员卡选项" name="manage" path="manage">
                    <div class="vip-card-periods" style="padding:10px">
                        <el-row :gutter="20">
                            <el-col :span="6" v-for="period in this.periods" :key="period.id">
                                <el-card class="box-card" shadow="hover">
                                    <div slot="header" class="clearfix">
                                        <span>{{period.name}}</span>
                                        <span style="float: right; padding: 3px 0" >{{period.indate}}天</span>
                                    </div>
                                    <div class="text item">
                                        <p>价格 ￥ {{period.amount}}</p>
                                        <p class="real-amount" >原价 ￥ {{period.real_amount}}</p>
                                    </div>

                                </el-card>
                            </el-col>
                        </el-row>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="学员管理" name="student" path="student" v-loading="tabLoading">

                    <div>
                        <div>
                            <el-input v-model="searchName" size="mini" style="width:160px" placeholder="微信昵称" clearable></el-input>

                            <el-button class="margin-l-sm" size="mini" @click="search">筛选</el-button>
                        </div>
                        <div class="margin-t-sm">

                            <div class="table-content">

                                <el-table
                                        :header-cell-style="{padding:0}"
                                        ref="singleTable"
                                        :data="tableData"
                                        highlight-current-row
                                        v-loading="loading"
                                        style="width: 100%;background: #fff;">
                                    <el-table-column
                                            label="ID"
                                            property="id"
                                            width="80">
                                    </el-table-column>
                                    <el-table-column
                                            width="80"
                                            label="头像">
                                        <template slot-scope="scope">
                                            <el-avatar :src="scope.row.user_name_avatar_url"></el-avatar>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="名称">
                                        <template slot-scope="scope">
                                            <span>{{scope.row.user_name}}</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                            property="created_at"
                                            label="加入时间">
                                    </el-table-column>
                                    <el-table-column
                                            property="index"
                                            label="会员天数"
                                            width="120">
                                        <template slot-scope="scope">
                                            <span>{{scope.row.indate}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            property="address"
                                            label="备注" >
                                        <template slot-scope="scope">
                                            <span>{{scope.row.remark}}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </div>

                            <div class="table-batch " >
                                <span class="fl-r padding-b-sm" >
                                    <el-pagination
                                            small
                                            background
                                            :current-page="page"
                                            :page-sizes="[10, 20]"
                                            :page-size="pageSize"
                                            layout="total, sizes, prev, pager, next, jumper"
                                            @size-change="setPageSize"
                                            @current-change="setPage"
                                            :total="total">
                                </el-pagination>
                                </span>
                                <div style="clear: both"></div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>

            </el-tabs>
        </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "detail",
        data(){
            return {
                tabLoading:false,
                loading:true,
                id: '',
                activeName: 'manage',
                pic:'',
                amount:0,
                name:'',
                type:'',
                intro:'',
                discount:'',
                imageUrl:config.imageUrl,
                periods:[],
                searchName:'',
                tableData:[],
                page:1,
                pageSize:10,
                total:0
            };
        },
        methods: {
            ...mapActions('marketing',['vipCardDetail']),
            ...mapActions('user',['getUserVipLog']),
            change(tab, event) {
                if(tab.name === 'student'){
                    this.getVipLog()
                }
            },
            search(){
                this.page = 1
                this.getVipLog()
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getVipLog()
            },
            setPage(page){
                this.page  = page
                this.getVipLog()
            },
            getDetail(){
                this.loading = true
                this.vipCardDetail({id:this.id}).then(res => {
                    console.log(res)
                    this.pic = res.data.pic
                    this.name = res.data.name
                    this.intro = res.data.intro
                    this.periods = res.data.periods
                    this.type = res.data.type
                    this.discount = res.data.discount

                    this.loading = false
                })
            },
            getVipLog(){
                this.tabLoading = true
                let data = {
                    page:this.page,
                    pageSize:this.pageSize,
                    vipId:this.id,
                    userName:this.searchName
                }
                this.getUserVipLog(data).then(res => {
                    console.log(res)
                    this.tableData = res.data.list
                    this.total = res.data.total
                    this.tabLoading = false
                })
            }

        },
        mounted() {
            this.id = this.$route.params.id

            this.getDetail()
        }
    }
</script>

<style>
    .course-detail-title{
        margin:10px;
        background: #fafafa;
        overflow: hidden;
    }
    .title{
        font-size: 18px;
        margin-top:5px;
    }
    .price{
        margin-top:45px;
        font-size: 16px;
    }
    .real-amount{
        margin-top:10px;text-decoration:line-through
    }
</style>