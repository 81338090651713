<template>
    <div >
        <quill-editor
            :value="content"
            ref="myQuillEditor"
            :options="editorOption"
            @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
            style="width:1100px;"
            :onchange="onEditorChange"
            @change="onEditorChange($event)">
        </quill-editor>

        <div style="display: none"><cover ref="image" :uploadPath="uploadPath" @success="uploadSuccess"></cover></div>
    </div>
</template>

<script>
    import { quillEditor } from "vue-quill-editor"; //调用编辑器
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import ImageUpload from "./imageUpload";
    import Cover from "./cover";
    import {config} from "../js/config";
    export default {
        name: "editor",
        props:[
            'content'
        ],
        components:{
            Cover,
            quillEditor
        },
        data(){
            return {
                uploadPath:'editor',
                editorOption: {
                    placeholder: "请在这里输入",
                    height:'600px',
                    modules:{
                        toolbar:{
                            container:[
                                ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
                                ['blockquote', 'code-block'],     //引用，代码块
                                [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                                [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
                                [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
                                [{ 'direction': 'rtl' }],             // 文本方向
                                [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                                [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
                                [{ 'font': [] }],     //字体
                                [{ 'align': [] }],    //对齐方式
                                ['clean'],    //清除字体样式
                                // ['image']    //上传图片、上传视频
                                ['uploadImage'],

                            ],
                            handlers:{
                                'uploadImage': () =>{
                                    this.$refs.image.$el.getElementsByClassName('el-upload el-upload--picture-card')[0].click()
                                }
                            }
                        }

                    },
                    initButton:function(){
                        const sourceEditorButton = document.querySelector('.ql-uploadImage');
                        sourceEditorButton.className = "el-icon-picture";
                        sourceEditorButton.title = "插入图片";
                    }
                }
            };
        },
        watch: {
            content(newVal) {
                this.content = newVal
            }
        },
        methods:{
            onEditorReady(editor) { // 准备编辑器

            },
            onEditorBlur(e){

            }, // 失去焦点事件
            onEditorFocus(e){

            }, // 获得焦点事件
            onEditorChange(e){
                this.$emit('onChange',e.html);
            }, // 内容改变事件
            uploadSuccess(pic){
                let image = '<p><img src="'+config.imageUrl + pic+'" width="100%" /></p>'
                this.content =  this.content+image
                console.log(this.content+image)
                console.log(config.imageUrl + pic)
            }
        },
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill;
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.editorOption.initButton()
            })
        }
    }
</script>

<style >
.ql-container{
    height: 600px;
}
</style>
