import {Api} from "../../service/Api";
import {config} from "../../js/config";

const state = () => ({

})

const getters = {

}

const actions = {
    getOssInfo({commit},data){
        return (new Api()).getOssInfo(data)
    },
    getBaseData({commit},data){
        return (new Api()).getBaseData(data)
    },
    delete({commit},data){
        return (new Api()).delete(data)
    },
    setSort({ commit }, data) {
        return new Api().edit(config.setCheckSort, data)
    },
    setStatus({ commit }, data) {
        return new Api().edit(config.setStatus, data)
    },
    getVideoUploadAccess({commit},data){
        return (new Api()).getVideoUploadAccess(data)
    },
    makeQrCode({commit},data) {
        return (new Api()).makeQrCode(data)
    },
    importTask({commit},data) {
        return (new Api()).add(config.importTask,data)
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
