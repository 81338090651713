<template>
	<div v-loading="loading">
		<div class="main-title">学员管理</div>

		<div class="content-list-page page-content">
			<div class="list-head-box">
				<div data-v-69874ad0 class="list-head">
					<p data-v-69874ad0 class="list-head-title">温馨提示</p>
					<ul data-v-69874ad0>
						<li data-v-69874ad0>XXXXXX</li>
					</ul>
				</div>

				<div class="course-detail-title content-list-page page-content">
					<div style="padding: 10px">
						<div class="fl-l" style="width: 150px">
							<el-image
								height="80px"
								width="150px"
								:src="imageUrl + $route.query.pic"
								:preview-src-list="[imageUrl + $route.query.pic]"
							/>
						</div>

						<div class="fl-l margin-l-sm">
							<p>班级：{{ $route.query.name }}</p>
						</div>
					</div>
				</div>
				<div class="clear"></div>
			</div>

			<div class="margin-sm">
				<el-tabs v-model="type" type="card">
					<el-tab-pane label="学员管理" name="manage">
						<el-select
							size="mini"
							v-model="select"
							placeholder="请选择"
							clearable
							@clear="getStudentData"
						>
							<el-option :value="1" label="已打卡" />
							<el-option :value="2" label="未打卡" />
						</el-select>
						<el-button class="margin-l-sm" type="primary" @click="getStudentData" size="mini">
							筛选
						</el-button>
						<el-button
							type="primary"
							@click="showAddStudentDialog = true"
							size="mini"
						>
							新增
						</el-button>

						<el-table :data="tableData">
							<el-table-column prop="id" label="id" />
							<el-table-column prop="avatar" label="头像">
								<template slot-scope="scope">
									<el-avatar :src="scope.row.user_name_avatar_url" />
								</template>
							</el-table-column>
							<el-table-column prop="user_id" label="用户id" />
							<el-table-column prop="user_name" label="名称" />
							<el-table-column prop="created_at" label="加入时间" />
							<el-table-column prop="record_count" label="打卡总数" />
							<el-table-column prop="record_at" label="近期打卡时间" />

							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-popconfirm
										title="确定删除此学员吗"
										@confirm="deleteUser(scope.row)"
									>
										<el-button slot="reference" type="text">删除 - </el-button>
									</el-popconfirm>
									<el-button type="text" @click="handleReplace(scope.row)">
										打卡记录
									</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="table-batch">
							<span class="fl-r">
								<el-pagination
										small
										background
										:current-page="page"
										:page-sizes="[10, 20]"
										:page-size="pageSize"
										layout="total, sizes, prev, pager, next, jumper"
										@size-change="setPageSize"
										@current-change="setPage"
										:total="total"
								>
								</el-pagination>
							</span>
							<div style="clear: both"></div>
						</div>
					</el-tab-pane>

					<el-tab-pane label="学员动态" name="moment">
						<el-select
							size="mini"
							v-model="momentSelectText"
							@change="momentText = ''"
							clearable
						>
							<el-option value="user_id" label="UID"></el-option>
							<el-option value="name" label="微信昵称"></el-option>
						</el-select>
						<el-input class="margin-l-sm" size="mini" v-model="momentText" style="width: 160px" clearable />
						<el-button
							class="margin-l-sm"
							size="mini"
							type="primary"
							@click="getMomentData(momentText, 'name')"
							>筛选</el-button>

						<el-table :data="tableData">
							<el-table-column prop="id" label="id" />

							<el-table-column label="头像">
								<template slot-scope="scope">
									<el-avatar :src="scope.row.user_name_avatar_url" />
								</template>
							</el-table-column>

							<el-table-column prop="user_id" label="用户id" />

							<el-table-column prop="user_name" label="用户名" />

							<el-table-column prop="title" label="标题" />

							<el-table-column label="预览图">
								<template slot-scope="scope">
									<el-image
										v-if="scope.row.type === 1"
										class="table-item-img"
										:src="imageUrl + scope.row.pic"
										:preview-src-list="[imageUrl + scope.row.pic]"
									/>
									<el-image
											v-else
											class="table-item-img"
											:src="scope.row.video_pic"
											:preview-src-list="[scope.row.video_pic]"
									/>
								</template>
							</el-table-column>

							<el-table-column prop="type" label="类型">
								<template slot-scope="scope">
									<span v-if="scope.row.type === 1">图片</span>
									<span v-else>视频</span>
								</template>
							</el-table-column>

							<el-table-column prop="status" label="状态">
								<template slot-scope="scope">
									<span v-if="scope.row.status === 1">待审核</span>
									<span v-if="scope.row.status === 2">审核通过</span>
									<span v-if="scope.row.status === 3">审核不通过</span>
								</template>
							</el-table-column>

							<el-table-column prop="created_at" label="创建时间" />

							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-popconfirm
											title="确定删除吗？"
											@confirm="delSocial(scope.row)"
									>
										<el-link  slot="reference"  type="primary" >删除</el-link>
									</el-popconfirm>
									-
									<el-link  type="primary"
											  @click="socialDetail(scope.row)">详情</el-link> -

									<el-popover
											placement="top"
											width="160"
											v-model="scope.row.visible">
										<div >
											<el-button size="mini" type="success" @click="checkSocial(scope.row,2)">通过</el-button>
											<el-button  size="mini" type="danger" @click="checkSocial(scope.row,3)">不通过</el-button>
										</div>
										<el-link type="primary" slot="reference">审核</el-link>
									</el-popover>
									<span v-if="scope.row.type === 2">
									-
									<span>
										<el-link type="primary" @click="showPlayDialog(scope.row)">播放</el-link>
									</span>
                            </span>
								</template>
							</el-table-column>
						</el-table>

						<div class="table-batch">
							<span class="fl-r">
								<el-pagination
									small
									background
									:current-page="page"
									:page-sizes="[10, 20]"
									:page-size="pageSize"
									layout="total, sizes, prev, pager, next, jumper"
									@size-change="setPageSize"
									@current-change="setPage"
									:total="total"
								>
								</el-pagination>
							</span>
							<div style="clear: both"></div>
						</div>
					</el-tab-pane>

					<el-tab-pane label="班级通知" name="notification">
						<div class="padding-b-sm">
							<el-button
								type="primary"
								icon="el-icon-plus"
								size="mini"
								@click="showDialog = true"
							>
								选择学员
							</el-button>

							<div class="student padding-sm" style="margin: 12px 0">
								<div style="color: #606266; line-height: 30px">
									<span class="fl-l"> 已选学员：</span>

									<el-tag
										type="success"
										closable
										class="margin-b-sm margin-r-sm"
										effect="plain"
										:key="student.id"
										v-for="student in dialogStu"
										@close="subStudent(student)"
									>
										<div class="fl-l">
											<div
												style="
													display: flex;
													align-items: center;
													padding: 5px 0;
												"
											>
												<el-avatar
													shape="square"
													size="small"
													:src="student.user_name_avatar_url"
												></el-avatar>
												<span class="margin-l-sm">{{ student.user_name }}</span>
											</div>
										</div>
									</el-tag>
									<el-button
										class="fl-r"
										type="primary"
										icon="el-icon-delete"
										size="mini"
										@click="dialogStu = []"
									>
										清空
									</el-button>
									<div class="clear"></div>
								</div>
							</div>

							<el-form ref="form" :model="formData" :rules="rules">
								<el-form-item label="通知类型" prop="type">
									<el-select v-model="formData.type">
										<el-option :value="1" label="班级通知" />
<!--										<el-option :value="2" label="老师请假通知" />-->
									</el-select>
								</el-form-item>

								<el-form-item label="老师名称" prop="name">
									<el-input
										style="width: 195px"
										v-model="formData.name"
									></el-input>
								</el-form-item>

								<el-form-item label="班级名称" prop="className">
									<el-input
										style="width: 195px"
										v-model="formData.className"
									></el-input>
								</el-form-item>

								<el-form-item label="跳转小程序" prop="toApplet">
									<el-switch
										:active-value="1"
										:inactive-value="0"
										v-model="formData.toApplet"
									/>
								</el-form-item>

								<el-form-item label="小程序路径" v-show="formData.toApplet">
									<el-input style="width: 195px" v-model="formData.path" />
								</el-form-item>

								<el-form-item label="通知内容" prop="content">
									<el-input
										v-model="formData.content"
										type="textarea"
										:maxlength="150"
										clearable
										show-word-limit
										:rows="12"
									/>
								</el-form-item>
							</el-form>

							<div
								style="display: flex; justify-content: center; margin-top: 20px"
							>
								<el-button type="primary" size="mini" @click="submit">
									发送通知
								</el-button>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<el-dialog
			title="添加学员"
			append-to-body
			:show-close="false"
			:close-on-press-escape="false"
			:close-on-click-modal="false"
			:visible.sync="showAddStudentDialog"
			width="50%"
		>
			<div>
				<div class="padding-sm">
					<el-select
						v-model="searchType"
						slot="prepend"
						placeholder="选择类型"
						size="mini"
					>
						<el-option label="微信昵称" :value="1"></el-option>
						<el-option label="UID" :value="2"></el-option>
					</el-select>
					<el-input
						class="margin-l-sm"
						v-model="studentName"
						size="mini"
						style="width: 160px"
						clearable
					></el-input>

					<el-button
						class="margin-l-sm"
						size="mini"
						@click="searchStudent"
						type="primary"
						:loading="searchStudentBtn"
						>筛选</el-button
					>
				</div>

				<div class="margin-t-sm" v-if="notData">暂无数据</div>
				<div class="margin-t-sm">
					<el-tag
						class="margin-b-sm margin-r-sm"
						effect="plain"
						:key="student.id"
						v-for="student in searchStudents"
					>
						<div style="display: flex; align-items: center; padding: 5px 0">
							<span>UID:{{ student.id }}</span>
							<el-avatar
								class="margin-l-sm"
								shape="square"
								size="small"
								:src="student.avatar_url"
							></el-avatar>
							<span class="margin-l-sm">{{ student.name }}</span>
							<span
								class="margin-l-sm el-icon-plus icon-hover"
								@click="selectStudents(student)"
							></span>
						</div>
					</el-tag>
				</div>

				<div v-if="selectStudent.length > 0">
					<p class="margin-t-sm margin-b-sm">已选学员</p>
					<div
						style="
							border: 1px solid #eee;
							padding: 10px 10px 0 10px;
							border-radius: 10px;
						"
					>
						<el-tag
							type="success"
							closable
							class="margin-b-sm margin-r-sm"
							effect="plain"
							:key="student.id"
							v-for="student in selectStudent"
							@close="subStudent(student)"
						>
							<div class="fl-l">
								<div style="display: flex; align-items: center; padding: 5px 0">
									<el-avatar
										shape="square"
										size="small"
										:src="student.avatar_url"
									></el-avatar>
									<span class="margin-l-sm">{{ student.name }}</span>
								</div>
							</div>
						</el-tag>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="hideAddStudent" size="mini">取 消</el-button>
				<el-button
					type="primary"
					@click="addStudent"
					size="mini"
					:loading="buttonLoading"
					>确 定</el-button
				>
			</span>
		</el-dialog>

		<Dialog
			:dialogStuOrigin="dialogStuOrigin"
			:showDialog.sync="showDialog"
			@finish="setStudent"
		/>
		<el-dialog append-to-body title="播放" :visible.sync="dialogPlayVisible">
			<div v-loading="videoLoading">
				<div style="padding-bottom:20px">{{content}}</div>

				<video   controls="controls" :src="videoUrl"></video>

			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

import { Message } from 'element-ui'

import Dialog from './dialog'

import { config } from '@/js/config'

export default {
	components: { Dialog },
	data() {
		return {
			dialogPlayVisible:false,
			videoLoading:false,
			content:'',
			videoUrl:'',
			dialogStu: [],
			momentSelectText: 'user_id',
			showDialog: false,
			momentText: '',
			visible: false,
			select: '',
			pageSize: 10,
			page: 1,
			loading: false,
			total: 0,
			imageUrl: config.imageUrl,
			type: 'manage',
			buttonLoading: false,
			formData: {
				type: 1,
				name: '',
				toApplet: false,
				content: '',
				className: this.$route.query.name,
				path: ''
			},
			tableData: [],
			rules: {
				type: [{ required: true, trigger: 'change', message: '请选择类型' }],
				name: [
					{ required: true, trigger: 'change', message: '请输入您的名字' }
				],
				toApplet: [{ required: true, trigger: 'change' }],
				className: [{ required: true, trigger: 'change' }],
				content: [
					{ required: true, trigger: 'change', message: '请输入通知内容' }
				]
			},
			searchStudentBtn: false,
			studentName: '',
			searchType: 1,
			showAddStudentDialog: false,
			searchName: '',
			id: 0,
			searchStudents: [],
			notData: false,
			selectStudent: [],
			setLoading: false,
			workCountLoading: false,
			workCount: 0,
			sendButtonLoading: false,
			dialogStuOrigin: []
		}
	},

	methods: {
		...mapActions('user', ['getUserList','getUserSocialDetail']),
		...mapActions('common', ['delete','setStatus']),

		...mapActions('classManage', [
			'getClassMessage',
			'getStudentList',
			'getMomentList',
			'addClassNotification',
			'deleteClassUser',
			'addClassUser'
		]),

		async selectStudents(student) {
			if (this.selectStudent.find(currentId => +currentId === +student.id))
				return
			else this.selectStudent.push(student.id)
		},

		submit() {
			this.$refs.form.validate(async valid => {
				// if (!valid || !this.dialogStu.length)
				// 	return this.$message.error('请选择所有选项')

				this.loading = true

				const params = {
					...this.formData,
					userIds: this.dialogStu.map(item => item.user_id),
					className: this.$route.query.name
				}

				if (!params.toApplet) delete params.path

				const { data } = await this.addClassNotification(params).finally(()=>{
					this.loading = false
				})

				this.$refs.form.resetFields()
				this.dialogStu = []

				this.$message.success('发送通知成功')

				this.loading = false
			})
		},
		showAddStudent() {
			this.showAddStudentDialog = true
		},
		hideAddStudent() {
			this.showAddStudentDialog = false
		},
		async addStudent() {
			if (!this.selectStudent.length) return (this.showAddStudentDialog = false)

			this.buttonLoading = true

			const { data } = await this.addClassUser({
				user_ids: this.selectStudent,
				class_id: this.$route.query.id
			})

			data.forEach(id => this.$message.error('id重复添加', id))

			this.buttonLoading = false
			this.showAddStudentDialog = false

			this.getStudentData()
		},

		searchStudent() {
			let searchData = {}
			if (this.searchType === 1) {
				searchData.name = this.studentName
				searchData.pageSize = 1000
			} else {
				searchData.uid = this.studentName
				searchData.pageSize = 1000
			}
			console.log(searchData)
			if (this.studentName === '') {
				this.$message.error('请输入昵称')
				return
			}
			this.notData = false
			this.searchStudentBtn = true

			this.getUserList(searchData).then(res => {
				if (res.data.list.length === 0) {
					this.notData = true
				}
				this.searchStudents = res.data.list
				this.searchStudentBtn = false
			})
		},

		subStudent(student) {
			this.selectStudent = this.selectStudent.filter(
				currentId => +currentId !== +student
			)
		},

		setPageSize(value) {
			console.log(this.type)
			this.pageSize = value

			if(this.type == 'moment')
				this.getMomentData()

			if(this.type == 'manage')
				this.getStudentData()
		},

		setPage(value) {
			this.page = value

			if(this.type == 'moment')
				this.getMomentData()

			if(this.type == 'manage')
				this.getStudentData()
		},
		socialDetail(e){
			this.$router.push('userSocial/detail/'+e.id)
			console.log(e)
		},
		showPlayDialog(row){
			this.videoLoading = true
			this.getUserSocialDetail({id:row.id}).then(res => {
				console.log(res)
				this.videoUrl = res.data.videoUrl
				this.content = res.data.content
				this.videoLoading = false
			})
			this.dialogPlayVisible = true
		},
		checkSocial(row,status){
			let _this = this
			let data = {
				id: row.id,
				status: status,
				model:'SocialModel'
			}
			this.setStatus(data).then(res => {
				if(res.ret === 0){
					this.$message.success('设置成功')
					this.getMomentData()
				}
			})

		},
		delSocial(e){

			this.delete({id:e.id,model:'Social'}).then(res =>{
				if(res.ret === 0){
					this.$message.success('删除成功')
					this.getMomentData()
				}

			})
			console.log(e)
		},
		handleReplace({ user_id }) {
			this.momentText = user_id
			this.type = 'moment'
		},

		async getMessage() {
			// this.loading = true
		},

		async getStudentData() {
			this.loading = true

			const { res_info, data } = await this.getStudentList({
				class_id: this.$route.query.id,
				record_status: this.select,
				pageSize: this.pageSize,
				page: this.page
			})

			this.loading = false

			if (res_info !== 'ok') return

			this.total = data.total
			this.tableData = data.list
			this.dialogStuOrigin = data.list
		},

		async getMomentData() {
			this.loading = true

			const { data, res_info } = await this.getMomentList({
				[this.momentSelectText]: this.momentText,
				pageSize: this.pageSize,
				page: this.page,
				class_id:this.$route.query.id
			}).finally(()=>{
				this.loading = false
			})

			this.loading = false

			if (res_info !== 'ok') return

			this.total = data.total
			this.tableData = data.list
		},

		async deleteUser({ id }) {
			this.loading = true

			await this.deleteClassUser(id)

			this.getStudentData()
		},

		async sendMessage(content) {
			this.sendButtonLoading = true
		},

		setStudent(stus) {
			this.dialogStu = stus
			this.showDialog = false
		}
	},

	created() {
		if (this.$route.query.id) this.getStudentData()
	},

	watch: {
		type(current) {
			this.loading = false
			this.total = 0
			this.pageSize = 10
			this.page = 1
			this.tableData = []

			switch (current) {
				case 'moment':
					return this.getMomentData()

				case 'message':
					return this.getMessage()

				case 'manage':
					if (this.$route.query.id) this.getStudentData()
					return
			}
		}
	},
	beforeDestroy() {
		this.select = ''
	}
}
</script>

<style>
.student {
	border: 1px solid #eee;
}
.icon-hover {
	cursor: pointer;
	font-size: 14px;
}
.icon-hover:hover:before {
	font-size: 14px;
	content: '\e7a0';
}
.el-tag {
	height: auto;
	line-height: auto;
}
.el-tag .el-icon-close {
	top: 5px;
}
</style>
