<template>
    <div  v-loading="loading">
        <div>
<!--            <span class="fl-l">-->
<!--                <span class="fl-l"><el-input v-model="searchName" placeholder="请输入内容" clearable size="课时名称"></el-input></span>-->
<!--                <span class="fl-l margin-l-sm"><el-button  size="mini" plain icon="el-icon-search" @click="search">筛选</el-button></span>-->
<!--            </span>-->
            <span class="fl-r">
                <el-button  size="mini" plain icon="el-icon-plus" @click="toAddClass">新建课时</el-button>
                <el-popover
                        class="margin-l-sm"
                        placement="left"
                        width="160"
                        v-model="visible">
                    <div class="padding-sm">
                        <p class="font-size-12">章节名称</p>
                        <p class="padding-t-sm "><el-input v-model="chapter" placeholder="请输入章节名称"></el-input></p>
                    </div>

                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="createChapter" :loading="createChapterLoading">确定</el-button>
                    </div>
                  <el-button slot="reference" type="primary" size="mini" icon="el-icon-plus">新建章节</el-button>
                </el-popover>
            </span>
            <p class="clear"></p>
        </div>

        <div class="table-content">

           <div>
               <div class=" table-header">
                   <div class="fl-l w26">目录</div>
                   <div class="fl-l w26">概况</div>
                   <div class="fl-l w10">类型</div>
                   <div class="fl-l w10">排序</div>
                   <div class="fl-l w26" style="text-align: right">操作</div>
                   <div class="clear"></div>
               </div>
           </div>

            <div class="padding-sm">
                <div v-for="item  in list" :key="item.id">
                    <div class="section border-s padding-sm">
                        <div class="fl-l w26">{{item.name}} </div>
                        <div class="fl-l w26">-</div>
                        <div class="fl-l w10">-</div>
                        <div class="fl-l w10">{{item.sort}}</div>
                        <div class="fl-l w26" style="text-align: right;font-weight: normal">
                            <el-popover
                                    class="margin-l-sm"
                                    placement="left"
                                    width="360"
                                    v-model="item.show">
                                <div class="padding-sm">
                                    <p class="fl-l margin-r-sm" style="height: 30px;line-height: 30px">移动到第</p>
                                    <p class="fl-l"><el-input-number :min="0" size="mini" v-model="item.sort" controls-position="right" ></el-input-number></p>
                                    <div class="fl-l margin-l-sm">
                                        <el-button type="primary" size="mini" @click="changeSort(item,CourseChapterModel)" :loading="item.loading">确定</el-button>
                                        <el-button size="mini" type="text" @click="item.show = false">取消</el-button>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <el-link slot="reference" type="primary" >排序</el-link>
                            </el-popover>
                            -
                            <el-link  type="primary" @click="showEditChapter(item)">编辑</el-link>
                            -
                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(item,'TeacherCourseChapter')"
                            >
                                <el-link slot="reference" type="primary">删除</el-link>
                            </el-popconfirm>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="margin-sm border-s padding-sm font-size-12" v-for="clazz in item.class" :key="clazz.id">
                        <div class="fl-l w26">
                            <i class="circle el-icon-picture" v-if="clazz.type === 1" ></i>
                            <i class="circle el-icon-video-camera-solid" v-if="clazz.type === 2" ></i>
                            <i class="circle el-icon-headset" v-if="clazz.type === 3" ></i>
                            <span> {{clazz.name}}</span>
                            <span v-if="clazz.free === 1 ">
                                <el-tag type="success" size="mini">试学</el-tag>
                            </span>

                        </div>
                        <div class="fl-l w26">
                            <div v-if="clazz.size === 0">-</div>
                            <div v-if="clazz.size">大小 :【{{parseFloat((clazz.size/1024)/1024).toFixed(2) + 'M'}}】</div>

                        </div>

                        <div class="fl-l w10">
                            <p v-if="clazz.type === 1"> 图文</p>
                            <p v-if="clazz.type === 2"> 视频</p>
                            <p v-if="clazz.type === 3"> 音频</p>
                        </div>

                        <div class="fl-l w10"> {{clazz.sort}}</div>
                        <div class="fl-l w26" style="text-align: right">
                            <el-popover
                                    class="margin-l-sm"
                                    placement="left"
                                    width="360"
                                    v-model="clazz.show">
                                <div class="padding-sm">
                                    <p class="fl-l margin-r-sm" style="height: 30px;line-height: 30px">移动到第</p>
                                    <p class="fl-l"><el-input-number :min="0" size="mini" v-model="clazz.sort" controls-position="right" ></el-input-number></p>
                                    <div class="fl-l margin-l-sm">
                                        <el-button type="primary" size="mini" @click="changeSort(clazz,CourseClassModel)" :loading="clazz.loading">确定</el-button>
                                        <el-button size="mini" type="text" @click="clazz.show = false">取消</el-button>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <el-link slot="reference"  type="primary" >排序</el-link>
                            </el-popover>
                            -
                            <el-link type="primary" @click="editClass(clazz)">编辑</el-link>
                            -
                            <el-link type="primary" @click="detailClass(clazz)">查看</el-link>
                            -
                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(clazz,'TeacherCourseClass')"
                            >
                                <el-link slot="reference" type="primary">删除</el-link>
                            </el-popconfirm>
                            <span v-if="clazz.type !== 1">
                                -
                                <el-link  type="primary" @click="download(clazz)">下载</el-link>
                            </span>
                        </div>
                        <div class="clear"></div>
                    </div>

                </div>
            </div>
        </div>

        <el-dialog
                :append-to-body="true"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                title="编辑章节"
                :visible.sync="editChapterVisible"
                width="30%"
               >
            <div><el-input v-model="chapterName" placeholder="请输入章节名称"></el-input></div>
            <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hideEditChapter">取 消</el-button>
            <el-button size="mini" type="primary" @click="editChapter" :loading="editChapterLoading">确 定</el-button>
          </span>
        </el-dialog>

        <!--查看-->

        <el-dialog
                :append-to-body="true"
                :show-close="true"
                :visible.sync="showDetailDialog"
                width="50%"
                :title="detail.name"
                >
                <div style="display: flex;flex-wrap:wrap;padding-bottom:30px; "  v-loading="detailDialogLoading">
                    <div class="padding-sm"  v-html="detail.content" style="max-height: 800px;overflow:auto;width: 100%"> </div>
                    <div style="display: flex;justify-content: center;width: 100%">
                        <video v-if="detail.type === 2" width="320" height="240" controls :src="imageUrl + detail.data">

                        </video>
                        <audio  v-if="detail.type === 3" controls :src="imageUrl + detail.data">

                        </audio>
                    </div>
                </div>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    var FileSaver = require('file-saver');

    export default {
        name: "manage",
        data(){
            return{
                imageUrl:config.imageUrl,
                editChapterVisible:false,
                searchName:'',
                showDetailDialog:false,
                detailDialogLoading:false,
                tableData:[],
                chapterName:'',
                selectChapterId:0,
                editChapterLoading:false,
                input:'',
                loading:false,
                visible:false,
                visible2:false,
                chapter:'',
                createChapterLoading:false,
                teacherCourseId:0,
                list:[],
                CourseChapterModel:'TeacherCourseChapterModel',
                CourseClassModel:'TeacherCourseClassModel',
                detail:{}
            };
        },
        methods:{
            ...mapActions('teacherCourse',['addTeacherCourseChapter','getTeacherCourseChapter','setSort','getTeacherCourseClassDetail']),
            ...mapActions('common',['delete']),
            search(){
                this.getList()
            },
            showEditChapter(item){
                this.selectChapterId = item.id
                this.chapterName = item.name
                this.editChapterVisible = true
            },
            hideEditChapter(){
                this.editChapterVisible = false
            },
            editClass(item){
                this.$router.push('/teacherCourse/editClass/teacherCourseId/'+this.teacherCourseId+'/classId/'+item.id)
            },
            detailClass(item){
                this.detailDialogLoading = true
                this.showDetailDialog = true

                this.getTeacherCourseClassDetail(item.id).then(res => {
                    this.detail = res.data
                }).finally(()=>{
                    this.detailDialogLoading = false
                })

            },
            editChapter(){
                this.editChapterLoading = true
                let data = {
                    id:this.selectChapterId,
                    teacherCourseId:this.teacherCourseId,
                    name:this.chapterName
                }

                this.addTeacherCourseChapter(data).then(res => {

                    if(res.ret === 0)
                    {
                        this.visible = false
                        this.$message.success('修改成功')
                        this.chapter = ''
                        this.getList();
                    }
                    this.hideEditChapter()

                }).finally(()=>{
                    this.editChapterLoading = false
                })

            },
            createChapter(){
                this.createChapterLoading = true

                let data = {
                    teacherCourseId:this.teacherCourseId,
                    name:this.chapter
                }

                this.addTeacherCourseChapter(data).then(res => {

                    if(res.ret === 0)
                    {
                        this.visible = false
                        this.$message.success('章节创建成功')
                        this.chapter = ''
                        this.getList();
                    }
                    this.createChapterLoading = false
                })

            },
            changeSort(item,model){
                console.log(item)
                item.loading = true

                let data = {
                  id:item.id,
                  sort:item.sort,
                  model:model
                }

                this.setSort(data).then(res => {
                    if(res.ret === 0){
                        item.loading = false
                        item.show = false
                        this.$message.success('排序成功！')
                        this.getList()
                    }
                })
            },
            setStatus(item,model){
                let data = {
                    model:model,
                    id:item.id,
                    status:!item.status
                }


                this.setTheStatus(data).then(res => {
                    if(res.ret === 0)
                    {
                        this.$message.success('设置成功！')
                        this.getList()
                    }
                })
            },
            download(item){
                window.open(this.imageUrl + item.data,'_self')
            },
            deleted(item,model){
                console.log(item)
                this.delete({model:model,id:item.id}).then(res => {
                    if(res.ret === 0)
                    {
                        this.$message.success('删除成功！')
                        this.getList()
                    }
                })
            },
            toAddClass(){
                this.$router.push('/teacherCourse/addClass/'+this.teacherCourseId)
            },
            s_to_hs(s){
                return tools.sToTime(s)
            },
            getList(){

                this.loading = true
                let data = {
                    teacherCourseId:this.teacherCourseId,
                    name:this.searchName,
                }

                this.getTeacherCourseChapter(data).then(res => {
                    if(res.ret === 0){
                        this.list = res.data
                    }

                    this.loading = false
                })
            }
        },
        mounted() {
            this.teacherCourseId = this.$route.params.id
            this.getList()
        }
    }
</script>

<style >
    .table-header{
        padding:15px;
        background: #fafafa;
    }
    .font-size-12{
        font-size: 12px;
    }
    .circle{
        border:1px solid #ccc;
        padding:3px;
        border-radius: 50%;
        margin-right: 3px;
        font-size: 8px;
    }
    .section{
        font-size: 14px;
        padding: 20px;
        font-weight: bold;
    }
    .border-s{
        border-bottom:1px solid #ebebeb
    }
.w10{
    width: 10%;
}
.w26{
    width: 26.3333%;
}
</style>