<template>
    <div style="padding: 10px" v-loading="loading">
        <div>
            <div class="flex flex-wrap justify-around  align-center" style="height: 100%;padding: 10px">
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(45, 140, 240)">日</div>
                    <div class="total-info">
                        <div class="total-info-num">{{formatNumber(toDaySum)}}</div>
                        <div class="total-info-txt">今日收入</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(25, 190, 107)">周</div>
                    <div class="total-info">
                        <div class="total-info-num">{{formatNumber(monthSum)}}</div>
                        <div class="total-info-txt">本月收入</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(228, 108, 187);">月</div>
                    <div class="total-info">
                        <div class="total-info-num">{{formatNumber(yearSum)}}</div>
                        <div class="total-info-txt">年度收入</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(154, 102, 228)">总</div>
                    <div class="total-info">
                        <div class="total-info-num">{{formatNumber(totalSum)}}</div>
                        <div class="total-info-txt">总收入</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(255, 153, 0)">退</div>
                    <div class="total-info">
                        <div class="total-info-num">{{toDayRefund}}</div>
                        <div class="total-info-txt">今日退费</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(237, 63, 20)">退</div>
                    <div class="total-info">
                        <div class="total-info-num">{{totalRefund}}</div>
                        <div class="total-info-txt">总退费</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div style="padding: 20px 0">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        size="mini"
                        v-model="date"
                        type="month"
                        @change="change"
                        placeholder="选择月">
                </el-date-picker>
            </div>
        </div>
        <div class="">
            <div id="everyday" style="height: 600px;width: 100%;margin: 20px 0"></div>
            <div id="main2" style="height: 600px;width: 100%;margin: 20px 0"></div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "salesStatisticsChart",
        data(){
            return {
                loading:false,
                toDaySum: 0,
                monthSum: 0,
                yearSum: 0,
                totalSum: 0,
                toDayRefund: 0,
                totalRefund: 0,
                date: new Date(),
                everyday:null,
                myChart2:null,
            };
        },
        methods:{
            ...mapActions('sales',['getStatisticsChart']),
            formatNumber(num) {
                let n = Number(num);
                if (n === 0) {
                    return num + '';
                } else
                if (n > 1 && n < 10000) {
                    return n + '';
                } else {
                    return (n / 10000).toFixed(3) + '万';
                }
            },
            change(e){
                console.log(e)
                this.getDetail()
            },
            async getDetail(){
                this.loading = true
                let res = await this.getStatisticsChart({date:this.date})
                this.loading = false
                this.toDaySum = res.data.toDaySum
                this.monthSum = res.data.monthSum
                this.yearSum = res.data.yearSum
                this.totalSum = res.data.totalSum
                this.toDayRefund = res.data.toDayRefund
                this.totalRefund = res.data.totalRefund
                console.log(res.data.dayStatistics)

                let users = []
                let day = []

                let userData = []
                let da = []
                let total = 0
                let array = []
                let totalArr = []
                res.data.allUser.forEach(user => {
                    users.push(user.name)
                    for (let resKey in res.data.dayStatistics) {
                        if(!day.includes(resKey)) day.push(resKey)

                        res.data.dayStatistics[resKey].forEach(item => {
                            item.forEach(i => {
                                if(user.id == i.user_id){
                                    da.push(i.price)
                                }
                            })
                        })
                    }
                    userData.push({
                        name:user.name,
                        type:'bar',
                        stack:'Total',
                        // label: {
                        //     show: true,
                        //     position: 'top',
                        // },
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data:da
                    })
                    da = []

                })
                let i=0

                for (let resKey in res.data.dayStatistics) {
                    userData.forEach(user => {
                        if(user.data[i])
                            total += user.data[i]
                    })
                    i++
                    totalArr.push(total)
                    total = 0
                }
                i=0
                console.log('===============')
                console.log(userData)
                console.log(totalArr)
                userData[userData.length-1].label = {
                    show: true,
                    position: 'top',
                    formatter:function(p){
                        return totalArr[p.dataIndex]
                    }
                }




//             // 基于准备好的dom，初始化echarts实例

// // 绘制图表
                let option = {
                    title: {
                        text: ''
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                    },
                    legend: {
                        data: users
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                    },
                    xAxis: {
                        type: 'category',
                        data: day
                    },
                    yAxis: {
                        type: 'value',
                        max:function (value) {
                            return value.max + 50000;
                        }
                    },
                    series: userData
                };

                console.log(option)

                let option2 = {
                    title: {
                        text: '周统计'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                    },
                    legend: {
                        data: ["Food", "Cloth", "Book"]
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value',
                        name:'啊啊'
                    },
                    series: [
                        {
                            name: '第一周',
                            data: [120, 200, 150, 80, 70, 110, 130],
                            type: 'bar',
                        },{
                            name: '第二周',
                            data: [180, 100, 180, 280, 170, 130, 230],
                            type: 'bar',
                        },
                        {
                            name: '第三周',
                            data: [180, 100, 180, 280, 170, 130, 230],
                            type: 'bar',
                        },
                        {
                            name: '第四周',
                            data: [180, 100, 180, 280, 170, 130, 230],
                            type: 'bar',
                        }
                    ]
                };
                let everyday = this.$echarts.init(document.getElementById('everyday'));
                // let myChart2 = this.$echarts.init(document.getElementById('main2'));
                console.log(option)
                everyday.setOption(option,true);
                // myChart2.setOption(option2);
                option = null
                option2 = null
            }

        },
        async mounted() {
            this.getDetail()
        }
    }
</script>

<style scoped>
    .flex{
        display: flex;
    }
    .flex-wrap{
        flex-wrap: wrap;
    }
    .align-center{
        align-items: center;
    }
    .justify-around{
        justify-content : space-between;
    }
    .total-box{
        height: 120px;
        width: calc(16.6% - 20px);
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        border-radius: 8px;
    }
    .total-name{
        font-size: 30px;
        color: rgb(255, 255, 255);
        text-align: center;
        border-radius: 8px 0 0 8px;
        background: #cccccc;
        width: 30%;
        height: 120px;
        line-height: 120px;
    }
    .total-info{
        width: calc(70%);
        text-align: center;
        height: 100%;

    }
    .total-info-num{
        font-size: 20px;
        line-height: 85px;
    }
    .total-info-txt{

    }

</style>
