<template>
    <div v-loading="editLoading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >运营和营销</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/promotion' }">促销活动</el-breadcrumb-item>
                <el-breadcrumb-item>新建活动</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini" >


                <el-form-item label="名称：" label-position="left" prop="name" >
                    <el-input  style="width: 200px" v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="封面图片：" label-position="left" prop="pic" >
                    <edit-cover @success="uploadSuccess" :pic=" imageUrl + form.pic" :uploadPath="uploadPath"></edit-cover>
                </el-form-item>

                <el-form-item label="分享海报：" label-position="left" prop="SharePic" >
                    <edit-cover @success="uploadSuccessSharePic" :pic=" imageUrl + form.sharePic" :uploadPath="uploadPath">
                        <span slot="prompt">275*380像素或16:9，支持PNG、JPG、GIF格式，小于5M</span>
                    </edit-cover>
                </el-form-item>

                <el-form-item label="选择课程：" label-position="left" prop="name" >
                    <edit-product :select-product="selectProducts" @onSelect="onSelect" @resetSelect="resetSelect"></edit-product>
                </el-form-item>

                <el-form-item label="详情：" label-position="left" prop="content" >
                    <editor @onChange="onChange" :content="form.content"></editor>
                </el-form-item>

<!--                <el-form-item label="类型：" label-position="left" class="input" >-->
<!--                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">-->
<!--                        <el-option-->
<!--                                v-for="item in options"-->
<!--                                :key="item.value"-->
<!--                                :label="item.label"-->
<!--                                :value="item.value">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="活动时间：" label-position="left" class="input"  prop="startTime">
                    <el-date-picker
                            size="small"
                            v-model="value2"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            @change="changeDateTime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="价格：" label-position="left" class="input" prop="amount">
                    <el-input style="width: 200px"  v-model="form.amount"></el-input> 元
                </el-form-item>

                <el-form-item label="参与人数：" label-position="left" >
                    <el-input style="width: 200px"  v-model="form.restrict"></el-input>（0为无限制,团购活动为几人起拼团成功）
                </el-form-item>

                <el-form-item label="需要收货地址：" label-position="left" class="input" >
                    <el-switch v-model="form.needAddress" @change="setNeedAddress" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{form.needAddress ? '需要' : '不需要'}}</span>
                </el-form-item>

                <el-form-item label="需要邮箱：" label-position="left" class="input" >
                    <el-switch v-model="form.needEmail" @change="setNeedEmail" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{form.needEmail ? '需要' : '不需要'}}</span>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch :active-value="1" :inactive-value="0" v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/promotion" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import Editor from "../../../components/editor";
    import EditProduct from "../../../components/editProduct";
    import EditCover from "../../../components/editCover";
    export default {
        name: "template-add",
        components: {EditCover, EditProduct, Editor},
        data() {
            return {
                imageUrl:config.imageUrl,
                editLoading:false,
                statusTxt:'上架',
                loading:false,
                uploadPath:'store',
                selectProducts:[],
                form: {
                    id:'',
                    name:'',
                    type: 1,
                    status:true,
                    needAddress:1,
                    needEmail:1,
                    pic:'',
                    sharePic:'',
                    amount:0,
                    content:'',
                    restrict:0,
                    startTime:'',
                    endTime:'',
                    courseIds:[],
                    courseType:0
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < (Date.now() - 3600 * 1000 * 24);
                    },
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                options: [
                    {
                        value: 1,
                        label: '组合购'
                    },
                    {
                        value: 2,
                        label: '团购'
                    },
                    {
                        value: 3,
                        label: '整点秒杀'
                    },
                ],
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                    ],
                    pic: [
                        { type: 'string', required: true, message: '请选择图片封面', trigger: 'change' }
                    ],
                    sharePic: [
                        { type: 'string', required: true, message: '请选择分享海报', trigger: 'change' }
                    ],
                    content: [
                        { type: 'string', required: true, message: '请输入课程内容', trigger: 'blur' }
                    ],
                    amount: [
                        {  required: true, message: '请输入价格', trigger: 'blur' },
                    ],
                },

            }
        },
        methods: {
            ...mapActions('marketing',['addPromotion','getPromotionDetail']),
            uploadSuccess(path){
                this.form.pic = path
            },
            uploadSuccessSharePic(path){
                this.form.sharePic = path
            },
            changeDateTime(value){
                console.log(value !== null)
                if(value !== null){
                    this.form.startTime = value[0]
                    this.form.endTime = value[1]
                }else{
                    this.form.startTime = ''
                    this.form.endTime = ''
                }
            },
            onChange(content){
                this.form.content = content
            },
            onSelect(product){
                console.log('onSelectonSelectonSelect')
                console.log(product)
                this.form.courseIds = product[0].ids
                switch (product[0].type) {
                    case 'course' : this.form.courseType = 1;break
                    case 'checkCourse' : this.form.courseType = 2;break
                }
            },
            resetSelect(){
                this.form.courseIds = []
            },
            async onSubmit() {
                let _this = this
                this.loading = true
                console.log(this.form)

                if(!this.validateForm('form'))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }

                let res = await this.addPromotion(this.form)

                if(res.ret == 0)
                {
                    this.$message.success('修改成功！')
                    this.$router.push('/promotion')
                }

                this.loading = false
            },
            setNeedAddress(v){
                this.form.needAddress = v
            },
            setNeedEmail(v){
                this.form.needEmail = v
            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
        },
        async mounted() {
            this.editLoading = true
            this.form.id = this.$route.params.id
            let detail = await this.getPromotionDetail(this.form.id)
            this.form.name = detail.data.name
            this.form.amount = detail.data.amount
            this.form.content = detail.data.content
            this.form.pic = detail.data.pic
            this.form.sharePic = detail.data.share_pic
            this.form.type = detail.data.type
            this.form.restrict = detail.data.restrict
            this.form.startTime = detail.data.start_at
            this.form.endTime = detail.data.end_at
            if(this.form.startTime !== null && this.form.endTime !== null){
                this.value2 = [this.form.startTime,this.form.endTime]
            }
            this.form.courseIds = detail.data.courseIds
            this.form.status = detail.data.status
            this.form.needAddress = detail.data.need_address
            this.form.needEmail = detail.data.need_email
            this.form.courseType = detail.data.course_type
            this.selectProducts = detail.data.courses
            this.editLoading = false
        }
    }
</script>

<style>

</style>
