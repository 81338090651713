<template>
    <div>
        <div class="main-title">用户动态</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">

                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">

                <span class="fl-l " >
                    <el-input v-model="userName" placeholder="用户昵称" clearable size="small"></el-input>
                </span>

                <span class="margin-l-sm fl-l" >
                    <el-date-picker
                            size="small"
                            v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">

                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            width="150"
                            label="头像">
                        <template slot-scope="scope">
                            <el-avatar shape="square" :size="50" :src="scope.row.user_name_avatar_url" icon="el-icon-user-solid">
                            </el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="用户名"
                            width="200"
                            >
                        <template slot-scope="scope">
                            <span>{{scope.row.user_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="标题">
                        <template slot-scope="scope">
                            <span>{{scope.row.title}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="预览图" width="200">
                        <template slot-scope="scope">
                            <el-image
                                    v-if="scope.row.type === 1"
                                    style="width: 60px; height: 60px"
                                    :src="imageUrl+scope.row.pic_data[0]"
                                    fit="cover"
                                    :preview-src-list="picData(scope.row.pic_data)"
                            ></el-image>
                            <el-image
                                    v-if="scope.row.type === 2"
                                    style="width: 60px; height: 60px"
                                    :src="scope.row.video_pic"
                                    fit="cover"
                                    :preview-src-list="[scope.row.video_pic]"
                            ></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="类型">
                        <template slot-scope="scope">
                            <span>{{scope.row.type === 1 ? '图片' : '视频'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="状态">
                        <template slot-scope="scope">
                            <span>{{scope.row.status === 1 ? '待审核' : (scope.row.status === 2 ? '通过' : '未通过')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span> -
                            <span>
                                <router-link :to="'/userSocial/detail/'+scope.row.id" class="router-link" > <el-link type="primary">详情</el-link></router-link>
                            </span>
                            <span v-if="scope.row.type === 2">
                                -
                                <span>
                                    <el-link type="primary" @click="showPlayDialog(scope.row)">播放</el-link>
                                </span>
                            </span> -
                            <span>
                                <el-popover
                                        placement="top"
                                        width="160"
                                        v-model="scope.row.visible">
                                  <div >
                                    <el-button size="mini" type="success" @click="setStatus(scope.row,2)">通过</el-button>
                                    <el-button  size="mini" type="danger" @click="setStatus(scope.row,3)">不通过</el-button>
                                  </div>
                                  <el-link type="primary" slot="reference">审核</el-link>
                                </el-popover>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

        <el-dialog append-to-body title="播放" :visible.sync="dialogPlayVisible">
            <div v-loading="videoLoading">
                <div style="padding-bottom:20px">{{content}}</div>

                <video   controls="controls" :src="videoUrl"></video>

            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "list",
        data(){
            return {
                videoLoading:false,
                dialogPlayVisible:false,
                content:'',
                videoUrl:'',
                page:1,
                pageSize:10,
                total:0,
                userName:'',
                loading:false,
                imageUrl:config.imageUrl,
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [],
                currentRow: null,
            }
        },
        methods:{
            ...mapActions('user',['getUserSocialList','getUserSocialDetail','setUserSocialStatus']),
            ...mapActions('common',['delete']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            picData(pics){
                let arr = []
                if(pics.length > 0){
                    pics.forEach(pic => {
                        arr.push(this.imageUrl + pic)
                    })
                }
                return arr
            },
            setStatus(row, status) {
                let _this = this
                let data = {
                    id: row.id,
                    status: status,
                }
                this.setUserSocialStatus(data).then(res => {

                    if(res.ret === 0){
                        _this.$message({
                            message: '设置成功',
                            type: 'success'
                        });
                    }else{
                        _this.$message({
                            message: '设置失败',
                            type: 'error'
                        });
                    }

                    this.getList()

                })
            },
            showPlayDialog(row){
                this.videoLoading = true
                this.getUserSocialDetail({id:row.id}).then(res => {
                    console.log(res)
                    this.videoUrl = res.data.videoUrl
                    this.content = res.data.content
                    this.videoLoading = false
                })
                this.dialogPlayVisible = true
            },
            hidePlayDialog(){
                this.dialogPlayVisible = false
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            setBan(row,isBan){

            },

            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                let data = {
                    page:this.page,
                    pageSize:this.pageSize,
                    name:this.userName,
                    created_at_start:this.value2 == null ? '' : this.value2[0],
                    created_at_end:this.value2 == null ? '' : this.value2[1],
                }
                // if(this.userName != ''){
                //     data.name = this.userName
                // }
                this.loading = true
                this.getUserSocialList(data).then(res => {
                    this.total = res.data.total
                    this.tableData = res.data.list

                    this.loading = false
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            deleted(row){
                this.loading = true
                this.delete({id:row.id,model:"Social"}).then(res => {
                    this.getList()
                    if(res.ret  == 0){
                        this.$message.success('删除成功')
                    }

                })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>

</style>
