<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >教研中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/teacherCourse' }">课程</el-breadcrumb-item>
                <el-breadcrumb-item>新建课程</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="mini" >

                <el-form-item label="名称：" label-position="left" prop="name" class="input">
                    <el-input style="width: 200px"  v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="封面：" label-position="left" prop="pic"  >
                    <cover :uploadPath="uploadPath" @success="uploadSuccess"></cover>
                </el-form-item>

                <el-form-item label="年级：" label-position="left" class="input" prop="grade">
                    <el-select v-model="form.grade" clearable placeholder="选择年级" size="small">
                        <el-option
                                v-for="item in grades"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="类型：" label-position="left" class="input" prop="type">
                    <el-select v-model="form.type" clearable placeholder="选择类型" size="small">
                        <el-option
                                v-for="item in types"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="主题：" label-position="left" class="input" prop="theme">
                    <el-select v-model="form.theme" clearable placeholder="选择主题" size="small">
                        <el-option
                                v-for="item in themes"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="课程介绍：" label-position="left" prop="content">
                    <editor @onChange="onChange" :content="form.content"></editor>
                </el-form-item>

                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit('form')">保存</el-button>
                    <router-link to="/teacherCourse" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import cover from '../../../components/cover'
    import editor from '../../../components/editor'
    import {mapActions} from "vuex";
    export default {
        name: "Add",
        components:{cover,editor},
        data() {
            return {
                statusTxt:'上架',
                payStatusTxt:'付费',
                loading:false,
                inputVisible: false,
                inputValue: '',
                uploadPath:'teacherCourse/coursePic',
                grades:[],
                types:[],
                themes: [],
                form: {
                    name:'',
                    pic:'',
                    grade:'',
                    type:'',
                    theme:'',
                    content:''
                  },
                rules: {
                    name: [
                            { required: true, message: '请输入课程名称', trigger: 'blur' },
                        ],
                    pic: [
                        { type: 'string', required: true, message: '请选择图片封面', trigger: 'change' }
                        ],
                    content: [
                            { type: 'string', required: true, message: '请输入课程内容', trigger: 'blur' }
                        ],
                    grade: [
                        { required: true, message: '请选择年级', trigger: 'change' },
                    ],
                    type: [
                        { required: true, message: '请选择类型', trigger: 'change' },
                    ],
                    theme: [
                        { required: true, message: '请选主题', trigger: 'change' },
                    ],
                 },

            }
        },
        methods: {
            ...mapActions('teacherCourse',['addTeacherCourse']),
            ...mapActions('common',['getBaseData']),
            uploadSuccess(path){
                this.form.pic = path
            },
            handleChange(value){
                let arr = []
                console.log(value)
                value.forEach(item => {
                    arr.push(item[1])
                })

                this.form.gradeId = arr
            },
            async onSubmit(formName) {

                if(!this.validateForm(formName))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }

                let _this = this
                this.loading = true


                this.addTeacherCourse(this.form).then(res =>{

                    if(res.ret == 0)
                    {
                        this.$message.success('创建课程成功！')
                        this.$router.push('/teacherCourse')
                    }

                }).finally(()=>{
                    this.loading = false
                })


            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },

            handleClose(tag) {
                this.form.tags.splice(this.form.tags.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(() => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.form.tags.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            onChange(content){
                console.log(content)
                this.form.content = content
            }
        },
        async mounted() {

            let baseData = await this.getBaseData()

            baseData.data.teacherCourseConfig.forEach(item => {
                switch (item.type) {
                    case 1 : this.grades.push(item) ;break;
                    case 2 : this.types.push(item) ;break;
                    case 3 : this.themes.push(item) ;break;
                }
            })


        }
    }
</script>

<style>
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
</style>
