<template>
    <div>
        <div>
            <el-row type="flex" justify="end">
<!--            <div>-->
<!--                <el-input v-model="searchName" size="mini" style="width:160px" placeholder="课件名称" clearable></el-input>-->
<!--                <el-button class="margin-l-sm" size="mini" @click="search">筛选</el-button>-->
<!--            </div>-->
            <div ><el-button type="primary" size="mini" icon="el-icon-plus" @click="addFile">添加资料</el-button></div>
            </el-row>

        </div>
        <div class="margin-t-sm">

            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            label="名称">
                        <template slot-scope="scope">
                            <span>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="大小">
                        <template slot-scope="scope">
                            <span>{{parseFloat((scope.row.size/1024)/1024).toFixed(2) + 'M'}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link type="primary" @click="view(scope.row,scope.$index)">查看</el-link>
                            </span> -
                            <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                            <span>
                                -
                                <el-link type="primary" @click="download(scope.row,scope.$index)">下载</el-link>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch"  style="padding-bottom: 10px">
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>

        </div>


        <!--查看文件-->
        <el-dialog
                :fullscreen="true"
                :show-close="true"
                append-to-body
                :visible.sync="dialogVisible"
                width="30%">
                <div style="width: 100%;height: 100%" >
                    <iframe @load="loadFinish"  id="iframe" frameborder="0" style="width: 100%;" :height="height-100" :src="fileUrl">
                    </iframe>
                </div>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../../../utils/tools";
    export default {
        props:['classTotal'],
        components: { },
        name: "file",
        data(){
            return{
                downloadUrl: config.downloadUrl,
                iframeLoading:false,
                height:document.documentElement.clientHeight,
                dialogVisible:false,
                isSubmit:false,
                isShowDialog:false,
                fileUrl:'',
                total:0,
                loading:true,
                page:1,
                pageSize:10,
                name:'',
                tableData:[],
                id:0,
                searchName:'',
                form:{
                    name:'',
                    courseId:0,
                    fileId:0,
                },
            }
        },
        watch:{
            classTotal:function(val){

            }
        },
        methods:{
            ...mapActions('teacherCourse',['getTeacherCourseFileList','delTeacherCourseFile']),
            search(){
                this.getCourseFileList()
            },
            addFile(){
                this.$router.push('/teacherCourse/addFile/'+this.id)
            },
            loadFinish(){
                // this.$message.info('已成功加载')
                // console.log(111)
            },
            getCourseFileList(){
                this.loading = true
                let data = {
                    teacherCourseId:this.id,
                    name:this.searchName,
                    page:this.page,
                    pageSize:this.pageSize
                }
                this.getTeacherCourseFileList(data).then(res => {
                    if(res.ret == 0)
                    {
                        this.total = res.data.total
                        this.tableData = res.data.list
                        this.loading = false
                    }
                })
            },

            percentage(p){
                return tools.percentage(p)
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getCourseFileList()
            },
            setPage(page){
                this.page  = page
                this.getCourseFileList()
            },
            view(row){
                this.iframeLoading = true
                this.fileUrl = 'https://view.officeapps.live.com/op/view.aspx?src='+imageUrl + row.path
                console.log(this.fileUrl)
                this.dialogVisible = true
            },
            download(row){
                window.open(this.downloadUrl + row.path,'_self')
            },
            deleted(row, rowIndex){
                let _this = this
                this.loading = true
                console.log(row.id)
                this.delTeacherCourseFile(row.id).then(success => {
                    _this.getCourseFileList()
                    _this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                },error => {
                    _this.$message({
                        message: '删除失败',
                        type: 'error'
                    });
                })
            },
        },
        mounted() {
            this.id = this.$route.params.id
            this.form.courseId = this.$route.params.id
            this.getCourseFileList()

        }
    }
</script>

<style scoped>

</style>