<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >资源管理</el-breadcrumb-item>
                <el-breadcrumb-item >题库</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
			<div class="padding-b-sm">
				<el-form :model="form"  ref="form1">
					<el-form-item label="题库名称" prop="name" :rules="[{ required: true, message: '请输入题库名称' , trigger: 'blur'}]">
						<el-input style="width: 18.75rem;" v-model="form.name" placeholder="题库名称"></el-input>
						<el-button class="margin-l-sm" size="mini" type="primary" @click="addTab(currentTab)" >插入题目</el-button>
					</el-form-item>
				</el-form>
			</div>
            <div>
                <el-tabs  v-model="currentTab" type="card" closable @tab-remove="removeTab" @tab-click="selectTab">
                    <el-tab-pane
                            v-for="item in editableTabs"
                            :key="item.name"
                            :label="item.title"
                            :name="item.name"
                    >
                    <div>
                        <el-form :model="form.questions[currentTab]" label-width="100px" ref="form" class="demo-ruleForm">
                            <el-form-item label="题目类型：" prop="type">
                                <el-select size="mini" v-model="form.questions[currentTab].type" placeholder="请选择" @change="changeType(form.questions[currentTab].type)">
                                    <el-option
                                            v-for="item in questionType"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
							<el-form-item label="选项类型：" prop="textType">
							    <el-select size="mini" v-model="form.questions[currentTab].text_type" placeholder="请选择" :disabled="flag">
							        <el-option
							                v-for="item in textType"
							                :key="item.value"
							                :label="item.label"
							                :value="item.value">
							        </el-option>
							    </el-select>
							</el-form-item>
                            <el-form-item label="题目：" prop="content" :rules="[{ required: true, message: '请输入题目', trigger: 'blur' }]">
                                <el-input v-model="form.questions[currentTab].content " placeholder="请输入内容"></el-input>
                            </el-form-item>
                            <el-form-item label="题目选项：" prop="items" v-if="form.questions[currentTab].type === 4 || form.questions[currentTab].text_type === 1">
                                <div v-for="(opt,index) in form.questions[currentTab].items" :key="index">
                                    <el-input class="input" v-model="opt.item" placeholder="输入选项"></el-input>
                                    <el-button class="margin-l-sm" size="mini" @click.prevent="removeOption(index)">删除</el-button>
                                </div>
                                <el-button size="mini" type="primary" @click="addOption">添加选项</el-button>
                            </el-form-item>
							
							<el-form-item label="题目选项：" label-position="left" prop="form.questions[currentTab].items" v-else-if="form.questions[currentTab].text_type === 2">
								<paste-image upload-path="question" @onSuccess="onSuccess" @onRemove="onRemove"></paste-image>
							</el-form-item>
							
                            <el-form-item label="题目答案：" prop="answer.answer" :rules="[{ required: true, message: '请输入答案', trigger: 'blur' }]">
                                <el-input v-model="form.questions[currentTab].answer.answer" placeholder="请输入内容"></el-input>
                            </el-form-item>
                            <el-form-item label="题目解析：" prop="analyses.content" :rules="[{ required: true, message: '请输入解析', trigger: 'blur' }]">
                                <el-input v-model="form.questions[currentTab].analyses.content" type="textarea"
                                          :rows="10"
                                          placeholder="请输入内容"></el-input>
                            </el-form-item>
                        </el-form>

                    </div>
                    </el-tab-pane>
                </el-tabs>

                <el-button class="margin-l-sm" size="mini" type="primary" @click="validateForm()" >保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
	import PasteImage from "../../../components/pasteImage/index";
    export default {
        name: "add",
		components: { PasteImage },
        data() {
            return {
				uploadPath: 'question',
				uploadNumber: 4,
                currentTab:'0',
				textType: [
					{
						value: 1,
						label: '文本'
					},
					{
						value: 2,
						label: '图片'
					}
				],
                questionType:[
                    {
                        value: 1,
                        label: '单选题'
                    },
                    {
                        value: 2,
                        label: '多选题'
                    },
                    {
                        value: 3,
                        label: '判断题'
                    },
                    {
                        value: 4,
                        label: '填空题'
                    },
                ],
                editableTabs: [{
                    title: '题目1',
                    name: '0',
                }],
                form:{
                    name:'',
                    questions:[
                        {
							content: '',
                            type:1,
							text_type:1,
                            items:[],
                            answer: {answer: ''},
                            analyses: {content: ''}
                        }
                    ]
                },
				// 控制选项是否可选
				flag: false
            }
        },
        methods: {
			...mapActions('questionBank',['addQuestionBank']),
            //添加选项
            removeOption(index){
                this.form.questions[this.currentTab].items.splice(index, 1)
            },
            //添加选项
            addOption(){
                this.form.questions[this.currentTab].items.push({
                    item:'',
                })
            },
			// 表单提交前
			validateForm(){
				this.$refs['form1'].validate(async valid => {
					if (!valid) {
						return 
					} 
				})
				this.$refs.form[this.currentTab].validate(async valid => {
				  if (!valid) {
					return 
				  } 
				  this.submit()
				})
			},
            //提交表单保存
            async submit(){
               const {res_info, data } = await this.addQuestionBank(this.form)
			   if(res_info !== 'ok') {
				   this.$message.error('保存失败!')
				   return
			   }
			   this.$message.success('保存成功!')
			   this.$router.push('/question')
            },
            selectTab(tab,event){
				this.currentTab = tab.index
            },
            //添加题目
            addTab(currentTab) {				
                let newTabName = ++currentTab;
                this.editableTabs.push({
                    title: '题目'+(newTabName + 1),
                    name: newTabName + '',
                });
                this.form.questions.push({
                   content: '',
                   type:1,
                   text_type:1,
                   items:[],
                   answer: {answer: ''},
                   analyses: {content: ''}
                })
                this.currentTab = newTabName + '';
				this.currentIndex = Number(this.currentTab)
            },
            //删除题目
            removeTab(targetName) {
                if(this.editableTabs.length === 1)
                {
                    this.$message.error('至少保存一道题目')
                    return
                }
                let tabs = this.editableTabs;
                let activeName = this.currentTab;
                if (activeName === targetName) {
					var vm = this
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1];
                            if (nextTab) {
                                activeName = nextTab.name;
                            }
                        }
                    });
                    vm.form.questions.splice(targetName, 1)
                }
                this.currentTab = activeName;
                this.editableTabs = tabs.filter(tab => tab.name !== targetName);
            },
			// 图片上传
			onSuccess(path) {
				var pics = path + ''
				var that = this
				var pathList = pics.split(',')
				var relPath = pathList[pathList.length - 1]
				that.form.questions[that.currentTab].items.push({item: relPath})
			},
			// 图片移除
			onRemove(index,file){
				this.form.questions[this.currentTab].items.splice(index,1)
			},
			changeType(val){
				if(val === 4){
					this.form.questions[this.currentTab].text_type = 1
					this.flag = true
				}else{
					this.flag = false
				}
			}
        }
    }
</script>
