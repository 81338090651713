<template>
  <div>
    <div>
      <span class="margin-r-sm">
        <el-date-picker
          size="mini"
          v-model="date_pick"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </span>
      <el-select
        v-model="searchType"
        slot="prepend"
        placeholder="选择类型"
        size="mini"
      >
        <el-option label="微信昵称" :value="1"></el-option>
        <el-option label="UID" :value="2"></el-option>
      </el-select>
      <el-input
        class="margin-l-sm"
        v-model="studentName"
        size="mini"
        style="width: 160px"
        clearable
      ></el-input>

      <el-button class="margin-l-sm" size="mini" @click="search"
        >筛选</el-button
      >
      <el-button class="fl-r" size="mini" @click="derivelist"
        >导出列表
      </el-button>
      <el-button
        class="fl-r"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="showAddStudent"
        >添加学员</el-button
      >
      <div class="clear"></div>
    </div>
    <div class="margin-t-sm">
      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0 }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="序号" property="id" width="80">
          </el-table-column>
          <el-table-column width="80" label="头像">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.user_name_avatar_url"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="用户id">
            <template slot-scope="scope">
              <span>{{ scope.row.user_id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.user_name }}</span>
            </template>
          </el-table-column>

          <el-table-column property="created_at" label="更新时间" width="200">
            <template slot-scope="scope">
              <p>{{ scope.row.start_at }}</p>
              <p>{{ scope.row.end_at }}</p>
            </template>
          </el-table-column>
          <!--                    <el-table-column-->
          <!--                            property="index"-->
          <!--                            label="已学课时"-->
          <!--                            width="120">-->
          <!--                        <template slot-scope="scope">-->
          <!--                            <span>{{scope.row.now_day}}</span>-->
          <!--                        </template>-->
          <!--                    </el-table-column>-->
          <el-table-column property="index" label="学习课时数">
            <template slot-scope="scope">
              <p>
                {{ scope.row.start_plan_day }} - {{ scope.row.end_plan_day }}
              </p>
            </template>
          </el-table-column>
          <el-table-column property="index" label="当前更新天数">
            <template slot-scope="scope">
              <span>{{ scope.row.now_day }}</span>
            </template>
          </el-table-column>
                    <el-table-column label="创建人" property="creator" >
          </el-table-column>
          <el-table-column property="index" label="截止时间" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.out_at }}</span>
            </template>
          </el-table-column>
          <el-table-column property="address" label="更新周期" width="200">
            <template slot-scope="scope">
              {{ scope.row.update_day }}
            </template>
          </el-table-column>
          <el-table-column property="address" label="备注" width="200">
            <template slot-scope="scope">
              {{ scope.row.remark }}
            </template>
          </el-table-column>
          <el-table-column property="address" label="操作">
            <template slot-scope="scope">
              <span>
                <el-link type="primary" @click="editStudent(scope.row)"
                  >编辑</el-link
                >
              </span>
              -
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r padding-b-sm">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog
      title="添加学员"
      append-to-body
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="showAddStudentDialog"
      width="50%"
    >
      <div>
        <div class="padding-sm" v-if="this.form.id === 0">
          <el-select
            v-model="searchType"
            slot="prepend"
            placeholder="选择类型"
            size="mini"
            @change="changeSearch"
          >
            <el-option label="微信昵称" :value="1"></el-option>
            <el-option label="UID" :value="2"></el-option>
          </el-select>

          <el-select
            class="margin-l-sm"
            v-model="studentName"
            size="mini"
            clearable
            filterable
            remote
            reserve-keyword
            @change="selectUser"
            @clear="clearUser"
            placeholder="请输入关键词"
            :remote-method="searchStudent"
            :loading="loading"
          >
            <el-option
              style="height: 40px; line-height: 40px"
              v-for="item in users"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <div
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  height: 40px;
                  line-height: 40px;
                "
              >
                <span style="margin-right: 10px"> {{ item.id }} </span>
                <el-avatar size="small" :src="item.avatar_url"></el-avatar>
                <span style="margin-left: 10px">{{ item.name }}</span>
              </div>
            </el-option>
          </el-select>
        </div>

        <div class="margin-t-sm" v-if="notData">暂无数据</div>
        <div>
          <div style="margin-top: 12px">
            <div
              v-if="JSON.stringify(selectCurrentUser) !== '{}'"
              style="
                margin-top: 12px;
                display: flex;
                align-items: center;
                padding: 10px;
                border-radius: 4px;
                margin: 4px;
              "
            >
              <el-avatar
                size="small"
                :src="selectCurrentUser.avatar_url"
              ></el-avatar>
              <span style="margin-left: 4px">{{ selectCurrentUser.name }}</span>
            </div>

            <el-form ref="form" label-width="auto" :model="form" :rules="rules">
              <el-form-item label="开始时间: " prop="start_at">
                <el-date-picker
                  value-format="yyyy-MM-dd hh:mm:ss"
                  v-model="form.start_at"
                />
              </el-form-item>

              <el-form-item label="是否全部计划: " prop="is_all">
                <el-switch
                  v-model="form.is_all"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>

              <template v-if="form.is_all === 0">
                <el-form-item label="开始计划天数: " prop="start_plan_day">
                  <el-input v-model.number="form.start_plan_day" />
                </el-form-item>

                <el-form-item label="结束计划天数: " prop="end_plan_day">
                  <el-input v-model.number="form.end_plan_day" />
                </el-form-item>
              </template>

              <el-form-item label="更新周期: " prop="update_day">
                <el-checkbox-group v-model="form.update_day" size="small">
                  <el-checkbox-button
                    v-for="(city, index) in [
                      '日',
                      '一',
                      '二',
                      '三',
                      '四',
                      '五',
                      '六',
                    ]"
                    :label="index"
                    :key="city"
                  >
                    周{{ city }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="备注: ">
                <el-input
                  placeholder="请输入内容"
                  type="textarea"
                  :rows="6"
                  resize="none"
                  maxlength="500"
                  show-word-limit
                  v-model="form.remark"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideAddStudent" size="mini">取 消</el-button>
        <el-button
          type="primary"
          @click="addStudent"
          size="mini"
          :loading="submitBtnLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import tools from "../../../utils/tools";
export default {
  props: ["classTotal", "courseName"],
  name: "student",
  data() {
    return {
      date_pick: [],
      submitBtnLoading: false,
      searchStudentBtn: false,
      studentName: "",
      searchType: 1,
      showAddStudentDialog: false,
      searchName: "",
      total: 0,
      loading: true,
      page: 1,
      pageSize: 10,
      name: "",
      tableData: [],
      id: 0,
      searchStudents: [],
      notData: false,
      selectStudent: [],
      setLoading: false,
      workCountLoading: false,
      workCount: 0,
      users: [],
      selectCurrentUser: {},
      form: {
        id: 0,
        start_at: "",
        is_all: 1,
        check_course_id: 0,
        user_id: 0,
        start_plan_day: 0,
        end_plan_day: 0,
        update_day: [1, 2, 4, 5],
        remark: "",
      },
      rules: {
        start_at: [{ required: true }],
        is_all: [{ required: true }],
        user_id: [{ required: true }],
        start_plan_day: [{ required: true }],
        update_day: [{ required: true }],
        end_plan_day: [{ required: true }],
      },
    };
  },
  watch: {
    classTotal: function (val) {},
    courseName: function (val) {
      console.log(val);
    },
  },
  methods: {
    ...mapActions("punch", ["getCheckStudent", "addCheckStudent"]),
    ...mapActions("user", ["getUserList", "exportUsers"]),
    ...mapActions("common", ["delete"]),
    search() {
      this.page = 1;
      this.getCourseStudentList();
    },
    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          this.selectCurrentUser.name = item.name;
          this.selectCurrentUser.id = item.id;
          this.selectCurrentUser.avatar_url = item.avatar_url;
        }
      });
      this.form.user_id = id;
      console.log(this.form);
    },
    clearUser() {},
    searchStudent(v) {
      console.log("searchStudent");
      let searchData = {};
      searchData.page = 1;
      searchData.date_pick = this.date_pick;
      if (this.searchType === 1) {
        searchData.name = v;
        searchData.pageSize = 1000;
      } else {
        searchData.uid = v;
        searchData.pageSize = 1000;
      }
      console.log(searchData);
      if (v === "") {
        this.$message.error("请输入昵称");
        return;
      }
      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    changeSearch(e) {
      console.log("=================");
      console.log(e);
    },
    showAddStudent() {
      this.form.id = 0;
      this.form.start_at = "";
      this.form.is_all = 1;
      this.form.user_id = 0;
      this.form.start_plan_day = 0;
      this.form.end_plan_day = 0;
      this.form.update_day = [1, 2, 4, 5];
      this.selectCurrentUser = {};
      this.showAddStudentDialog = true;
    },
    hideAddStudent() {
      this.showAddStudentDialog = false;
    },
    subStudent(student) {
      console.log(student);
      this.selectStudent.splice(this.selectStudent.indexOf(student), 1);
    },
    addStudent() {
      this.submitBtnLoading = true;
      console.log(this.form);
      this.addCheckStudent(this.form)
        .then((res) => {
          if (res.ret === 0) {
            this.$message.success("操作成功");
            this.studentName = "";
            this.hideAddStudent();
            this.getCourseStudentList();
          }
        })
        .finally(() => {
          this.submitBtnLoading = false;
        });
    },
    editStudent(item) {
      this.showAddStudent();
      this.form.id = item.id;
      this.form.start_at = item.start_at;
      this.form.is_all = item.is_all;
      this.form.check_course_id = item.check_course_id;
      this.form.user_id = item.user_id;
      this.form.start_plan_day = item.start_plan_day;
      this.form.end_plan_day = item.end_plan_day;
      this.form.update_day = item.update_day;
      this.form.remark = item.remark;
      this.selectCurrentUser.name = item.user_name;
      this.selectCurrentUser.id = item.user_id;
      this.selectCurrentUser.avatar_url = item.user_name_avatar_url;
      console.log(item);
    },
    getCourseStudentList() {
      this.loading = true;
      let data = {
        check_course_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      if (this.searchType === 1 && this.studentName !== "") {
        data.user_name = this.studentName + "";
      }
      if (this.searchType === 2 && this.studentName !== "") {
        data.user_id = this.studentName;
      }
      this.getCheckStudent(data).then((res) => {
        if (res.ret == 0) {
          this.total = res.data.total;
          this.tableData = res.data.list;
          this.loading = false;
        }
      });
    },
    percentage(p) {
      return tools.percentage(p);
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getCourseStudentList();
    },
    setPage(page) {
      this.page = page;
      this.getCourseStudentList();
    },
    setStatus(row, rowIndex) {
      let _this = this;
      let data = {
        id: row.id,
        status: !row.status,
      };

      this.loading = true;
      this.setCourseUserStatus(data).then(
        (success) => {
          _this.tableData[rowIndex].status = success.data.status;

          _this.$message({
            message: "设置成功",
            type: "success",
          });
          this.loading = false;
        },
        (error) => {
          _this.$message({
            message: "设置失败",
            type: "error",
          });
          this.loading = false;
        }
      );
    },
    async derivelist() {
      let from = {
        check_course_id: this.form.check_course_id,
        date_pick: this.date_pick,
      };
      if (this.searchType === 1) {
        from.user_name = this.studentName;
      } else {
        from.user_id = this.studentName;
      }
      console.log(from);
      const { data } = await this.exportUsers(from);
      if (!data) {
        return this.$message.error("筛选导出的数据为空！");
      }
      var blob =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        data;
      var fileName = "学员" + tools.getFormatDate() + ".xlsx";
      tools.downloadFileByBase64(blob, fileName);
    },
    deleted(row, rowIndex) {
      let _this = this;
      this.loading = true;
      this.delete({ id: row.id, model: "UserCheckCourse" }).then((res) => {
        this.getCourseStudentList();
        if (res.ret == 0) {
          this.$message.success("删除成功");
        }
      });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.form.check_course_id = this.id;
    this.getCourseStudentList();
  },
};
</script>

<style>
.icon-hover {
  cursor: pointer;
  font-size: 14px;
}
.icon-hover:hover:before {
  font-size: 14px;
  content: "\e7a0";
}
.el-tag {
  height: auto;
  line-height: auto;
}
.el-tag .el-icon-close {
  top: 5px;
}
</style>
