<template>
    <div>

        <div class="mainBox">

            <!-- 波形绘制区域 -->
            <div v-if="status!=0">
            <div class="pd recpower" >
                <div style="height:40px;width:300px;background:#ececec;position:relative;border:1px solid #ccc;border-radius:5px 5px  0 0 ;border-bottom: 0">
                    <div class="recpowerx" style="height:40px;background:#0B1;position:absolute;"></div>
                    <div class="recpowert" style="padding-left:50px; line-height:40px; position: relative;"></div>
                </div>
            </div>
            <div class="pd waveBox">
                <div style="border:1px solid #ccc;display:inline-block;border-radius: 0 0 5px 5px">
                    <div style="height:100px;width:300px;" class="recwave"></div>
                </div>
            </div>
            </div>

            <!-- 按钮控制区域 -->
            <div class="pd btns">
                <div style="padding-bottom: 10px;padding-top: 10px">
                    <el-button size="mini" v-if="status==0" round @click="recOpen()" style="margin-right:10px" icon="el-icon-mic">添加录音</el-button>
                    <el-button size="mini" v-if="status==1" round @click="recStart()" title="开始录制"  icon="el-icon-caret-right ">开始录制</el-button>
                    <el-button size="mini" v-if="status==3" round @click="recRestart()" title="重新录制"  icon="el-icon-refresh">重新录制</el-button>
                    <el-button size="mini" v-if="status==2" round @click="recStop()" title="停止" icon="el-icon-video-pause">停止</el-button>

                    <!--				<el-button circle @click="recPause()" title="暂停" icon="el-icon-video-pause"></el-button>-->
                    <!--				<el-button circle @click="recResume()" title="继续"></el-button>-->
                    <el-button size="mini" v-if="status==3" round @click="recPlay()" title="播放" icon="el-icon-video-play">播放</el-button>
                    <el-button size="mini" v-if="status==3 && uploadSuccess==false" round @click="recUpload()" :loading="isUpload" title="上传" icon="el-icon-upload ">上传</el-button>
                </div>

            </div>

            <div id="audio"></div>
<!--            <div >上传成功-->
<!--                <audio controls src="https://xxyw-test1.oss-cn-qingdao.aliyuncs.com/workRecorder/1611298293197.mp3"></audio>-->
<!--            </div>-->
        </div>

    </div>
</template>

<script>
    import Recorder from '../../components/recorder/js/recorder-core'
    import  '../../components/recorder/js/lib.fft'
    import  '../../components/recorder/js/mp3'
    import  '../../components/recorder/js/wav'
    import  '../../components/recorder/js/waveview'
    import  '../../components/recorder/js/frequency.histogram.view'
    import {mapActions} from "vuex";
    export default {
        name: "index",
        data(){
            return {
                rec:'',
                wave:'',
                recBlob:'',
                dialogInt:'',
                status:0,
                uploadUrl:'',
                policy:'',
                accessid:'',
                signature:'',
                callback:'',
                dir:'workRecorder',
                isUpload:false,
                uploadSuccess:false
            }
        },
        methods:{
            ...mapActions('common',['getOssInfo']),
            recOpen(){
                this.status = 1
                let _this = this
                this.rec=null;
                this.wave=null;
                this.recBlob=null;
                var newRec=Recorder({
                    type:"mp3",sampleRate:16000,bitRate:16 //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
                    ,onProcess:function(buffers,powerLevel,bufferDuration,bufferSampleRate,newBufferIdx,asyncEnd){
                        //录音实时回调，大约1秒调用12次本回调
                        document.querySelector(".recpowerx").style.width=powerLevel+"%";
                        document.querySelector(".recpowert").innerText= (bufferDuration/1000)+" 秒 / "+powerLevel;

                        //可视化图形绘制
                        _this.wave.input(buffers[buffers.length-1],powerLevel,bufferSampleRate);
                    }
                });

                this.createDelayDialog(); //我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调，此处demo省略了弹窗的代码
                newRec.open(function(){//打开麦克风授权获得相关资源
                    _this.dialogCancel(); //如果开启了弹框，此处需要取消

                    _this.rec=newRec;

                    //此处创建这些音频可视化图形绘制浏览器支持妥妥的
                    _this.wave=Recorder.FrequencyHistogramView({elem:".recwave"});
                    _this.$message.success('已打开录音，可以点击录制开始录音了')
                    _this.reclog("已打开录音，可以点击录制开始录音了",2);
                },function(msg,isUserNotAllow){//用户拒绝未授权或不支持
                    _this.dialogCancel(); //如果开启了弹框，此处需要取消
                    _this.reclog((isUserNotAllow?"UserNotAllow，":"")+"打开录音失败："+msg,1);
                    _this.$message.error('打开录音失败:'+msg)
                });

                window.waitDialogClick=function(){
                    console.log(_this)
                    _this.dialogCancel();
                    _this.reclog("打开失败：权限请求被忽略，<span style='color:#f00'>用户主动点击的弹窗</span>",1);
                    _this.$message.error('打开失败：权限请求被忽略')
                }
            },

            recStop(){
                this.status=3
                let _this  = this
                if(!(_this.rec&&Recorder.IsOpen())){
                    _this.reclog("未打开录音",1);
                    _this.$message.error('打开失败：权限请求被忽略，:')
                    return;
                }
                _this.rec.stop(function(blob,duration){
                    console.log(blob,(window.URL||webkitURL).createObjectURL(blob),"时长:"+duration+"ms");

                    _this.recBlob=blob;
                    _this.reclog("已录制mp3："+duration+"ms "+blob.size+"字节，可以点击播放、上传了",2);
                    _this.$message.success("已录制mp3："+duration+"ms "+blob.size+"字节，可以点击播放、上传了")
                },function(msg){
                    _this.reclog("录音失败:"+msg,1);
                    _this.$message.error("录音失败:"+msg)
                });
            },
            recStart(){
                this.status=2
                if(this.rec&&Recorder.IsOpen()){
                    this.recBlob=null;
                    this.rec.start();
                    console.log(this.rec)
                    this.reclog("已开始录音...");
                }else{
                    this.reclog("未打开录音",1);
                }
            },
            recRestart(){
                this.uploadSuccess  = false
                this.isUpload  = false
                let nodes = document.querySelector("#audio").childNodes
                console.log(nodes)
                if(nodes.length > 0){
                    while (nodes.length > 0){
                        document.querySelector("#audio").removeChild(document.querySelector("#audio > audio"));
                    }

                }

                this.recStart()
            },
            recPause(){
                if(this.rec&&Recorder.IsOpen()){
                    this.rec.pause();
                }else{
                    this.reclog("未打开录音",1);
                }
            },
            recResume(){
                if(this.rec&&Recorder.IsOpen()){
                    this.rec.resume();
                }else{
                    this.reclog("未打开录音",1);
                }
            },
            recPlay(){
                if(!this.recBlob){
                    this.reclog("请先录音，然后停止后再播放",1);
                    this.$message.error("请先录音，然后停止后再播放")
                    return;
                }
                var cls=("a"+Math.random()).replace(".","");
                this.reclog('播放中: <span class="'+cls+'"></span>');
                var audio=document.createElement("audio");
                audio.controls=false;
                if(document.querySelector("#audio").childNodes.length == 0){
                    document.querySelector("#audio").appendChild(audio);
                }
                audio.currentTime = 0
                //简单利用URL生成播放地址，注意不用了时需要revokeObjectURL，否则霸占内存
                audio.src=(window.URL||webkitURL).createObjectURL(this.recBlob);
                audio.play();

                setTimeout(function(){
                    (window.URL||webkitURL).revokeObjectURL(audio.src);
                },5000);
            },
            //上传
            recUpload(){
                let _this = this
                var blob=this.recBlob;
                _this.isUpload = true
                if(!blob){
                    this.reclog("请先录音，然后停止后再上传",1);
                    this.$message.error("请先录音，然后停止后再上传")
                    return;
                }
                let fileName = this.dir + '/'+new Date().getTime()+'.mp3'

                //本例子假设使用原始XMLHttpRequest请求方式，实际使用中自行调整为自己的请求方式
                //录音结束时拿到了blob文件对象，可以用FileReader读取出内容，或者用FormData上传
                var api=this.uploadUrl;
                var onreadystatechange=function(title){
                    return function(){
                        if(xhr.readyState==4){
                            if(xhr.status==200){
                                _this.uploadSuccess=true
                                _this.reclog(title+"上传成功",2);
                                _this.$message.success('录音上传成功！')
                                _this.$emit('on-success',fileName)
                            }else{
                                _this.reclog(title+"没有完成上传，演示上传地址无需关注上传结果，只要浏览器控制台内Network面板内看到的请求数据结构是预期的就ok了。", "#d8c1a0");

                                console.error(title+"上传失败",xhr.status,xhr.responseText);
                            }
                        }
                    }
                }
                this.reclog("开始上传到"+api+"，请求稍后...");

                var form=new FormData();

                //...其他表单参数
                form.append('key',fileName)
                form.append('policy',this.policy)
                form.append('OSSAccessKeyId',this.accessid)
                form.append('signature',this.signature)
                form.append('callback',this.callback)
                form.append("file",blob,"recorder.mp3"); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
                console.log(form)

                var xhr=new XMLHttpRequest();
                xhr.open("POST", api);
                xhr.onreadystatechange=onreadystatechange("上传方式二【FormData】");
                xhr.send(form);

            },
            createDelayDialog(){
                this.dialogInt=setTimeout(function(){//定时8秒后打开弹窗，用于监测浏览器没有发起权限请求的情况，在open前放置定时器利于收到了回调能及时取消（不管open是同步还是异步回调的）
                    this.showDialog();
                },8000);
            },
            showDialog(){
                if(!/mobile/i.test(navigator.userAgent)){
                    return;//只在移动端开启没有权限请求的检测
                }
                this.dialogCancel();

                //显示弹框，应该使用自己的弹框方式
                var div=document.createElement("div");
                document.body.appendChild(div);
                div.innerHTML=(''
                    +'<div class="waitDialog" style="z-index:99999;width:100%;height:100%;top:0;left:0;position:fixed;background:rgba(0,0,0,0.3);">'
                    +'<div style="display:flex;height:100%;align-items:center;">'
                    +'<div style="flex:1;"></div>'
                    +'<div style="width:240px;background:#fff;padding:15px 20px;border-radius: 10px;">'
                    +'<div style="padding-bottom:10px;">录音功能需要麦克风权限，请允许；如果未看到任何请求，请点击忽略~</div>'
                    +'<div style="text-align:center;"><a onclick="waitDialogClick()" style="color:#0B1">忽略</a></div>'
                    +'</div>'
                    +'<div style="flex:1;"></div>'
                    +'</div>'
                    +'</div>');
            },
            dialogCancel(){
                clearTimeout(this.dialogInt);

                //关闭弹框，应该使用自己的弹框方式
                var elems=document.querySelectorAll(".waitDialog");
                for(var i=0;i<elems.length;i++){
                    elems[i].parentNode.removeChild(elems[i]);
                }
            },
            reclog(e){
                console.log(e)
            }
        },
        mounted() {
            this.getOssInfo(this.dir).then(res => {
                console.log(res)
                this.uploadUrl = res.data.host
                this.policy = res.data.policy
                this.accessid = res.data.accessid
                this.signature = res.data.signature
                this.callback = res.data.callback
            })
        }
    }
</script>

<style>

</style>