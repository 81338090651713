import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	addQuestionBank({ commit }, data ){
		return new Api().add(config.addQuestionBank, data)
	},
    getQuestionBankList({ commit }, data){
        return new Api().getList(config.getQuestionBank,data)
    },
    getQuestionBankDetail({ commit }, data){
        return new Api().detail(config.getQuestionBankDetail,data)
    },
    delQuestionBank({ commit }, id){
        return new Api().del(config.delQuestionBank,id)
    },
	// 导出文件
    exportQuestionBank({ commit }, data){
            axios.post(config.baseUrl+config.exportQuestionBank,{id: data.id},{responseType: "blob"}).then(response => {
            var relType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileName = data.name + '-' + tools.getFormatDate()+'.xlsx'
            const blob = new Blob([response], { type: relType })
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fileName)
            } else {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = fileName
                link.click()
                URL.revokeObjectURL(link.href)
            }
        });
    }
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
