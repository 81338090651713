<template>
    <div class="">
        <div class="ScreenShot">
            <div  class="pasteInputDiv" @paste="handlePaste">
                <div class="pasteInput">
                    <span style="font-size: 12px">粘贴图片到此处,或 </span>
                    <span><el-link @click="selectImage" type="primary" :underline="false"> 点击上传</el-link></span>
                </div>
            </div>
            <input v-show="false" type="file" ref="inputFile" class="inputFile" @change="changeFile"/>
            <div class="image-list">
                <div  v-for="(item,index) in uploadList" :key="index" class="margin-t-sm margin-r-sm">
                    <div class="pasteImgDiv" >
                        <i class="el-icon-error close-position" @click="deleteImg(index)" />
                        <el-image
                                class="pasteImg"
                                :src="item.img"
                                :preview-src-list="[item.img]"
                                :z-index="99999"
                        >
                        </el-image>
                        <div class="img-progress">
                            <el-progress  :percentage="item.progress" :width="80" :stroke-width="16" :text-inside="true" :status="item.status"></el-progress>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import {mapActions} from "vuex";
    import axios from "axios";

    export default {
        name: 'pasteImage',
        props: {
            uploadPath:{
                default:'test',
                type:String
            },
            picArray:{
                default(){
                    return[]
                },
                type:Array
            }
        },
        data() {
            return {
                imageUrl:config.imageUrl,
                show: false,
                srcList: [],
                file: null,
                url:'',
                disabled:false,
                uploadData:{},
                uploadUrl:'',
                uploadList:[],//上传图片列表
                uploadPaths:[],//上传图片的路径列表
            }
        },
        watch:{
            picArray(picArray){
                picArray.forEach(item => {
                    let img = {
                        img:this.imageUrl + item,
                        status : 'success',
                        progress : 100
                    }
                    this.uploadPaths.push(item)
                    this.uploadList.push(img)
                })
            }
        },
        methods: {
            ...mapActions('common',['getOssInfo']),
            async handlePaste(event) {
                const items = (event.clipboardData || window.clipboardData).items;
                let file = null;

                if (!items || items.length === 0) {
                    this.$message.error("当前浏览器不支持本地");
                    return;
                }
                // 搜索剪切板items
                for (let i = 0; i < items.length; i++) {
                    if (items[i].type.indexOf("image") !== -1) {
                        file = items[i].getAsFile();
                        break;
                    }
                }
                if (!file) {
                    this.$message.error("粘贴内容非图片");
                    return;
                }

                await this.uploadToOss(file)

            },

            deleteImg(index) {
                console.log(index)
                this.uploadList.splice(index,1)
                this.uploadPaths.splice(index,1)
                this.$emit('onDelete',this.uploadPaths)
				this.$emit('onRomove',index,this.uploadPaths)
                console.log('onDelete:'+this.uploadPaths)
            },
            selectImage(){
                // this.$refs.inputFile.$el.getElementsByClassName('inputFile')[0].click()
                console.log( this.$refs.inputFile.dispatchEvent(new MouseEvent('click')))
                console.log('选择图片')
            },
            changeFile(e){
                const file = e.target.files[0]
                console.log(file)
                this.uploadToOss(file)
            },
            //上传到oss
            async uploadToOss(file) {
                let img = {}
                img.progress = 0
                img.status = null
                this.uploadList.push(img)
                // 此时file就是我们的剪切板中的图片对象
                // 如果需要预览，可以执行下面代码
                const reader = new FileReader();
                // reader.onload = event => {
                //     this.uploadList.push({
                //         img:event.target.result
                //     })
                // };
                let fileName = file.lastModified+'_'+file.name
                reader.readAsDataURL(file);
                var formData = new FormData();

                var _this = this
                await this.getOssInfo(this.uploadPath).then(res => {
                    this.uploadUrl = res.data.host
                    formData.append('key', _this.uploadPath+'/'+fileName);
                    formData.append('policy', res.data.policy);
                    formData.append('OSSAccessKeyId', res.data.accessid);
                    formData.append('signature', res.data.signature);
                    formData.append('callback', res.data.callback);
                })
                formData.append('file', file);

                let res = await axios.post(this.uploadUrl, formData, {
                    //设置请求头
                    headers: { "Content-Type": "multipart/form-data" },
                    onUploadProgress:progressEvent  => {
                        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
                        console.log(complete)
                        img.progress = complete
                    }
                });
                img.img = this.imageUrl + res.data.path
                img.status = 'success'
                this.uploadPaths.push(res.data.path)
                this.$emit('onSuccess',this.uploadPaths)
                console.log('onSuccess:'+this.uploadPaths)
            },
        },

        mounted() {

        }
    }
</script>
<style>
    .img-progress{
        position: absolute;
        left: 0;
        top:35px;
        width: 100%;
    }

    .pasteInputDiv{
        box-sizing: border-box;
        width: 200px;
        height: 110px;

    }
    .image-list{
        display: flex;
        flex-wrap: wrap;
    }
    .pasteInput{
        background-color: #fbfdff;
        border: 1px dashed #c0ccda;
        border-radius: 6px;
        vertical-align: top;
        width: 200px;
        height: 110px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pasteInput:hover{
        cursor: pointer;
        border: 1px dashed #409EFF;
    }
    .pasteImgDiv{
        position: relative;
        width: 80px;
        height: 80px;

    }
    .pasteImg{
        position: relative;
        width: 80px;
        height: 80px;
        border: 1px solid #c0ccda ;
        border-radius: 5px;
    }
    .close-position{
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 22px;
        background: #fff;
        border-radius: 50%;
        color: red;
        font-weight: bold;
        z-index: 9999;
    }
    .close-position:hover{
        cursor: pointer;
    }
</style>
