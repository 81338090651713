import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import login from './modules/login'
import excerpt from './modules/excerpt'
import slideShow from './modules/slideShow'
import file from './modules/file'
import fileDownload from './modules/fileDownload'
import other from './modules/other'
import user from './modules/user'
import video from './modules/video'
import course from './modules/course'
import marketing from './modules/marketing'
import store from './modules/store'
import sales from './modules/sales'
import teacherCourse from './modules/teacherCourse'
import specialColumn from './modules/specialColumn'
import classManage from './modules/classManage'
import message from './modules/message'
import punch from './modules/punch'
import questionBank from './modules/questionBank'

Vue.use(Vuex)
//modules
export default new Vuex.Store({
	modules: {
		common,
		login,
		slideShow,
		excerpt,
		file,
		fileDownload,
		other,
		user,
		video,
		marketing,
		course,
		teacherCourse,
		sales,
		store,
		specialColumn,
		classManage,
		message,
		punch,
		questionBank
	},
	strict: false
})
