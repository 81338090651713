<template>
	<div>
		<div class="detail-title">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教研中心</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/teacherCourse' }"
					>课程</el-breadcrumb-item
				>
				<el-breadcrumb-item>课程详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="course-detail-title" style="">
			<div style="padding: 10px">
				<div class="fl-l" style="width: 150px">
					<img height="80px" width="150px" :src="pic" />
				</div>
				<div class="fl-l margin-l-sm">
					<p class="title">{{ detail.name }}</p>
					<p class="price margin-b-sm">
						<el-tag size="small" v-if="detail.grade !== ''">
							{{ detail.grade }}
						</el-tag>
						<el-tag size="small" v-if="detail.type !== ''" class="margin-l-sm">
							{{ detail.type }}
						</el-tag>
						<el-tag size="small" v-if="detail.theme !== ''" class="margin-l-sm">
							{{ detail.theme }}
						</el-tag>
					</p>
				</div>
				<div class="fl-r">
					<p>创建人：【{{ detail.user_name }}】</p>
				</div>
				<div class="clear"></div>
			</div>
		</div>

		<div class="margin-sm">
			<el-tabs v-model="activeName" type="card" @tab-click="change">
				<el-tab-pane label="课时管理" name="manage" path="manage"></el-tab-pane>
				<el-tab-pane label="备课资料" name="file" path="file"></el-tab-pane>
				<router-view> </router-view>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'detail',
	data() {
		return {
			activeName: 'manage',
			pic: '',
			amount: 0,
			name: '',
			imageUrl: config.imageUrl,
			id: '',
			detail: []
		}
	},
	methods: {
		...mapActions('teacherCourse', ['getTeacherCourseDetail']),
		change(tab, event) {
			let route = tab.$attrs.path
			console.log(route)
			this.$router.push(
				'/teacherCourse/detail/' + this.$route.params.id + '/' + route
			)
		},
		getDetail() {
			this.getTeacherCourseDetail(this.id).then(res => {
				this.detail = res.data
				this.pic = this.imageUrl + '/' + res.data.pic
			})
		}
	},
	mounted() {
		this.id = this.$route.params.id
		let arr = this.$route.path.split('/')
		let activeName = arr[arr.length - 1]
		this.activeName = activeName

		this.getDetail()
	}
}
</script>

<style>
.course-detail-title {
	margin: 10px;
	background: #fafafa;
	overflow: hidden;
}
.title {
	font-size: 18px;
	margin-top: 5px;
}
.price {
	margin-top: 30px;
	font-size: 16px;
}
</style>
