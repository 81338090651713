<template>
    <div>
        <div class="main-title"></div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" v-loading="statisticsLoading">
                    <el-row :gutter="24">
                        <el-col :span="5">
                            <div class="text-center text-gray">课程顾问
                                <el-tooltip class="item" effect="dark" content="课程顾问" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">{{this.$store.state.user.info.name}}</div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">今日微信添加数量
                                <el-tooltip class="item" effect="dark" content="今日微信添加数量" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">
                                <el-popover
                                        placement="bottom"
                                        width="200"
                                        trigger="click"
                                >
                                    <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                                        <div v-for="(item,index) in wxCountData" :key="index">
                                            <p>{{item.name}}</p>
                                            <p class="margin-t-sm">{{item.count}}</p>
                                        </div>
                                    </div>
                                    <el-link type="primary" slot="reference" @click="wxCountDetail('day')">{{todayWxCount}}</el-link>
                                </el-popover>
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">本周微信添加数量
                                <el-tooltip class="item" effect="dark" content="本周微信添加数量" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">
                                <el-popover
                                        placement="bottom"
                                        width="200"
                                        trigger="click"
                                >
                                    <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                                        <div v-for="(item,index) in wxCountData" :key="index">
                                            <p>{{item.name}}</p>
                                            <p class="margin-t-sm">{{item.count}}</p>
                                        </div>
                                    </div>
                                    <el-link type="primary" slot="reference" @click="wxCountDetail('week')">{{weekWxCount}}</el-link>
                                </el-popover>
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">本月微信添加数量
                                <el-tooltip class="item" effect="dark" content="本月微信添加数量" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">
                                <el-popover
                                        placement="bottom"
                                        width="200"
                                        trigger="click"
                                >
                                    <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                                        <div v-for="(item,index) in wxCountData" :key="index">
                                            <p>{{item.name}}</p>
                                            <p class="margin-t-sm">{{item.count}}</p>
                                        </div>
                                    </div>
                                    <el-link type="primary" slot="reference" @click="wxCountDetail('month')">{{monthWxCount}}</el-link>
                                </el-popover>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="text-center text-gray">微信添加总数量
                                <el-tooltip class="item" effect="dark" content="微信添加总数量" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">
                                <el-popover
                                        placement="bottom"
                                        width="200"
                                        trigger="click"
                                >
                                    <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                                        <div v-for="(item,index) in wxCountData" :key="index">
                                            <p>{{item.name}}</p>
                                            <p class="margin-t-sm">{{item.count}}</p>
                                        </div>
                                    </div>
                                    <el-link type="primary" slot="reference" @click="wxCountDetail('year')">{{totalWxCount}}</el-link>
                                </el-popover>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:25px" :gutter="24">
                        <el-col :span="5">
                            <div class="text-center text-gray">今日签单数量
                                <el-tooltip class="item" effect="dark" content="今日签单数量" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">{{todayCount}}</div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">今日业绩
                                <el-tooltip class="item" effect="dark" content="今日业绩" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">
                                <el-popover
                                        placement="bottom"
                                        width="200"
                                        trigger="click"
                                >
                                    <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                                        <div v-for="(item,index) in wxCountData" :key="index">
                                            <p>{{type[item.type]}}</p>
                                            <p class="margin-t-sm">{{item.price}}</p>
                                        </div>
                                    </div>
                                    <el-link type="primary" slot="reference" @click="statisticsDetail(1)">{{todayPrice}}</el-link>
                                </el-popover>
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">年度总签单数量
                                <el-tooltip class="item" effect="dark" content="年度总签单数量" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">{{totalCount}}</div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">本周业绩
                                <el-tooltip class="item" effect="dark" content="本周业绩" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">
                                <el-popover
                                        placement="bottom"
                                        width="200"
                                        trigger="click"
                                >
                                    <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                                        <div v-for="(item,index) in wxCountData" :key="index">
                                            <p>{{type[item.type]}}</p>
                                            <p class="margin-t-sm">{{item.price}}</p>
                                        </div>
                                    </div>
                                    <el-link type="primary" slot="reference" @click="statisticsDetail(2)">{{weekPrice}}</el-link>
                                </el-popover>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="text-center text-gray">本月业绩
                                <el-tooltip class="item" effect="dark" content="本月业绩" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">
                                <el-popover
                                        placement="bottom"
                                        width="200"
                                        trigger="click"
                                >
                                    <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                                        <div v-for="(item,index) in wxCountData" :key="index">
                                            <p>{{type[item.type]}}</p>
                                            <p class="margin-t-sm">{{item.price}}</p>
                                        </div>
                                    </div>
                                    <el-link type="primary" slot="reference" @click="statisticsDetail(3)">{{monthPrice}}</el-link>
                                </el-popover>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:25px" :gutter="24">
                        <el-col :span="5">
                            <div class="text-center text-gray">年度总业绩
                                <el-tooltip class="item" effect="dark" content="年度总业绩" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">
                                <el-popover
                                        placement="bottom"
                                        width="200"
                                        trigger="click"
                                >
                                    <div v-loading="wxCountDataLoading" class="padding-sm" style="display: flex;justify-content: space-around">
                                        <div v-for="(item,index) in wxCountData" :key="index">
                                            <p>{{type[item.type]}}</p>
                                            <p class="margin-t-sm">{{item.price}}</p>
                                        </div>
                                    </div>
                                    <el-link type="primary" slot="reference" @click="statisticsDetail(4)">{{totalPrice}}</el-link>
                                </el-popover>
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">本月退费数量
                                <el-tooltip class="item" effect="dark" content="本月退费数量" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">{{monthRefundCount}}</div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">本月退费金额
                                <el-tooltip class="item" effect="dark" content="本月退费金额" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">{{monthRefundPrice}}</div>
                        </el-col>
                        <el-col :span="5">
                            <div class="text-center text-gray">年度总退费数量
                                <el-tooltip class="item" effect="dark" content="年度总退费数量" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">{{totalRefundCount}}</div>
                        </el-col>
                        <el-col :span="4">
                            <div class="text-center text-gray">年度总退费金额
                                <el-tooltip class="item" effect="dark" content="年度总退费金额" placement="top">
                                    <i class="el-icon-warning-outline" style="cursor:pointer"></i>
                                </el-tooltip>
                            </div>
                            <div class="text-center padding-t ">{{totalRefundPrice}}</div>
                        </el-col>
                    </el-row>
                    <div style="position: absolute;right: 10px;top:10px;cursor:pointer">
                        <el-tooltip class="item" effect="dark" content="刷新统计数据" placement="top">
                            <i class="el-icon-refresh" @click="statistics"></i>
                        </el-tooltip>
                    </div>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <div style="display: flex;flex-wrap: wrap">
                    <span class="margin-t-sm ">
                    <el-input v-model="searchData.id" placeholder="ID" clearable size="small"></el-input>
                </span>
                    <span class="margin-t-sm margin-l-sm fl-l">
                        <el-select v-model="searchData.type" clearable placeholder="选择类型" size="small">
                            <el-option
                                    v-for="item in typeArray"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </span>

                    <span class="margin-t-sm margin-l-sm fl-l">
                    <el-select v-model="searchData.status" clearable placeholder="选择状态" size="small">
                        <el-option
                                v-for="item in statusArray"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l">
                    <el-select v-model="searchData.hasCourse" clearable placeholder="是否需要开通课程" size="small">
                        <el-option
                                v-for="item in hasCourseArray"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l">
                    <el-select v-model="searchData.grade" clearable placeholder="选择年级" size="small">
                        <el-option
                                v-for="item in grades"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l">
                    <el-select v-model="searchData.check" clearable placeholder="选择订单状态" size="small">
                        <el-option
                                v-for="item in checkArray"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l">
                    <el-select v-model="searchData.isAddress" clearable placeholder="是否邮寄" size="small">
                        <el-option
                                label="需要邮寄"
                                value="1">
                        </el-option>
                    </el-select>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l ">
                    <el-input v-model="searchData.nickname" placeholder="用户昵称" clearable size="small"></el-input>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l ">
                    <el-input v-model="searchData.area" placeholder="地区" clearable size="small"></el-input>
                </span>


                    <span class="margin-t-sm margin-l-sm fl-l">
                    <el-date-picker
                            size="small"
                            v-model="date2"
                            type="daterange"
                            align="right"
                            value-format="yyyy-MM-dd"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="创建日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l " v-if="this.$store.state.user.info.role === 1">
                    <el-input v-model="searchData.userName" placeholder="业绩归属" clearable size="small"></el-input>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l " >
                    <el-input v-model="searchData.phone" placeholder="手机号" clearable size="small"></el-input>
                </span>

                    <span class="margin-t-sm margin-l-sm fl-l">
                     <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                     <el-button class="margin-l-sm"
                                icon="el-icon-download" size="small" @click="exportBtn">导出</el-button>
                </span>

                    <span class="margin-t-sm margin-l-sm " style="flex: 1;">

                        <span class="fl-r">
<!--                            <el-popover-->
                            <!--                                    placement="left"-->
                            <!--                                    width="200"-->
                            <!--                                    v-model="visible">-->
                            <!--                      <p class="padding-l-sm">今天添加微信数量</p>-->
                            <!--                      <div class="padding-sm">-->
                            <!--                          <el-input v-model="todayWxCount"></el-input>-->
                            <!--                        <div class="padding-t-sm">-->
                            <!--                            <el-button size="mini" type="text" @click="visible = false">取消</el-button>-->
                            <!--                            <el-button type="primary" size="mini" @click="setWeiXinCount"-->
                            <!--                                       :loading="wxLoading">确定</el-button>-->
                            <!--                        </div>-->
                            <!--                      </div>-->
                        <el-button slot="reference" icon="el-icon-plus" size="small" @click="dialogVisible = true">录入微信数</el-button>
                            <!--                    </el-popover>-->



                    <router-link to="/sales/add" class="router-link margin-l-sm">
                        <el-button type="primary" size="small">新增</el-button>
                    </router-link>
                        </span>
                </span>

                </div>


            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        row-key="id"
                        :data="tableData"
                        highlight-current-row
                        @selection-change="selectChange"
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            fixed="left"
                            v-if="this.$store.state.user.info.role === 1"
                            type="selection"
                            :reserve-selection="true"
                    >
                    </el-table-column>
                    <el-table-column
                            label="ID"
                            property="id"
                    >
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 1">新签</span>
                            <span v-if="scope.row.type === 2">退费</span>
                            <span v-if="scope.row.type === 3">转介绍</span>
                            <span v-if="scope.row.type === 4">扩科目</span>
                            <span v-if="scope.row.type === 5">续费</span>
                            <span v-if="scope.row.type === 6">百度</span>
                            <span v-if="scope.row.type === 7">头条</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="附件图">
                        <template slot-scope="scope">
                            <preview-img :pic-array="scope.row.pic" :http="imageUrl"></preview-img>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="nickname"
                            label="用户昵称"
                    ></el-table-column>
                    <el-table-column
                            property="uid"
                            label="用户id"
                    ></el-table-column>
                    <el-table-column
                            property="index"
                            label="开通信息">
                        <template slot-scope="scope">
                            <el-popover
                                    v-if="scope.row.has_course === 1"
                                    placement="right"
                                    trigger="click"
                            >
                                <div v-loading="usercourseLoading" style="padding: 20px;max-height: 500px;overflow:auto;">
                                    <el-descriptions style="margin-bottom: 10px" v-for="(userCourse,index) in userCourses" :key="index" title="" size="mini" border :column="2">
                                        <el-descriptions-item label="用户ID" >{{userCourse.user_id}}</el-descriptions-item>
                                        <el-descriptions-item label="用户名">
                                            <div><el-avatar size="small" :src="userCourse.user_name_avatar_url"></el-avatar></div>
                                            <div>{{userCourse.user_name}}</div>
                                        </el-descriptions-item>
                                        <el-descriptions-item label="课程ID" >{{userCourse.course_id}}</el-descriptions-item>
                                        <el-descriptions-item label="课程名">
                                            <div>
                                                <el-image style="width: 100px;"
                                                          :src="imageUrl + userCourse.course_pic"
                                                          fit="contain"></el-image>
                                            </div>
                                            <div>{{userCourse.course_name}}</div>
                                        </el-descriptions-item>
                                        <el-descriptions-item label="课程类型"  :span="2">{{userCourse.course_type === 'checkCourse' ? '阅读日程' : '录播课程'}}</el-descriptions-item>

                                        <template v-if="userCourse.course_type === 'checkCourse'">
                                            <el-descriptions-item label="开始更新时间"  :span="2">{{userCourse.start_at}}</el-descriptions-item>
                                            <el-descriptions-item label="是否全部计划" :span="2">
                                                <el-tag size="small" v-if="userCourse.is_all">是</el-tag>
                                                <el-tag size="small" type="danger" v-else>否</el-tag>
                                            </el-descriptions-item>
                                            <template v-if="!userCourse.is_all">
                                                <el-descriptions-item label="开始计划天数">{{userCourse.start_plan_day}}</el-descriptions-item>
                                                <el-descriptions-item label="结束计划天数">{{userCourse.end_plan_day}}</el-descriptions-item>
                                            </template>
                                            <el-descriptions-item label="更新周期"  :span="2">{{userCourse.update_day}}</el-descriptions-item>
                                        </template>

                                    </el-descriptions>
                                </div>
                                <el-link :type=" scope.row.course_status === 0 ? 'danger' : 'primary'"  slot="reference" @click="getUserCourseDetail(scope.row.id)">查看信息</el-link>
                            </el-popover>
                            <span v-else>无需开通课程权限</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="grade"
                            label="年级"
                    ></el-table-column>
                    <el-table-column
                            property="area"
                            label="地区"
                    ></el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间"
                    >
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="业绩归属"
                    >
                        <template slot-scope="scope">
                            <span>{{scope.row.counselor}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="课程名称">
                        <template slot-scope="scope">
                            <span>{{scope.row.course}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="收款方式">
                        <template slot-scope="scope">
                            <span>{{payType(scope.row.pay_type)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="收款金额">
                        <template slot-scope="scope">
                            <span>￥{{scope.row.price}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="邮箱">
                        <template slot-scope="scope">
                            <span>{{scope.row.email}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="收件人">
                        <template slot-scope="scope">
                            <span>{{scope.row.user_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="手机号">
                        <template slot-scope="scope">
                            <span>{{scope.row.phone}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="邮寄地址">
                        <template slot-scope="scope">
                            <span>{{scope.row.address}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="备注">
                        <template slot-scope="scope">
                            <span>{{scope.row.remark}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            width="120"
                            label="支付订单信息">
                        <template slot-scope="scope">
                            <div>订单号：{{scope.row.sn === '' ? '无' : scope.row.sn}}</div>
                            <div>支付单号：{{scope.row.pay_sn === '' ? '无' : scope.row.pay_sn}}</div>
                            <div>实付金额：{{scope.row.amount  === '' ? '无' : scope.row.amount}}</div>
                            <div>支付时间：{{scope.row.pay_at  === '' ? '无' : scope.row.pay_at}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status == 1" class="state-up"><i></i>已处理</div>
                            <div v-if="scope.row.status == 0" class="state-down"><i></i>待处理</div>
                            <template v-if="scope.row.has_course==1">
                                <div v-if="scope.row.course_status == 1" class="state-up"><i></i>已开通</div>
                                <div v-if="scope.row.course_status == 0" class="state-down"><i></i>待开通</div>
                            </template>

                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="订单状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.check == 1" class="state-up"><i></i>已核实</span>
                            <span v-if="scope.row.check == 0" class="state-down"><i></i>未核实</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            fixed="right"
                            label="操作">
                        <template slot-scope="scope">

                            <span>
                                <el-link slot="reference" type="primary" @click="eidt(scope.row.id)">编辑</el-link>
                            </span>
                            -
                            <span v-if="$store.state.user.info.role === 1">
                                <el-link slot="reference" type="primary" @click="closeRoleDialog = true;selectSales=scope.row">改归属</el-link>
                            </span>
                            -
                            <span v-if="$store.state.user.info.role === 1 && scope.row.status === 0">
                                <el-link slot="reference" type="primary" @click="handleOne(scope.row)">处理</el-link>
                            </span>
                            -
                            <span v-if="$store.state.user.info.role === 1 && scope.row.check === 0">
                                <el-link slot="reference" type="primary" @click="checkOne(scope.row)">核实</el-link>
                            </span>
                            <span v-if="scope.row.check === 0">
                                -
                            <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row)"
                                >
                                  <el-link slot="reference" type="primary" >删除</el-link>
                                </el-popconfirm>
                            </span>
                            </span>

                            <span v-if="$store.state.user.info.role === 1 && scope.row.course_status === 0 && scope.row.has_course==1">
                                <el-link slot="reference" type="primary" @click="openCourse(scope.row.id)">开通课程</el-link>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch">
                <div class="fl-l" v-if="this.$store.state.user.info.role === 1">
                    <el-button size="mini" @click="handle" :loading="handleLoading">一键处理</el-button>
                    <el-button size="mini" type="primary" @click="check" :loading="checkLoading">一键核实</el-button>
                </div>
                <span class="fl-r">
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

        <el-dialog
                title="录入微信"
                append-to-body
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                width="510px">
            <div style="width: 50%">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item class="margin-b-sm" :label="'来源 ' +(index+1)" v-for="(item,index) in form.list" :key="index">
                        <div style="display: flex">
                              <span>
                                  <el-input style="width: 150px" placeholder="名字：例 小小" clearable v-model="item.name"></el-input>
                              </span>
                            <span class="margin-l-sm">
                                  <el-input @input="inputCount" style="width: 150px" clearable placeholder="数量：例 999" v-model.number="item.count"></el-input>
                              </span>
                            <el-link @click="delList(index)" :underline="false" class="margin-l-sm el-icon-remove-outline" style="line-height: 40px" ></el-link>
                            <el-link @click="addList" :underline="false" class="margin-l-sm el-icon-circle-plus-outline" style="line-height: 40px" ></el-link>
                        </div>
                    </el-form-item>
                    <el-form-item class="margin-b-sm" label="微信总数">
                        <el-input  style="width: 310px" disabled v-model="form.count"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                    <el-button size="mini" type="primary" @click="setWeiXinCount" :loading="wxLoading">确 定</el-button>
              </span>
        </el-dialog>

        <el-dialog
                append-to-body
                title="转归属"
                :visible.sync="closeRoleDialog"
                width="30%">
            <div >
                <el-select v-model="salesUserId" placeholder="请选择归属人" size="mini" clearable>
                    <el-option :label="item.id + ':' + item.name" :value="item.id" v-for="item in salesUserList" :key="item.id"></el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="closeRoleDialog = false" size="mini">取 消</el-button>
            <el-button type="primary"  size="mini" @click="changeSalesUser()">确 定</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import PreviewImg from "../../../components/previewImg";

    export default {
        name: "template-list",
        components: {PreviewImg},
        data() {
            return {type:{
                    1:'新签',
                    2:'退费',
                    3:'转介绍',
                    4:'扩科目',
                    5:'续费',
                    6:'百度',
                    7:'头条',
                },
                form:{
                    list:[
                        {
                            name:'',
                            count:''
                        }
                    ],
                    count:0
                },
                salesUserList:[],
                salesUserId:'',
                selectSales:null,
                closeRoleDialog:false,
                userCourses:[],
                usercourseLoading:false,
                dialogVisible:false,
                searchData: {},
                date2: '',
                statisticsLoading: false,
                visible: false,
                wxLoading: false,
                page: 1,
                pageSize: 10,
                total: 0,
                loading: true,
                imageUrl: config.imageUrl,
                todayWxCount: 0,//今日微信数量
                weekWxCount: 0,//周微信数量
                monthWxCount: 0,//月微信数量
                totalWxCount: 0,//总微信数量
                todayCount: 0,//今日新签数量
                todayPrice: 0,//今日新签价格
                totalCount: 0,//总新签数
                totalPrice: 0,//总新签价格
                weekPrice: 0,//周新签业绩
                monthPrice: 0,//月新签业绩
                monthRefundCount: 0,//月退款数
                monthRefundPrice: 0,//月退款金额
                totalRefundCount: 0,//总退款数
                totalRefundPrice: 0,//总退款数
                typeArray: [
                    {
                        value: 1,
                        label: '新签'
                    },
                    {
                        value: 2,
                        label: '退费'
                    },
                    {
                        value: 3,
                        label: '转介绍'
                    },
                    {
                        value: 4,
                        label: '扩科目'
                    },
                    {
                        value: 5,
                        label: '续费'
                    },
                    {
                        value: 6,
                        label: '百度'
                    },
                    {
                        value: 7,
                        label: '头条'
                    },
                ],
                statusArray: [{
                    value: 0,
                    label: '待处理'
                }, {
                    value: 1,
                    label: '已处理'
                }],
                hasCourseArray: [{
                    value: 0,
                    label: '不需要开通'
                }, {
                    value: 1,
                    label: '需要开通'
                }],
                checkArray: [{
                    value: 0,
                    label: '未核实'
                }, {
                    value: 1,
                    label: '已核实'
                }],
                grades: [
                    {
                        value: '一年级',
                        label: '一年级'
                    },
                    {
                        value: '二年级',
                        label: '二年级'
                    },
                    {
                        value: '三年级',
                        label: '三年级'
                    },
                    {
                        value: '四年级',
                        label: '四年级'
                    },
                    {
                        value: '五年级',
                        label: '五年级'
                    },
                    {
                        value: '六年级',
                        label: '六年级'
                    },
                    {
                        value: '七年级',
                        label: '七年级'
                    },
                    {
                        value: '八年级',
                        label: '八年级'
                    },
                    {
                        value: '九年级',
                        label: '九年级'
                    },
                ],
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [],
                currentRow: null,
                selectTableId: [],
                handleLoading: false,
                checkLoading: false,
                wxCountData:[],
                wxCountDataLoading:false
            }
        },


        methods: {
            ...mapActions('sales', ['getSalesList', 'addSalesWx', 'getStatistics', 'handleSales', 'checkSales', 'exportSales','getWxCountDetail','getSalesUserCourseDetail','openSalesUserCourse','getStatisticsDetail','salesChangeUser']),
            ...mapActions('common', ['delete']),
            ...mapActions('user', ['getUserList']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            changeSalesUser(){
                console.log(this.salesUserId)
                console.log(this.selectSales)
                this.salesChangeUser({userId:this.salesUserId,id:this.selectSales.id}).then(res => {
                    console.log(res)
                    this.$message.success('修改归属成功')
                    this.closeRoleDialog = false
                    this.getList()
                })
            },
            inputCount(e){
                let count = 0;
                this.form.list.forEach(item=>{
                    count += Number(item.count)
                })
                this.form.count = count
                console.log(count)
            },
            delList(index){
                if(this.form.list.length <= 1)
                {
                    this.$message.info('至少填写一个')
                    return ;
                }
                this.form.list.splice(index,1)
                let count = 0;
                this.form.list.forEach(item=>{
                    count += Number(item.count)
                })
                this.form.count = count
                console.log(index)
            },
            wxCountDetail(type){
                this.wxCountDataLoading = true
                let data = [];
                this.getWxCountDetail({type:type}).then(res => {
                    console.log(res.data)
                    for (var item in res.data){
                        let count = 0
                        res.data[item].forEach(i => {
                            count += i.count
                        })
                        data.push({
                            name:item,
                            count:count
                        })
                    }
                    this.wxCountData = data
                }).finally(()=>{
                    this.wxCountDataLoading = false
                })
            },
            statisticsDetail(type){
                this.wxCountDataLoading = true
                this.getStatisticsDetail({dateType:type,userId:this.$cookies.get('userId')}).then(res => {
                    this.wxCountData = res.data
                }).finally(()=>{
                    this.wxCountDataLoading = false
                })
            },
            addList(){
                this.form.list.push({
                    name:'',
                    count:''
                })
                console.log(11)
            },
            eidt(id) {
                this.$router.push('/sales/edit/' + id)
            },
            async handle() {
                if (this.selectTableId.length === 0) {
                    this.$message.error('请勾选表格数据')
                    return
                }
                this.handleLoading = true
                let res = await this.handleSales({ids: this.selectTableId})

                if (res !== undefined && res.ret === 0) {
                    this.$message.success('处理成功')
                    this.getList()
                    this.$refs.singleTable.clearSelection();
                }

                this.handleLoading = false
            },
            async handleOne(row){
                let res = await this.handleSales({ids: [row.id]})

                if (res !== undefined && res.ret === 0) {
                    this.$message.success('处理成功')
                    this.getList()
                }
            },
            async checkOne(row){
                let res = await this.checkSales({ids: [row.id]})
                if (res !== undefined && res.ret === 0) {
                    this.$message.success('处理成功')
                    this.getList()
                }
            },
            deleted(item) {
                this.delete({model: 'Sales', id: item.id}).then(res => {
                    if (res.ret === 0) {
                        this.$message.success('删除成功！')
                        this.getList()
                    }
                })
            },
            async check() {
                if (this.selectTableId.length === 0) {
                    this.$message.error('请勾选表格数据')
                    return
                }
                this.checkLoading = true
                let res = await this.checkSales({ids: this.selectTableId})
                if (res !== undefined && res.ret === 0) {
                    this.$message.success('处理成功')
                    this.getList()
                    this.$refs.singleTable.clearSelection();
                }
                this.checkLoading = false
            },
            payType(payType) {
                switch (payType) {
                    case 1 :
                        return '小程序支付'
                    case 2 :
                        return '微信对公账户'
                    case 3 :
                        return '支付宝支付'
                    case 4 :
                        return '短书购买'
                    default :
                        return '未知方式'
                }
            },
            selectChange(val) {
                this.selectTableId = []
                if (val.length > 0) {
                    val.forEach(item => {
                        this.selectTableId.push(item.id)
                    })

                }
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem() {
                return 'row-item';
            },
            search() {
                if (this.date2 != null && this.date2 != '') {
                    this.searchData.startTime = this.date2[0]
                    this.searchData.endTime = this.date2[1]
                } else {
                    this.searchData.startTime = ''
                    this.searchData.endTime = ''
                }
                this.tableData = []
                this.page = 1
                this.getList()
            },
            async exportBtn() {
                if (this.date2 != null && this.date2 != '') {
                    this.searchData.startTime = this.date2[0]
                    this.searchData.endTime = this.date2[1]
                } else {
                    this.searchData.startTime = ''
                    this.searchData.endTime = ''
                }
                this.searchData.ids = this.selectTableId
                console.log(this.searchData)
                let res = await this.exportSales(this.searchData)
                if (res.ret === 0) {
                    window.location.href = config.serviceUrl + '/download?fileName=' + res.data.fileName
                    this.$refs.singleTable.clearSelection();
                }
            },
            async setWeiXinCount() {
                if(this.form.list.length <= 0)
                {
                    this.$message.error('至少填写一项来源')
                    return
                }
                this.wxLoading = true
                await this.addSalesWx({list:this.form.list})
                this.wxLoading = false
                this.$message.success('录入成功')
                this.dialogVisible = false

            },
            async getUserCourseDetail(id){
                this.userCourses = []
                this.usercourseLoading = true
                let res = await this.getSalesUserCourseDetail({id:id})
                this.userCourses = res.data
                this.usercourseLoading = false
                console.log(res)
            },
            async getList() {
                this.loading = true
                this.tableData = []
                this.searchData.page = this.page
                this.searchData.pageSize = this.pageSize
                let data = await this.getSalesList(this.searchData)
                this.total = data.data.total
                this.tableData = data.data.list

                this.loading = false

            },
            setPageSize(pageSize) {
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page) {
                this.page = page
                this.getList()
            },
            async statistics() {
                this.statisticsLoading = true
                let res = await this.getStatistics()
                if (res.ret == 0) {
                    this.todayWxCount = res.data.todayWxCount//今日微信数量
                    this.weekWxCount = res.data.weekWxCount//周微信数量
                    this.monthWxCount = res.data.monthWxCount//月微信数量
                    this.totalWxCount = res.data.totalWxCount//总微信数量
                    this.todayCount = res.data.todayCount//今日新签数量
                    this.todayPrice = res.data.todayPrice//今日新签价格
                    this.totalCount = res.data.totalCount//总新签数
                    this.totalPrice = res.data.totalPrice//总新签价格
                    this.weekPrice = res.data.weekPrice//周新签业绩
                    this.monthPrice = res.data.monthPrice//月新签业绩
                    this.monthRefundCount = res.data.monthRefundCount//月退款数
                    this.monthRefundPrice = res.data.monthRefundPrice//月退款金额
                    this.totalRefundCount = res.data.totalRefundCount//总退款数
                    this.totalRefundPrice = res.data.totalRefundPrice//总退款数
                }
                this.statisticsLoading = false
            },
            async openCourse(id){
                let res = await this.openSalesUserCourse({id:id})
                if(res.ret === 0)
                {
                    this.$message.success('开通成功')
                    this.getList()
                }else{
                    this.$message.error('开通失败')
                }
                console.log(res)
            },
            getSalesUserList(){
                this.getUserList({role:5,status:1,pageSize:1000}).then(res => {
                    this.salesUserList = res.data.list
                })
            }
        },

        async mounted() {
            this.getList()
            this.statistics()
            this.getSalesUserList()
        }
    }
</script>

<style>

</style>
