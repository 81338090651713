<template>
    <div>
        <div v-if="data !== ''" style="font-size: 12px">文件已上传：{{data}}</div>

        <p style="font-size: 12px; color: #999; margin-bottom: 7px;"><slot name="prompt">支持MP4，文件小于1G</slot></p>

        <el-upload
                ref="fileList"
                :action="uploadUrl"
                :class="hideUploadClass"
                list-type="text"
                :limit="uploadNumber"
                :on-change="onChange"
                :accept="accept"
                :data="uploadData"
                :on-success="onSuccess"
                :on-remove="onRemove"
                :on-exceed="onExceed"
                :file-list="fileList"
                :before-upload="beforeUpload"
                :auto-upload="true">
            <el-button slot="default" size="mini"  v-if="data === ''">选择文件</el-button>
            <el-button slot="default" size="mini"  v-if="data !== ''">重新上传</el-button>

        </el-upload>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    export default {
        components: {
        },
        props:{
            uploadPath:{
                default:'teacherCourse',
                type:String
            },
            uploadNumber:{
                default: 1,
                type:Number
            },
            accept:{
                default:'',
                type:String
            },
            data:{
                default:'',
                type:String
            }
        },
        name: "mediaUpload",
        data() {
            return {
                uploadUrl:'',
                fileList:[],
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                hideUploadClass:'',
                uploadData:{},
                fileName:'',
                path:''
            };
        },
        methods: {
            ...mapActions('common',['getOssInfo']),
            handleRemove() {
                // this.$refs.refName.handleRemove(file)
                this.fileList = []
                this.hideUploadClass = ''
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            onChange(file,fileList){
                let fileName = file.name

                if(fileList.length >= this.uploadNumber){
                    this.hideUploadClass = 'hide'
                }else{
                    this.hideUploadClass = ''
                }
                this.fileName = fileName

            },
            onRemove(file,fileList){
                console.log(file)
                console.log(fileList)
            },
            onExceed(file){
                this.$message.error('最多只能选取'+this.uploadNumber+'张图片')
            },
            async beforeUpload(){
                let _this = this
                await this.getOssInfo(this.uploadPath).then(res => {
                    this.uploadUrl = res.data.host
                    _this.path = res.data.dir+'/'+_this.fileName
                    let data = {
                        key:_this.path,
                        policy:res.data.policy,
                        OSSAccessKeyId: res.data.accessid,
                        signature:res.data.signature,
                        callback:res.data.callback,
                    }
                    _this.uploadData = data
                })
            },
            onSuccess(res)
            {
                this.$notify({
                    title: '上传成功',
                    type: 'success',
                    message: '文件上传成功',
                });
                console.log('上传成功')
                let data = {
                    mimeType: res.data.mimeType,
                    path: res.data.path,
                    size: res.data.size,
                }
                this.$emit('success',data)
            },
        },
        mounted() {
            this.$nextTick(function() {
                this.$on('reset', function() {
                    this.uploadUrl = ''
                    this.fileList = []
                    this.dialogImageUrl = ''
                    this.dialogVisible = false
                    this.disabled = false
                    this.hideUploadClass =''
                    this.uploadData = {}
                    this.fileName = ''
                    this.path = ''
                });
            });
        }
    }
</script>

<style>

</style>
