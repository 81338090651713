<template>
    <div  v-loading="loading">
        <div>
            <span class="fl-l">
                <span class="fl-l"><el-input v-model="searchName" placeholder="请输入内容" clearable size="课时名称"></el-input></span>
                <span class="fl-l margin-l-sm"><el-button  size="mini" plain icon="el-icon-search" @click="search">筛选</el-button></span>
            </span>
            <span class="fl-r">
                <el-button  size="mini" plain icon="el-icon-plus" @click="toAddClass">新建课时</el-button>
                <el-popover
                        class="margin-l-sm"
                        placement="left"
                        width="160"
                        v-model="visible">
                    <div class="padding-sm">
                        <p class="font-size-12">章节名称</p>
                        <p class="padding-t-sm "><el-input v-model="chapter" placeholder="请输入章节名称"></el-input></p>
                    </div>

                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="createChapter" :loading="createChapterLoading">确定</el-button>
                    </div>
                  <el-button slot="reference" type="primary" size="mini" icon="el-icon-plus">新建章节</el-button>
                </el-popover>
            </span>
            <p class="clear"></p>
        </div>

        <div class="table-content">

           <div>
               <div class=" table-header">
                   <div class="fl-l w26">目录</div>
                   <div class="fl-l w26">概要</div>
                   <div class="fl-l w10">状态</div>
                   <div class="fl-l w10">排序</div>
                   <div class="fl-l w26" style="text-align: right">操作</div>
                   <div class="clear"></div>
               </div>
           </div>

            <div class="padding-sm">
                <div v-for="item  in list" :key="item.id">
                    <div class="section border-s padding-sm">
                        <div class="fl-l w26">{{item.name}} </div>
                        <div class="fl-l w26">共{{item.count}}节课</div>
                        <div class="fl-l w10">
                            <span v-if="item.status == 1" class="state-up"><i></i>已上架</span>
                            <span v-if="item.status == 0" class="state-down"><i></i>已下架</span>
                        </div>
                        <div class="fl-l w10">
                            <el-input-number size="mini" v-model="item.sort" @change="changeSort(item,CourseChapterModel)"></el-input-number>
                        </div>
                        <div class="fl-l w26" style="text-align: right;font-weight: normal">

<!--                            <el-popover-->
<!--                                    class="margin-l-sm"-->
<!--                                    placement="left"-->
<!--                                    width="330"-->
<!--                                    v-model="item.show">-->
<!--                                <div class="padding-sm">-->
<!--                                    <p class="fl-l margin-r-sm" style="height: 30px;line-height: 30px">移动到第</p>-->
<!--                                    <p class="fl-l"><el-input-number :min="0" size="mini" v-model="item.sort" controls-position="right" ></el-input-number></p>-->
<!--                                    <div class="fl-l margin-l-sm">-->
<!--                                        <el-button type="primary" size="mini" @click="changeSort(item,CourseChapterModel)" :loading="item.loading">确定</el-button>-->
<!--                                        <el-button size="mini" type="text" @click="item.show = false">取消</el-button>-->
<!--                                    </div>-->
<!--                                    <div class="clear"></div>-->
<!--                                </div>-->
<!--                                <el-link slot="reference"  type="primary" >排序</el-link>-->
<!--                            </el-popover>
                            --->
                            <el-link slot="reference"  type="primary" @click="showEditChapter(item)">编辑</el-link> -
                            <el-link v-if="item.status == 1" type="primary" @click="setStatus(item,CourseChapterModel)">下架</el-link>
                            <el-link v-if="item.status == 0" type="primary" @click="setStatus(item,CourseChapterModel)">上架</el-link>
                            -
                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(item,'CourseChapter')"
                            >
                                <el-link slot="reference" type="primary">删除</el-link>
                            </el-popconfirm>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="margin-sm border-s padding-sm font-size-12" v-for="clazz in item.class" :key="clazz.id">
                        <div class="fl-l w26">
                            <i class="circle" :class="clazz.type === 2 ? 'el-icon-picture' : 'el-icon-video-camera-solid' " ></i> {{clazz.name}}
                            <span v-if="clazz.free === 1 ">
                                <el-tag type="success" size="mini">试学</el-tag>
                            </span>

                        </div>
                        <div class="fl-l w26">
                            <p v-if="clazz.type === 2"> 图文</p>
                            <p v-if="clazz.type === 1"> 视频大小:【{{parseFloat((clazz.size/1024)/1024).toFixed(2) + 'M'}}】 视频时长：【{{s_to_hs(clazz.duration)}}】</p>
                        </div>
                        <div class="fl-l w10">
                            <span v-if="clazz.status == 1" class="state-up"><i></i>已上架</span>
                            <span v-if="clazz.status == 0" class="state-down"><i></i>已下架</span>
                        </div>
                        <div class="fl-l w10">
                            <el-input-number size="mini" v-model="clazz.sort" @change="changeSort(clazz,CourseClassModel)"></el-input-number>
                        </div>
                        <div class="fl-l w26" style="text-align: right">
<!--                            <el-popover-->
<!--                                    class="margin-l-sm"-->
<!--                                    placement="left"-->
<!--                                    width="320"-->
<!--                                    v-model="clazz.show">-->
<!--                                <div class="padding-sm">-->
<!--                                    <p class="fl-l margin-r-sm" style="height: 30px;line-height: 30px">移动到第</p>-->
<!--                                    <p class="fl-l"><el-input-number :min="0" size="mini" v-model="clazz.sort" controls-position="right" ></el-input-number></p>-->
<!--                                    <div class="fl-l margin-l-sm">-->
<!--                                        <el-button type="primary" size="mini" @click="changeSort(clazz,CourseClassModel)" :loading="clazz.loading">确定</el-button>-->
<!--                                        <el-button size="mini" type="text" @click="clazz.show = false">取消</el-button>-->
<!--                                    </div>-->
<!--                                    <div class="clear"></div>-->
<!--                                </div>-->
<!--                                <el-link slot="reference"  type="primary" >排序</el-link>-->
<!--                            </el-popover>
                            --->
                            <el-link type="primary" @click="editClass(clazz)">编辑</el-link>
                            -
                            <el-link v-if="clazz.status == 1" type="primary" @click="setStatus(clazz,CourseClassModel)">下架</el-link>
                            <el-link v-if="clazz.status == 0" type="primary" @click="setStatus(clazz,CourseClassModel)">上架</el-link>
                            -

                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(clazz,'CourseClass')"
                            >
                                <el-link slot="reference" type="primary">删除</el-link>
                            </el-popconfirm>
                        </div>
                        <div class="clear"></div>
                    </div>

                </div>
            </div>
        </div>

        <el-dialog
                :append-to-body="true"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                title="编辑章节"
                :visible.sync="editChapterVisible"
                width="30%"
               >
            <div><el-input v-model="chapterName" placeholder="请输入章节名称"></el-input></div>
            <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hideEditChapter">取 消</el-button>
            <el-button size="mini" type="primary" @click="editChapter" :loading="editChapterLoading">确 定</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../../../utils/tools";

    export default {
        name: "manage",
        data(){
            return{
                editChapterVisible:false,
                searchName:'',
                tableData:[],
                chapterName:'',
                selectChapterId:0,
                editChapterLoading:false,
                input:'',
                loading:false,
                visible:false,
                visible2:false,
                chapter:'',
                createChapterLoading:false,
                courseId:0,
                list:[],
                CourseChapterModel:'CourseChapterModel',
                CourseClassModel:'CourseClassModel',
            };
        },
        methods:{
            ...mapActions('course',['addChapter','getChapter','setSort','setTheStatus']),
            ...mapActions('common',['delete']),
            search(){
                this.list = []
                this.getList()
            },
            showEditChapter(item){
                this.selectChapterId = item.id
                this.editChapterVisible = true
            },
            hideEditChapter(){
                this.editChapterVisible = false
            },
            editClass(item){
                this.$router.push('/course/editClass/courseId/'+this.courseId+'/classId/'+item.id)
            },
            editChapter(){
                this.editChapterLoading = true
                let data = {
                    id:this.selectChapterId,
                    courseId:this.courseId,
                    name:this.chapterName,
                }

                this.addChapter(data).then(res => {

                    if(res.ret === 0)
                    {
                        this.visible = false
                        this.$message.success('修改成功')
                        this.chapter = ''
                        this.getList();
                    }
                    this.hideEditChapter()
                    this.editChapterLoading = false
                })

            },
            createChapter(){
                this.createChapterLoading = true

                let data = {
                    courseId:this.courseId,
                    name:this.chapter
                }

                this.addChapter(data).then(res => {

                    if(res.ret === 0)
                    {
                        this.visible = false
                        this.$message.success('章节创建成功')
                        this.chapter = ''
                        this.getList();
                    }
                    this.createChapterLoading = false
                })

            },
            changeSort(item,model){
                item.loading = true

                let data = {
                  id:item.id,
                  sort:item.sort,
                  model:model
                }
                this.setSort(data).then(res => {
                    if(res.ret === 0){

                        item.show = false
                        this.$message.success('排序成功！')
                        this.getList()
                    }
                })
            },
            setStatus(item,model){
                let data = {
                    model:model,
                    id:item.id,
                    status:!item.status
                }


                this.setTheStatus(data).then(res => {
                    if(res.ret === 0)
                    {
                        this.$message.success('设置成功！')
                        this.getList()
                    }
                })
            },
            deleted(item,model){
                console.log(item)
                this.delete({model:model,id:item.id}).then(res => {
                    if(res.ret === 0)
                    {
                        this.$message.success('删除成功！')
                        this.getList()
                    }
                })
            },
            toAddClass(){
                this.$router.push('/course/addClass/'+this.courseId)
            },
            s_to_hs(s){
                return tools.sToTime(s)
            },
            getList(){
                this.loading = true
                console.log(this.loading)
                let data = {
                    courseId:this.courseId,
                    name:this.searchName,
                }

                this.getChapter(data).then(res => {
                    if(res.ret === 0){
                        this.list = res.data
                    }
                }).finally(()=> {
                    this.loading = false
                })
            }
        },
        mounted() {
            this.courseId = this.$route.params.id
            this.getList()
        }
    }
</script>

<style >
    .table-header{
        padding:15px;
        background: #fafafa;
    }
    .font-size-12{
        font-size: 12px;
    }
    .circle{
        border:1px solid #ccc;
        padding:3px;
        border-radius: 50%;
        margin-right: 3px;
        font-size: 8px;
    }
    .section{
        font-size: 14px;
        padding: 20px;
        font-weight: bold;
    }
    .border-s{
        border-bottom:1px solid #ebebeb
    }
.w10{
    width: 10%;
}
.w26{
    width: 26.3333%;
}
</style>