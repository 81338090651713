<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >基础设置</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/slide-show' }">播图设置</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" label-width="120px" size="mini" >

                <el-form-item label="图片：" label-position="left" >
                    <cover @success="uploadSuccess"></cover>
                </el-form-item>

                <el-form-item label="跳转类型：" label-position="left" class="input" >
                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="资源id" label-position="left" class="input">
                    <el-input style="width: 200px"  v-model="form.resource"></el-input>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/slide-show" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import cover from '../../../components/cover'
    import {mapActions} from "vuex";
    export default {
        name: "add",
        components:{cover},
        data() {
            return {
                statusTxt:'上架',
                loading:false,
                form: {
                    type: 0,
                    status:true,
                    pic:'',
                    resource:0
                },
                options: [
                    {
                        value: 0,
                        label: '仅展示图片'
                    },
                    {
                        value: 1,
                        label: '每日摘抄'
                    },
                    {
                        value: 2,
                        label: '资料下载'
                    },
                    {
                        value: 3,
                        label: '外部链接'
                    }
                ],

            }
        },
        methods: {
            ...mapActions('slideShow',['addSlideShow']),
            uploadSuccess(path){

              this.form.pic = path
                console.log(this.form.pic)
            },
            async onSubmit() {
                let _this = this
                this.loading = true
                console.log(this.form)
                await this.addSlideShow(this.form).then( res => {
                    if(res.ret == 0){
                        _this.$message.success('新增成功！')
                        _this.$router.push('/slideShow')
                    }
                })
                this.loading = false
            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
            }
        }
    }
</script>

<style scoped>

</style>
