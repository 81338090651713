<template>
    <div>
        <div class="main-title">权限变更</div>
        <div class="detail-info">
            <el-form v-loading="loading" ref="ruleForm" :model="form" :rules="rules" label-width="120px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="actionType">
                    <el-select v-model="form.actionType" placeholder="请选择类型">
                        <el-option label="课程转移" :value="1"></el-option>
                        <el-option label="开通课程" :value="2"></el-option>
                        <el-option label="关闭课程" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.remarks"></el-input>
                </el-form-item>
                <el-form-item label="附件">
                    <paste-image upload-path="question" @onSuccess="onSuccess" @onRemove="onRemove"
                        :picArray="picArray"></paste-image>
                </el-form-item>
                <div v-if="form.actionType == 1">
                    <el-form-item label="转让人">
                        <sales-user-course v-if="!userInfo.transferor.name" @onAdd="addUserCourse">
                        </sales-user-course>
                        <div v-else>
                            <el-descriptions title="用户信息" direction="vertical" :column="4" border>
                                <template slot="extra">
                                    <el-button type="danger" size="small" @click="deleteUserCourse('transferor')">删除
                                    </el-button>
                                </template>
                                <el-descriptions-item label="用户名">{{ userInfo.transferor.name }}</el-descriptions-item>
                                <el-descriptions-item label="头像">
                                    <el-avatar :src="userInfo.transferor.avatar_url"></el-avatar>
                                </el-descriptions-item>
                            </el-descriptions>

                            <div v-if="courseData.courses.length > 0">
                                <b>普通课程</b>
                                <el-table :data="courseData.courses" style="width: 100%">
                                    <el-table-column prop="name" label="名字">
                                    </el-table-column>
                                    <el-table-column label="图片">
                                        <template slot-scope="scope">
                                            <el-image :src="imageUrl + scope.row.pic"></el-image>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>


                            <div v-if="courseData.checkCourses.length > 0">
                                <b>打卡课程</b>
                                <el-table :data="courseData.checkCourses" style="width: 100%">
                                    <el-table-column prop="name" label="名字">
                                    </el-table-column>
                                    <el-table-column label="图片">
                                        <template slot-scope="scope">
                                            <el-image :src="imageUrl + scope.row.pic"></el-image>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>

                        </div>
                    </el-form-item>
                    <el-form-item label="受转让人">
                        <select-user v-if="!userInfo.transferee.name" @onAdds="transfereeFn"></select-user>
                        <div v-else>
                            <el-descriptions title="用户信息" direction="vertical" :column="4" border>
                                <template slot="extra">
                                    <el-button type="danger" size="small" @click="deleteUserCourse('transferee')">删除
                                    </el-button>
                                </template>
                                <el-descriptions-item label="用户名">{{ userInfo.transferee.name }}</el-descriptions-item>
                                <el-descriptions-item label="头像">
                                    <el-avatar :src="userInfo.transferee.avatar_url"></el-avatar>
                                </el-descriptions-item>
                            </el-descriptions>
                            <div v-if="courseData.checkCourses.length > 0">
                                <b>打卡课程</b>
                                <el-table :data="courseData.checkCourses" style="width: 100%">
                                    <el-table-column prop="name" label="名字" width="200">
                                    </el-table-column>
                                    <el-table-column label="图片">
                                        <template slot-scope="scope" width="400">
                                            <el-image :src="imageUrl + scope.row.pic"></el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="课程时间">
                                        <template slot-scope="scope">
                                            <div>
                                                <el-form-item label="开始时间">
                                                    <!-- <el-date-picker v-model="scope.row.start_at" type="date"
                                                        placeholder="选择日期" value-format="timestamp">
                                                    </el-date-picker> -->
                                                    <el-date-picker v-model="scope.row.start_at" type="datetime"
                                                        placeholder="选择日期时间" default-time="00:00:00">
                                                    </el-date-picker>
                                                </el-form-item>
                                                <el-form-item label="是否全部计划">
                                                    <el-switch v-model="scope.row.is_all" active-color="#13ce66"
                                                        inactive-color="#ff4949">
                                                    </el-switch>
                                                </el-form-item>
                                                <el-form-item v-if="!scope.row.is_all" label="开始计划天数">
                                                    <el-input v-model="scope.row.start_plan_day" placeholder="请输入开始天数">
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item v-if="!scope.row.is_all" label="结束计划天数">
                                                    <el-input v-model="scope.row.end_plan_day" placeholder="请输入结束天数">
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item label="更新周期">
                                                    <el-checkbox-group v-if="str" v-model="scope.row.update_day"
                                                        size="small">
                                                        <el-checkbox-button @change="selectDate(scope.row)"
                                                            v-for="(city, index) in ['日', '一', '二', '三', '四', '五', '六']"
                                                            :label="index" :key="city">
                                                            周{{ city }}
                                                        </el-checkbox-button>
                                                    </el-checkbox-group>
                                                </el-form-item>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-form-item>
                </div>

                <div v-if="form.actionType == 2">
                    <el-form-item label="开通信息：" label-position="left">
                        <sales-course-user @onAdd="addUserCourse"></sales-course-user>
                    </el-form-item>
                    <el-form-item label="" label-position="left" v-for="(userCourse, index) in forms.userCourse"
                        :key="index">
                        <el-descriptions title="" size="mini" border :column="2">
                            <template slot="extra">
                                <el-button type="danger" size="small" @click="deleteUserCourse(index)">删除</el-button>
                            </template>
                            <el-descriptions-item label="用户ID">{{ userCourse.user_id }}</el-descriptions-item>
                            <el-descriptions-item label="头像和用户名">
                                <div>
                                    <el-avatar size="small" :src="userCourse.user_name_avatar_url"></el-avatar>
                                </div>
                                <div>{{ userCourse.user_name }}</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="课程ID">{{ userCourse.course_id }}</el-descriptions-item>
                            <el-descriptions-item label="课程名">
                                <div>
                                    <el-image style="width: 100px;" :src="imageUrl + userCourse.course_pic"
                                        fit="contain"></el-image>
                                </div>
                                <div>{{ userCourse.course_name }}</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="课程类型">{{ userCourse.course_type === 'checkCourse' ? '阅读日程' :
                                    '录播课程'
                            }}</el-descriptions-item>

                            <template v-if="userCourse.course_type === 'checkCourse'">
                                <el-descriptions-item label="开始更新时间" :span="2">{{ userCourse.start_at }}
                                </el-descriptions-item>
                                <el-descriptions-item label="是否全部计划" :span="2">
                                    <el-tag size="small" v-if="userCourse.is_all">是</el-tag>
                                    <el-tag size="small" type="danger" v-else>否</el-tag>
                                </el-descriptions-item>
                                <template v-if="!userCourse.is_all">
                                    <el-descriptions-item label="开始计划天数">{{ userCourse.start_plan_day }}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="结束计划天数">{{ userCourse.end_plan_day }}
                                    </el-descriptions-item>
                                </template>
                                <el-descriptions-item label="更新周期" :span="2">{{ userCourse.update_day }}
                                </el-descriptions-item>
                            </template>

                        </el-descriptions>
                    </el-form-item>
                </div>

                <div v-if="form.actionType == 3">
                    <el-form-item label="人员选择">
                        <sales-user-course v-if="!userInfo.transferor.name" @onAdd="addUserCourse">
                        </sales-user-course>
                        <div v-else>
                            <el-descriptions title="用户信息" direction="vertical" :column="4" border>
                                <template slot="extra">
                                    <el-button type="danger" size="small" @click="deleteUserCourse('transferor')">删除
                                    </el-button>
                                </template>
                                <el-descriptions-item label="用户名">{{ userInfo.transferor.name }}</el-descriptions-item>
                                <el-descriptions-item label="头像">
                                    <el-avatar :src="userInfo.transferor.avatar_url"></el-avatar>
                                </el-descriptions-item>
                            </el-descriptions>

                            <div v-if="courseData.courses.length > 0">
                                <b>普通课程</b>
                                <el-table :data="courseData.courses" style="width: 100%">
                                    <el-table-column prop="name" label="名字">
                                    </el-table-column>
                                    <el-table-column label="图片">
                                        <template slot-scope="scope">
                                            <el-image :src="imageUrl + scope.row.pic"></el-image>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>


                            <div v-if="courseData.checkCourses.length > 0">
                                <b>打卡课程</b>
                                <el-table :data="courseData.checkCourses" style="width: 100%">
                                    <el-table-column prop="name" label="名字">
                                    </el-table-column>
                                    <el-table-column label="图片">
                                        <template slot-scope="scope">
                                            <el-image :src="imageUrl + scope.row.pic"></el-image>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>

                        </div>
                    </el-form-item>
                </div>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">编辑</el-button>
                    <el-button @click="$router.go(-1)">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import PasteImage from "../../../components/pasteImage/index";
import salesUserCourse from "../../../components/sales/salesUserCourse.vue";
import selectUser from "../../../components/sales/selectUser.vue";
import SalesCourseUser from "../../../components/sales/salesCourseUser";
export default {
    name: "add",
    components: {
        salesUserCourse,
        PasteImage,
        selectUser,
        SalesCourseUser
    },
    data() {
        return {

            forms: {
                userCourse: [],
            },
            imageUrl: config.imageUrl,
            //表单数据
            form: {
                name: "", // 名称
                actionType: null, // 申请类型 => 课程转移
                remarks: "", // 备注
                attachFiles: [], // 附件
                courses: [], // 普通课程
                checkCourses: {}, // 打卡课程
                apply: [],
            },
            //表单验证
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    { min: 2, message: '长度在 2 以上', trigger: 'blur' }
                ],
                actionType: [
                    { required: true, message: '请选择类型', trigger: 'change' }
                ],
            },
            //对话框
            dialogVisible: false,
            //搜索用户
            searchType: 1,
            //用户
            studentName: '',
            //加载动画
            loading: false,
            //查找到了用户列表
            users: [],
            //判断是否查找到了数据
            notData: '',
            //课程列表
            courseList: [],
            //选择用户的数据
            selectCurrentUser: {},
            //转移的对象
            transfer: {},
            //用户信息
            userInfo: {
                transferor: {},
                transferee: {}
            },
            //转让的课程
            courseData: {
                courses: [],
                checkCourses: []
            },
            str: true,
            picArray: []
        }
    },
    methods: {
        //vuex
        ...mapActions('user', ['getUserList']),
        ...mapActions('sales', ['userCourseOperation', 'getPowerget']),
        selectDate(row) {
            this.str = false
            this.courseData.checkCourses.forEach(v => {
                if (v.id == row.id) {
                    v.update_day = row.update_day
                    this.str = true
                }
            })
        },
        addUserCourse(info) {
            if (this.form.actionType == 1) {
                this.userInfo.transferor = info.userInfo
                info.checkCourses.forEach(v => {
                    v.is_all = v.is_all == 1 ? true : false
                    v.update_day = v.update_day ? v.update_day : [1, 2, 4, 5]
                })
                Object.assign(this.courseData, info)

            } else if (this.form.actionType == 2) {
                this.forms.userCourse.push(info)
            } else {
                this.userInfo.transferor = info.userInfo
                Object.assign(this.courseData, info)
            }
        },
        transfereeFn(info) {
            this.userInfo.transferee = info.userInfo
        },
        //删除用户所选用户信息
        deleteUserCourse(data) {
            if (this.form.actionType == 1) {
                this.userInfo[data] = {}
            } else if (this.form.actionType == 2) {
                this.forms.userCourse.splice(data, 1)
            } else {
                this.userInfo[data] = {}
            }
        },
        // 图片上传
        onSuccess(path) {
            this.form.attachFiles = path
        },
        // 图片移除
        onRemove(index, file) {
            this.form.attachFiles.splice(index, 1)
        },
        //搜索关键字选择之后触发
        selectUser(id) {
            this.users.forEach(item => {
                if (id === item.id) {
                    Object.assign(this.selectCurrentUser, item)
                }
            })
            this.form.user_id = id
        },
        //用户进行搜索输入时触发
        remoteMethod(v) {
            let searchData = {}
            if (this.searchType === 1) {
                searchData.name = v
                searchData.pageSize = 1000
            } else {
                searchData.uid = v
                searchData.pageSize = 1000
            }
            if (v === '') {
                this.$message.error('请输入昵称')
                return
            }
            this.notData = false
            this.getUserList(searchData).then(res => {
                if (res.data.list.length === 0) {
                    this.notData = true
                }
                this.users = res.data.list
            })
        },
        //时间
        format(date) {
            let data = new Date(date)
            let y = data.getFullYear();//年
            let m = data.getMonth() + 1;//月
            let d = data.getDate();//日
            let h = data.getHours();//时
            let min = data.getMinutes();//分
            let s = data.getSeconds(); //秒
            m = m > 10 ? m : '0' + m
            d = d > 10 ? d : '0' + d
            h = h > 10 ? h : '0' + h
            min = min > 10 ? min : '0' + min
            s = s > 10 ? s : '0' + s
            let str = y + '-' + m + '-' + d + ' ' + h + ':' + min + ':' + s
            return str
        },
        //点击对话框以外的内容关闭对话框
        handleClose() {
            this.$confirm('关闭之后表单选择的数据会清空，确认关闭？')
                .then(_ => {
                    this.dialogVisible = false
                    this.users = []
                    this.selectCurrentUser = {}
                    this.notData = false
                    this.studentName = ''
                    this.transfer.region = ''
                })
        },
        //选择替换权限的人
        change() {
            this.dialogVisible = true
        },
        //选择更换权限
        saveUserCourse() {
            this.dialogVisible = false
        },
        //处理数据
        dataDispose() {
            if (this.form.actionType == 1) {
                this.form.id = parseFloat(this.$route.params.id)
                let arr = JSON.parse(JSON.stringify(this.courseData.checkCourses))
                arr.forEach(v => {
                    if (v.start_at) {
                        v.check_course_id = v.id
                        v.is_all = v.is_all ? 1 : 0
                        v.start_plan_day = v.is_all ? 0 : v.start_plan_day
                        v.end_plan_day = v.is_all ? 0 : v.end_plan_day
                        v.start_at = this.format(v.start_at)
                    } else {
                        this.$message.error('签到项目需要输入开始时间')
                    }
                })
                let courses = []
                this.courseData.courses.map(i => {
                    courses.push(i.id)
                })
                this.form.checkCourses = arr
                this.form.courses = courses
                this.form.transferee = this.userInfo.transferee.id
                this.form.transferor = this.userInfo.transferor.id
            } else if (this.form.actionType == 2) {
                let arr = []
                let checkCourses = []
                let courses = []
                this.forms.userCourse.forEach((v, i) => {
                    arr.push({
                        userId: v.user_id
                    })
                    if (v.course_type == "checkCourse") {
                        checkCourses.push({
                            "check_course_id": v.course_id,
                            "update_day": v.update_day,
                            "start_at": v.start_at,
                            "is_all": v.is_all,
                            "start_plan_day": v.start_plan_day,
                            "end_plan_day": v.end_plan_day
                        })
                        arr[i]['checkCourses'] = checkCourses
                        arr[i]['courses'] = []
                    } else {
                        courses.push(v.course_id)
                        arr[i]['courses'] = courses
                        arr[i]['checkCourses'] = []
                    }
                })
                this.form.apply = arr
            } else {
                let courses = []
                let checkCourses = []
                this.courseData.courses.map(i => {
                    courses.push(i.id)
                })
                this.courseData.checkCourses.map(i => {
                    checkCourses.push(i.id)
                })
                this.form.userId = this.userInfo.transferor.id
                this.form.courses = courses
                this.form.checkCourses = checkCourses
            }
        },
        //提交表单
        submitForm(formName) {
            this.dataDispose()
            if (this.form.actionType == 1) {
                this.form.checkCourses.forEach(v => {
                    if (!v.start_at) {
                        return
                    }
                })
                if (this.userInfo.transferor.id == this.userInfo.transferee.id) {
                    this.$message.error('受转让人和转让人不能是同一个人')
                    return false
                }
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitData()
                    this.userCourseOperation(this.form).then(res => {
                        if (res.res_info == 'ok') {
                            this.$router.go(-1);
                            this.$message.success('编辑成功')
                        }
                    })
                } else {
                    this.$message.error('还有必须输入的内容没有输入')
                    return false;
                }
            });
        },
        //提交表单验证
        submitData() {
            if (this.form.actionType == 1) {
                if (!this.userInfo.transferor.name) {
                    this.$message.error('转让人没有选择')
                    return
                }
                if (!this.userInfo.transferee.name) {
                    this.$message.error('受转让人没有选择')
                    return
                }
            } else if (this.form.actionType == 2) {
                if (!this.forms.userCourse.length > 0) {
                    this.$message.error('开通课程的人员没有选择')
                    return
                }
            } else {
                if (!this.userInfo.transferor.name) {
                    this.$message.error('关闭课程的人员没有选择')
                    return
                }
            }
        },
        initData() {
            this.loading = true
            this.getPowerget(parseFloat(this.$route.params.id)).then(res => {
                res.data.detail.actionType = res.data.detail.action_type
                res.data.detail.attachFiles = res.data.detail.attach_files.split(',')
                this.form = res.data.detail
                if (this.form.actionType == 1) {
                    res.data.detail.lists.forEach(v => {
                        if (v.identity == 1) {
                            //转让人
                            this.userInfo.transferor.id = v.user_id
                            this.userInfo.transferor.name = v.username
                            this.userInfo.transferor.avatar_url = v.avatar_url
                        } else {
                            //被转让人
                            this.userInfo.transferee.id = v.user_id
                            this.userInfo.transferee.name = v.username
                            this.userInfo.transferee.avatar_url = v.avatar_url
                            if (v.course_type == 1) {
                                //普通课程
                                this.courseData.courses.push({
                                    name: v.json_data.course_name,
                                    pic: v.json_data.course_pic,
                                    id: v.json_data.course_id,
                                })
                            } else {
                                //打卡课程
                                this.courseData.checkCourses.push({
                                    name: v.json_data.name,
                                    pic: v.json_data.pic,
                                    id: v.json_data.check_course_id,
                                    start_at: Date.parse(v.json_data.start_at),
                                    is_all: v.json_data.is_all == 1 ? true : false,
                                    start_plan_day: v.json_data.start_plan_day,
                                    end_plan_day: v.json_data.end_plan_day,
                                    update_day: v.json_data.update_day,
                                })
                            }

                        }
                    })
                } else if (this.form.actionType == 2) {
                    let arr = []
                    this.forms.userCourse = []
                    this.form.lists.forEach(v => {
                        arr.push({
                            user_id: v.user_id,
                            user_name_avatar_url: v.avatar_url,
                            user_name: v.username,
                            course_id: v.json_data.id,
                            course_pic: v.json_data.pic,
                            course_name: v.json_data.name,
                            course_type: v.course_type == 2 ? 'checkCourse' : 'course',
                            start_at: Date.parse(v.json_data.start_at),
                            is_all: v.json_data.is_all == 1 ? true : false,
                            start_plan_day: v.json_data.start_plan_day,
                            end_plan_day: v.json_data.end_plan_day,
                            update_day: v.json_data.update_day,
                        })
                    })
                    this.forms.userCourse = arr
                } else {
                    this.form.lists.forEach(v => {
                        this.userInfo.transferor.id = v.user_id
                        this.userInfo.transferor.name = v.username
                        this.userInfo.transferor.avatar_url = v.avatar_url
                        if (v.course_type == 2) {
                            //签到课程
                            this.courseData.checkCourses.push({
                                name: v.json_data.course_name,
                                pic: v.json_data.course_pic,
                                id: v.json_data.check_course_id
                            })
                        } else {
                            //普通课程
                            this.courseData.courses.push({
                                name: v.json_data.course_name,
                                pic: v.json_data.course_pic,
                                id: v.json_data.check_course_id
                            })
                        }
                    })
                }
                this.loading = false
                this.picArray = this.form.attachFiles
            })
        }
    },
    created() {
        this.initData()
    }
}
</script>

<style scoped>
.el-form-item {
    margin-bottom: 20px;
}

.el-input,
.el-select,
.el-textarea /deep/ {
    width: 40% !important;
}

.el-image {
    width: 120px;
}
</style>
