<template>
    <div>
        <el-button plain @click="dialogVisible = true">选择图片</el-button>

        <el-dialog
            title="选择图片"
            :visible.sync="dialogVisible"
            width="700px"
            append-to-body
            :before-close="handleClose">
            <div slot="title">
                我的图片
            </div>

            <div class="file-content" >
                <div class="all-file">
                    <p class="file-tag selected">全部文件 <span class="file-count">({{this.total}})</span> </p>
                </div>

                <div class="file-list-content">

                    <div class="file-list" v-loading="loading">
                        <p class="search">
                            <el-input style="width: 180px" v-model="name" clearable placeholder="输入文件名"></el-input>
                            <el-button plain @click="search">筛选</el-button>
                        </p>
                        <div class="file-item" v-for="file in files"  v-bind:key="file.id" @click="onSelectFile(file)">
                            <span v-if="selectFileId == file.id" class="select-icon"></span>
                            <div class="file-item-top">
                                <span class="file-icon"><img src="/img/pdf.png"/> </span>
                                <div class="file-info">
                                    <p class="file-info-title">
                                        <el-tooltip effect="dark" :content="file.name" placement="top">
                                            <span>{{file.name}}</span>
                                        </el-tooltip>
                                    </p>
                                    <p class="file-info-size">{{parseFloat((file.size/1024)/1024).toFixed(2) + 'M'}}</p>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>

                        <div class="search">
                            <el-upload
                                class="fl-l"
                                ref="upload"
                                :show-file-list="false"
                                :action="uploadUrl"
                                :data="uploadData"
                                :before-upload="beforeUpload"
                                :on-success="onSuccess"
                                :on-error="onError"
                                :auto-upload="true">
                                <el-button slot="trigger" size="small" :loading="uploading" type="success">上传文件</el-button>
                            </el-upload>
                            <div class="page fl-r" >
                                <el-pagination
                                    small
                                    background
                                    :current-page="page"
                                    :page-sizes="[10, 20]"
                                    :page-size="pageSize"
                                    layout="total, prev, pager, next "
                                    @size-change="setPageSize"
                                    @current-change="setPage"
                                    :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="select">确 定</el-button>
              </span>

        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "imageUpload",
        data(){
            return {
                files:[1,2,3],
                dialogVisible:true

            }
        },
        methods:{
            select(){

            },
            handleClose(done) {
                done();
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            onSelectFile(file){
                this.selectFileId = file.id
                this.selectFileName = file.name
                this.selectFile = file
            },
            getList(){

            },
            search(){
                this.page = 1

            },
            reelectFile(){
                this.dialogVisible = true
            },
            deleteFile(){
                this.isSelect = false
            },
            async beforeUpload(file){

            },
            onSuccess(response, file, fileList){


            },
            onError(err, file, fileList){
                this.$message.error('上传失败！')
                this.uploading = false
            }
        }
    }
</script>

<style>

</style>
