<template>
	<div>
		<div class="detail-title">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>班级管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/classList' }"
					>班级列表</el-breadcrumb-item
				>
				<el-breadcrumb-item>修改班级</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="detail-info" v-loading="loading">
			<el-form
				ref="form"
				:rules="rules"
				:model="form"
				label-width="80px"
				size="mini"
			>
				<el-form-item
					label="名称: "
					label-position="left"
					class="input"
					prop="name"
				>
					<el-input style="width: 200px" v-model="form.name"></el-input>
				</el-form-item>

				<el-form-item label="封面：" label-position="left" prop="pic">
					<edit-cover
						:pic="imageUrl + form.pic"
						:uploadPath="uploadPath"
						@success="uploadSuccess"
					></edit-cover>
				</el-form-item>

				<el-form-item>
					<el-button size="small" type="primary" @click="onSubmit"
						>保存</el-button
					>
					<el-button
						style="margin-left: 10px"
						size="small"
						@click="$router.back()"
						>取消</el-button
					>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import editCover from '../../../components/editCover'
import { mapActions } from 'vuex'

import { config } from '../../../js/config'

export default {
	name: 'Add',
	components: { editCover },

	data() {
		return {
			loading: false,
			uploadPath: 'class',
			rules: {
				name: [
					{ required: true, trigger: 'change', message: '请输入班级名称' }
				],
				pic: [{ required: true, trigger: 'change', message: '请上传封面' }]
			},
			form: {
				name: '',
				pic: ''
			},
			imageUrl: config.imageUrl
		}
	},
	methods: {
		...mapActions('classManage', ['addClass', 'getClassList']),

		uploadSuccess(path) {
			this.form.pic = path
		},

		onSubmit() {
			this.$refs.form.validate(async valid => {
				if (!valid) return

				this.loading = true

				await this.addClass({ ...this.form, id: this.$route.query.id })

				this.loading = false

				this.$router.back()
			})
		},

		getClassInfo() {
			const { id, name, pic } = this.$route.query

			if (!id || !name || !pic) return this.$router.replace('/classList')

			this.form.pic = pic
			this.form.name = name
		}
	},

	created() {
		this.getClassInfo()
	}
}
</script>

<style>
.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
</style>
