import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getWorkList({commit},data)
    {
        return (new Api()).getList(config.getWorkList,data)
    },
    commentWork({commit},data)
    {
        return (new Api()).getList(config.commentWork,data)
    },
    getWorkCount({commit},data)
    {
        return (new Api()).getList(config.getWorkCount,data)
    },
    isCommenting({commit},data){
        return (new Api()).getList(config.isCommenting,data)
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
