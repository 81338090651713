<template>
    <div >
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >内容</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/excerpt' }">美文摘抄</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info" v-loading="editLoading">
            <el-form ref="form" :model="form" label-width="80px" size="mini" >

                <el-form-item label="标题：" label-position="left" class="input">
                    <el-input style="width: 200px"  v-model="form.title"></el-input>
                </el-form-item>

                <el-form-item label="封面：" label-position="left" >
                    <edit-cover :pic="image" :uploadPath="uploadPath" @success="success"></edit-cover>
                </el-form-item>

                <el-form-item label="标签：" label-position="left" class="input" >
                    <el-tag
                        :key="tag"
                        v-for="tag in form.tags"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(tag)">
                        {{tag}}
                    </el-tag>
                    <el-input
                        class="input-new-tag"
                        v-if="inputVisible"
                        v-model="inputValue"
                        ref="saveTagInput"
                        size="small"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加标签</el-button>
                </el-form-item>

                <el-form-item label="内容：" label-position="left" >
                        <editor @onChange="onChange" :content="form.content"></editor>
                </el-form-item>


                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/excerpt" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import editor from '../../../components/editor'
    import {mapActions} from "vuex";
    import EditCover from "../../../components/editCover";
    export default {
        name: "Add",
        components:{EditCover, editor},
        data() {
            return {
                statusTxt:'上架',
                loading:false,
                editLoading:false,
                inputVisible: false,
                inputValue: '',
                image:'',
                uploadPath:'excerpt',
                form: {
                    id:0,
                    status:true,
                    pic:'',
                    title:'',
                    content:'',
                    tags:[],
                },
                options: [
                    {
                        value: 0,
                        label: '展示图片'
                    },
                    {
                        value: 1,
                        label: '跳转文章'
                    },
                    {
                        value: 2,
                        label: '跳转下载'
                    },
                    {
                        value: 3,
                        label: '跳转外部链接'
                    }
                ],

            }
        },
        methods: {
            ...mapActions('excerpt',['addExcerpt','getExcerptDetail']),
            uploadSuccess(path){
                this.form.pic = path
            },
            async onSubmit() {
                let _this = this
                this.loading = true
                console.log(this.form)
                await this.addExcerpt(this.form).then( res => {
                    if(res.ret == 0){
                        _this.$message.success('修改成功！')
                        _this.$router.push('/excerpt')
                    }
                })
                this.loading = false
            },
            success(path){
                this.form.pic = path
            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            handleClose(tag) {
                this.form.tags.splice(this.form.tags.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(() => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.form.tags.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            onChange(content){
                console.log(content)
                this.form.content = content
            },
        },
        mounted() {
            this.editLoading = true
            this.id = this.$route.params.id
            this.form.id = this.$route.params.id
            this.getExcerptDetail({id:this.id}).then(res => {
                this.form.pic = res.data.pic
                this.form.title = res.data.title
                this.form.content = res.data.content
                this.form.status = res.data.status ? true : false
                this.form.tags = res.data.tags
                this.image = config.imageUrl + res.data.pic
                console.log(this.form)
                this.editLoading = false
            })
        }
    }
</script>

<style>
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
</style>
