import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getSlideShowList({commit},data){
        return (new Api()).getSlideShowList(data)
    },
    setSlideShowStatus({commit},data){
        return (new Api()).setStatus('SlideShowModel',data.id,data.status)
    },
    delSlideShow({commit},data){
        return (new Api()).delSlideShow(data)
    },
    addSlideShow({commit},data){
        return (new Api()).addSlideShow(data.pic,data.type,data.status,data.resource)
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
