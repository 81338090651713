<template>
	<div v-loading="loading">
		<div class="main-title">班级管理</div>

		<div class="content-list-page page-content">
			<div class="list-head-box">
				<div data-v-69874ad0 class="list-head">
					<p data-v-69874ad0 class="list-head-title">温馨提示</p>
					<ul data-v-69874ad0>
						<li data-v-69874ad0>XXXXXX</li>
					</ul>
				</div>
			</div>

			<div class="padding-t-sm padding-b-sm">
				<span class="fl-l">
					<el-input
						v-model="search"
						size="medium"
						placeholder="输入名称"
						@keyup.enter.native="getTableData"
					/>
				</span>

				<span class="margin-l-sm fl-l">
					<el-button @click="getTableData" type="primary">搜索</el-button>
				</span>

				<span class="fl-r">
					<el-button
						type="primary"
						size="small"
						@click="$router.push('/classList/add')"
					>
						新增
					</el-button>
				</span>
			</div>

			<el-table :data="tableData">
				<el-table-column prop="id" label="id" />
				<el-table-column prop="name" label="班级名称" />
				<el-table-column prop="number" label="班级人数" />
				<el-table-column label="封面">
					<template slot-scope="scope">
						<el-image
							class="table-item-img"
							:src="imageUrl + scope.row.pic"
							:preview-src-list="[imageUrl + scope.row.pic]"
						/>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" label="创建时间" />
				<el-table-column prop="user_name" label="班主任" />
				<el-table-column label="状态">
					<template slot-scope="scope">
						{{ scope.row.status ? '正常' : '解散' }}
					</template>
				</el-table-column>

				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="replace(scope.row)">
							详情 -
						</el-button>

						<el-button
							type="text"
							@click="
								$router.push({
									path: '/classList/edit',
									query: {
										id: scope.row.id,
										name: scope.row.name,
										pic: scope.row.pic
									}
								})
							"
						>
							编辑 -
						</el-button>

						<el-popconfirm
							title="确定删除班级吗"
							@confirm="deleteClassRoom(scope.row)"
						>
							<el-button type="text" slot="reference">删除</el-button>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../../js/config'

export default {
	data: () => ({
		search: '',
		loading: false,
		total: 0,
		tableData: [],
		imageUrl: config.imageUrl
	}),

	methods: {
		...mapActions('classManage', [
			'getClassList',
			'deleteClass',
			'setClassStatus'
		]),

		async getTableData() {
			this.loading = true

			const { res_info, data } = await this.getClassList({ name: this.search })

			this.loading = false

			if (res_info !== 'ok') return

			this.total = data.total
			this.tableData = data.list
		},

		replace({ id, pic, name }) {
			this.$router.push({ path: '/manage', query: { id, pic, name } })
		},

		async deleteClassRoom({ id }) {
			this.loading = true

			const data = { id, model: 'Class' }

			await this.deleteClass(data)

			this.getTableData()
		},

		async confirm({ id }) {
			this.loading = true

			await this.setClassStatus({ model: 'ClassModel', id, status: 0 })

			this.getTableData()
		}
	},

	created() {
		this.getTableData()
	}
}
</script>
