<template>
	<div>
		<div class="detail-title">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>班级管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/classList' }">
					班级列表
				</el-breadcrumb-item>
				<el-breadcrumb-item>新增</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="detail-info">
			<el-form
				ref="form"
				:model="form"
				:rules="rules"
				label-width="80px"
				size="mini"
			>
				<el-form-item
					label="班级名称: "
					label-position="left"
					class="input"
					prop="name"
				>
					<el-input style="width: 200px" v-model="form.name"></el-input>
				</el-form-item>

				<el-form-item label="封面：" label-position="left" prop="pic">
					<cover :uploadPath="uploadPath" @success="uploadSuccess"></cover>
				</el-form-item>

				<el-form-item>
					<el-button
						size="small"
						type="primary"
						:loading="loading"
						@click="onSubmit"
					>
						保存
					</el-button>
					<el-button
						style="margin-left: 10px"
						size="small"
						@click="$router.back()"
					>
						取消
					</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import cover from '../../../components/cover'

import { mapActions } from 'vuex'

export default {
	name: 'Add',
	components: { cover },

	data() {
		return {
			loading: false,
			uploadPath: 'class',

			form: {
				pic: '',
				name: ''
			},

			rules: {
				name: [
					{ required: true, trigger: 'change', message: '请输入班级名称' }
				],
				pic: [{ required: true, trigger: 'change', message: '请上传封面' }]
			}
		}
	},
	methods: {
		...mapActions('classManage', ['addClass']),

		uploadSuccess(path) {
			this.form.pic = path
		},

		onSubmit() {
			this.loading = true

			this.$refs.form.validate(async valid => {
				if (!valid) return

				await this.addClass(this.form)

				this.$router.push('/classList')
			})

			this.loading = false
		},

		onChange(content) {
			this.form.content = content
		}
	}
}
</script>

<style>
.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
</style>
