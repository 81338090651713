import {Api} from "../../service/Api";

const state = () => ({
    info:{}
})

const getters = {

}

const actions = {
    getUserInfoToState({commit},data){
        new Api().getUserInfo(data).then(res => {
            commit('setUserInfoMutation', res.data)
        })
    },
    getUserInfo({commit},data){
        return (new Api()).getUserInfo(data)
    },
    getUserList({commit},data={})
    {
        return (new Api()).getList(config.getUserList,data)
    },
    setUserBan({commit},data={}){
        return (new Api()).getList(config.setUserBan,data)
    },
    setUserStatus({commit},data){
        return (new Api()).setStatus('User',data.id,data.status)
    },
    getUserSocialList({commit},data={})
    {
        return (new Api()).getList(config.getUserSocialList,data)
    },
    getUserSocialDetail({commit},data={}){
        return (new Api()).getList(config.getUserSocialDetail,data)
    },
    getUserSocialCommentList({commit},data={}){
        return (new Api()).getList(config.getUserSocialCommentList,data)
    },
    setUserCredit({commit},data={})
    {
        return (new Api()).edit(config.setUserCredit,data)
    },
    setUserRole({commit},data={}){
        return (new Api()).edit(config.setUserRole,data)
    },
    setUserSocialStatus({commit},data){
        return (new Api()).setStatus('SocialModel',data.id,data.status)
    },
    //赠送用户vip
    donateVip({commit},data){
        return (new Api()).edit(config.donateVip,data)
    },
    //获取用户vip记录
    getUserVipLog({commit},data){
        return (new Api()).getList(config.getUserVipLog,data)
    },
    //关闭vip
    closeUserVip({commit},data){
        return (new Api()).getList(config.closeUserVip,data)
    },

    exportUsers({commit},data){
        return (new Api()).getList(config.exportUsers,data)
    },
    transferList({ commit }, data) {
        return new Api().getList(config.transferList, data);
      },
      transferDetail({ commit }, data) {
        return new Api().getList(config.transferDetail, data);
      },
}

const mutations = {
    setUserInfoMutation(state,data){
        state.info = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
