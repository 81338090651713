import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getVideoList({commit},data){
        return (new Api()).getList(config.getVideoList,data)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
