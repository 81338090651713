<template>
	<div class="top-header">
		<span
			:style="{ 'margin-left': topWidth + 'px' }"
			@click="setMenuStatus(menuStatus)"
			class="open-menu"
			:class="icon"
		></span>

		<div class="padding-r-sm info">
			<!-- <el-button
				@click="$router.push('/message')"
				icon="el-icon-message"
				type="primary"
				circle
			/>&nbsp;&nbsp;&nbsp;&nbsp; -->


			<div class="padding-r-sm">{{ this.$store.state.user.info.name }}</div>

			<span class="padding-r-sm">
				<el-avatar size="small" :src="this.$store.state.user.info.avatar_url" />
			</span>

			<el-popconfirm
				title="退出登录?"
				icon="el-icon-warning"
				icon-color="#F56C6C"
				@confirm="confirm"
			>
				<el-button type="text" slot="reference">退出登录</el-button>
			</el-popconfirm>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { Message } from 'element-ui'

export default {
	name: 'top',
	data() {
		return {
			topWidth: 60,
			icon: 'el-icon-s-unfold',
			menuStatus: true,
			imageUrl: config.imageUrl
		}
	},
	methods: {
		...mapActions('user', ['getUserInfo']),

		setMenuStatus(status) {
			if (this.menuStatus) {
				this.topWidth = 230
				this.icon = 'el-icon-s-fold'
			} else {
				this.topWidth = 60
				this.icon = 'el-icon-s-unfold'
			}
			this.menuStatus = !status
			this.$emit('setMenuStatus', this.menuStatus)
		},

		confirm() {
			this.$cookies.remove('userId')
			this.$cookies.remove('token')
			this.$cookies.set('isLogin', false)

			Message('请重新登录')

			this.$router.replace('/login')
		}
	},
	mounted() {
		this.$store.dispatch('user/getUserInfoToState')

		// let socket = new WebSocket(
		// 	'ws://192.168.1.13:2346?token=73eb83b0-c423-3188-bb5a-68ae2ea2e73b'
		// )

		// socket.onopen = event => {
		// 	console.log(event)
		// }

		// socket.onmessage = msg => {
		// 	console.log(msg)
		// }
	},
	watch: {}
}
</script>

<style>
.info {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.top-header {
	height: 60px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.span-block {
	display: inline-block;
	padding: 0 10px;
	height: 60px;
	line-height: 60px;
}
.open-menu {
	font-size: 24px;
	padding: 20px;
	color: rgb(102, 102, 102);
	font-weight: normal;
	cursor: pointer;
}
.open-menu:hover {
	color: #409eff;
}
</style>
