<template>
  <div class="content">
    <div class="header text-center item-title">{{detail.name}}</div>
    <div class="margin-t-sm">
      <el-card class="box-card">
        <div v-for="question in detail.questions" :key="question.index" class="text item">
          <div v-if="question.type === 1"><el-tag type="info">第{{question.order}}题【 单选题 】</el-tag></div>
          <div v-else-if="question.type === 2"><el-tag type="info">第{{question.order}}题【 多选题 】</el-tag></div>
          <div v-else-if="question.type === 3"><el-tag type="info">第{{question.order}}题【 判断题 】</el-tag></div>
          <div v-else-if="question.type === 4"><el-tag type="info">第{{question.order}}题【 填空题 】</el-tag></div>
          <el-tag type="info">{{question.order + '.'}}</el-tag>
          <span class="text item-title">{{question.content}}</span>
          <div v-for="(items,index) in detail.items" :key="index" class="text item">
              <div v-if="question.text_type === 1 && question.type !== 3">
				  <span v-if="items.questions_id === question.id" class="margin-l-sm" style="line-height: 2rem;font-size: 0.8rem;">
					  <el-tag v-if="items.sort === 1" type="info">{{sort.A + '.'}}</el-tag>
					  <el-tag v-else-if="items.sort === 2" type="info">{{sort.B + '.'}}</el-tag>
					  <el-tag v-else-if="items.sort === 3" type="info">{{sort.C + '.'}}</el-tag>
					  <el-tag v-else-if="items.sort === 4" type="info">{{sort.D + '.'}}</el-tag>
					  <el-tag v-else-if="items.sort === 5" type="info">{{sort.E + '.'}}</el-tag>
					  <el-tag v-else-if="items.sort === 6" type="info">{{sort.F + '.'}}</el-tag>
					  {{items.item}}
				  </span>
			  </div>
			  <div v-else-if="question.text_type === 2 && question.text_type !== 3">
			     <span v-if="items.questions_id === question.id" class="margin-l-sm" style="vertical-align: middle;">
					 <span>
						 <el-tag v-if="items.sort === 1" type="info">{{sort.A + '.'}}</el-tag>
						 <el-tag v-else-if="items.sort === 2" type="info">{{sort.B + '.'}}</el-tag>
						 <el-tag v-else-if="items.sort === 3" type="info">{{sort.C + '.'}}</el-tag>
						 <el-tag v-else-if="items.sort === 4" type="info">{{sort.D + '.'}}</el-tag>
						 <el-tag v-else-if="items.sort === 5" type="info">{{sort.E + '.'}}</el-tag>
						 <el-tag v-else-if="items.sort === 6" type="info">{{sort.F + '.'}}</el-tag>
					 </span>
					 <!-- <img width="100" height="100" :src="path+items.item"> -->
					 <el-image
					   style="width: 100px; height: 100px; vertical-align: middle;"
					   :src="path+items.item"
					   fit="contain" lazy>
					   <div slot="error" class="image-slot">
						  <i class="el-icon-picture-outline"></i>
					   </div>
				     </el-image>
				 </span>
			  </div>
			  <div v-else>
			     <span v-if="items.questions_id === question.id" class="margin-l-sm" style="line-height: 2rem;font-size: 0.8rem;">{{items.item}}</span>
			  </div>
          </div>
          <div v-for="answer in detail.answer" :key="answer.index" class="text item">
            <el-tag v-if="answer.questions_id === question.id" type="success">正确答案: {{answer.answer}}</el-tag>
          </div>
          <div v-for="analyses in detail.analyses" :key="analyses.index" class="text item">
            <el-tag type="info" v-if="analyses.questions_id === question.id">本题解析: {{analyses.analyses.content}}</el-tag>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../../js/config'
export default {
  name: "detail",
  data(){
    return {
      id: 0,
      detail: [],
	  path: config.imageUrl,
	  sort: {
		  A: 'A',
		  B: 'B',
		  C: 'C',
		  D: 'D',
		  E: 'E',
		  F: 'F'
	  }
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    ...mapActions('questionBank',['getQuestionBankDetail']),
    async getDetail(){
      var vm = this
      const { res_info, data } = await this.getQuestionBankDetail({id: this.id})
      if (res_info !== 'ok') return
      vm.detail = data
    }
  }
}
</script>
