<template>
	<div>
		<div class="main-title">作业点评</div>
		<div class="content-list-page page-content">
			<div class="list-head-box">
				<div data-v-69874ad0="" class="list-head">
					<p data-v-69874ad0="" class="list-head-title">温馨提示</p>
					<ul data-v-69874ad0="">
						<li data-v-69874ad0="">
							点评后不能修改，请老师点评前先仔细检查点评内容是否存在错别字
						</li>
						<li data-v-69874ad0="">点评完成后系统将自动推送消息到用户微信上</li>
						<li data-v-69874ad0="">
							作业点评为优秀时，学生可获得100学币奖励，学币可以用于下载资料以及兑换商品
						</li>
						<li data-v-69874ad0="">
							附件可以上传例文或者修改后的作业图，不能超过10M
						</li>
					</ul>
				</div>
			</div>

			<div
				class="list-head"
				style="position: relative; margin-bottom: 10px"
				v-loading="countLoading"
			>
				<el-row :gutter="24">
					<el-col :span="4">
						<div class="text-center text-gray">
							今日提交作业总数
							<el-tooltip
								class="item"
								effect="dark"
								content="今日学员提交的作业总数"
								placement="top"
							>
								<i class="el-icon-warning-outline" style="cursor: pointer"></i>
							</el-tooltip>
						</div>
						<div class="text-center padding-t">{{ this.toDayPushCount }}</div>
					</el-col>
					<el-col :span="4">
						<div class="text-center text-gray">
							今日点评作业数量
							<el-tooltip
								class="item"
								effect="dark"
								content="今日我点评的作业数量，不统计作业类型为修改后提交的作业数量"
								placement="top"
							>
								<i class="el-icon-warning-outline" style="cursor: pointer"></i>
							</el-tooltip>
						</div>
						<div class="text-center padding-t">
							{{ this.toDayCommentCount }}
						</div>
					</el-col>
					<el-col :span="4">
						<div class="text-center text-gray">
							当月点评作业总数
							<el-tooltip
								class="item"
								effect="dark"
								content="本月我点评的作业总数，不统计作业类型为修改后提交的作业数量"
								placement="top"
							>
								<i class="el-icon-warning-outline" style="cursor: pointer"></i>
							</el-tooltip>
						</div>
						<div class="text-center padding-t">
							{{ this.monthCommentCount }}
						</div>
					</el-col>
					<el-col :span="4">
						<div class="text-center text-gray">
							待批改作业总数
							<el-tooltip
								class="item"
								effect="dark"
								content="所有待批改的作业总数量"
								placement="top"
							>
								<i class="el-icon-warning-outline" style="cursor: pointer"></i>
							</el-tooltip>
						</div>
						<div class="text-center padding-t">{{ this.unDisposedCount }}</div>
					</el-col>
					<el-col :span="4">
						<div class="text-center text-gray">
							已批改的作业总数
							<el-tooltip
								class="item"
								effect="dark"
								content="所有已批改的作业总数"
								placement="top"
							>
								<i class="el-icon-warning-outline" style="cursor: pointer"></i>
							</el-tooltip>
						</div>
						<div class="text-center padding-t">{{ this.doneCount }}</div>
					</el-col>
					<el-col :span="4">
						<div class="text-center text-gray">
							累计点评作业数量
							<el-tooltip
								class="item"
								effect="dark"
								content="我累计点评的作业总数，不统计作业类型为修改后提交的作业数量"
								placement="top"
							>
								<i class="el-icon-warning-outline" style="cursor: pointer"></i>
							</el-tooltip>
						</div>
						<div class="text-center padding-t">{{ this.commentCount }}</div>
					</el-col>
				</el-row>
				<div
					style="position: absolute; right: 10px; top: 10px; cursor: pointer"
				>
					<el-tooltip
						class="item"
						effect="dark"
						content="刷新统计数据"
						placement="top"
					>
						<i class="el-icon-refresh" @click="refreshCount"></i>
					</el-tooltip>
				</div>
			</div>

			<div class="padding-t-sm padding-b-sm">
				<span class="margin-l-sm fl-l margin-t-sm">
					<el-date-picker
						size="small"
						v-model="date1"
						type="daterange"
						value-format="yyyy-MM-dd"
						align="right"
						unlink-panels
						range-separator="至"
						start-placeholder="提交日期"
						end-placeholder="结束日期"
						:picker-options="pickerOptions"
					>
					</el-date-picker>
				</span>
				<span class="margin-l-sm fl-l margin-t-sm">
					<el-select
						v-model="byStatus"
						clearable
						placeholder="作业状态"
						size="small"
					>
						<el-option
							v-for="item in status"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</span>

				<!--                <span class="margin-l-sm fl-l margin-t-sm" >-->
				<!--                    <el-select v-model="byGrade" clearable placeholder="班型" size="small">-->
				<!--                        <el-option-->
				<!--                            v-for="item in course"-->
				<!--                            :key="item.id"-->
				<!--                            :label="item.name"-->
				<!--                            :value="item.name">-->
				<!--                        </el-option>-->
				<!--                    </el-select>-->
				<!--                </span>-->

				<div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
					<el-cascader
						:show-all-levels="false"
						:options="courseOptions"
						size="small"
						placeholder="班型"
						clearable
						@change="changeGrade"
					>
						<template slot-scope="{ node, data }">
							<span>{{ data.label }}</span>
							<span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
						</template>
					</el-cascader>
				</div>

				<span class="margin-l-sm fl-l margin-t-sm">
					<el-select
						v-model="byType"
						clearable
						placeholder="作业类型"
						size="small"
					>
						<el-option
							v-for="item in type"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</span>

				<span class="margin-l-sm fl-l margin-t-sm">
					<el-select
						v-model="excellent"
						clearable
						placeholder="是否优秀"
						size="small"
					>
						<el-option label="优秀" value="1"> </el-option>
					</el-select>
				</span>

				<span class="margin-l-sm fl-l margin-t-sm">
					<el-input
						v-model="teacherName"
						placeholder="点评老师"
						clearable
						size="small"
					></el-input>
				</span>

				<span class="margin-l-sm fl-l margin-t-sm">
					<el-input
						v-model="userName"
						placeholder="学生姓名"
						clearable
						size="small"
					></el-input>
				</span>

				<span class="margin-l-sm fl-l margin-t-sm">
					<el-input
						v-model="name"
						placeholder="用户昵称"
						clearable
						size="small"
					></el-input>
				</span>

				<span class="margin-l-sm fl-l margin-t-sm">
					<el-date-picker
						size="small"
						v-model="date2"
						type="daterange"
						align="right"
						value-format="yyyy-MM-dd"
						unlink-panels
						range-separator="至"
						start-placeholder="点评日期"
						end-placeholder="结束日期"
						:picker-options="pickerOptions"
					>
					</el-date-picker>
				</span>

				<span class="margin-l-sm fl-l margin-t-sm">
					<el-button
						type="primary"
						icon="el-icon-search"
						size="small"
						@click="search"
						>搜索</el-button
					>
				</span>

				<!--                <span class=" fl-r">-->
				<!--                    <router-link to="/excerpt/add" class="router-link" >-->
				<!--                        <el-button type="primary" size="small" >新增</el-button>-->
				<!--                    </router-link>-->
				<!--                </span>-->

				<div style="clear: both"></div>
			</div>

			<div class="table-content">
				<el-table
					:header-cell-style="{ padding: 0 }"
					:cell-class-name="rowItem"
					ref="singleTable"
					:data="tableData"
					highlight-current-row
					@current-change="handleCurrentChange"
					v-loading="loading"
					style="width: 100%; background: #fff"
				>
					<el-table-column label="ID" property="id" width="80">
					</el-table-column>
					<el-table-column property="title" label="作业标题"> </el-table-column>
					<el-table-column width="150" label="图片">
						<template slot-scope="scope">
							<div
								v-if="scope.row.pic != undefined && scope.row.pic.length > 0"
							>
								<el-image
									fit="cover "
									class="table-item-img"
									:src="scope.row.pic[0]"
									:preview-src-list="scope.row.pic"
								>
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</div>
						</template>
					</el-table-column>

					<el-table-column property="created_at" label="提交时间">
					</el-table-column>
					<el-table-column label="是否优秀" width="100">
						<template slot-scope="scope">
							<span>{{ scope.row.excellent == 1 ? '优秀' : '否' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="作业类型" width="100">
						<template slot-scope="scope">
							<span>{{ scope.row.type == 0 ? '首次提交' : '修改后提交' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="点评时间">
						<template slot-scope="scope">
							<span>{{ scope.row.comment_at }}</span>
						</template>
					</el-table-column>
					<el-table-column label="点评老师">
						<template slot-scope="scope">
							<span>{{ scope.row.teacher_name }}</span>
						</template>
					</el-table-column>
					<el-table-column property="index" label="用户昵称">
						<template slot-scope="scope">
							<span>{{ scope.row.user_name }}</span>
						</template>
					</el-table-column>
					<el-table-column property="address" label="状态">
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1" class="state-down"
								><i></i>待批改</span
							>
							<span v-if="scope.row.status == 3" class="state-up"
								><i></i>已批改</span
							>
						</template>
					</el-table-column>
					<el-table-column property="address" label="操作">
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">
								<span>
									<el-link
										type="primary"
										v-if="scope.row.status == 1"
										@click="comment(scope.row, scope.$index)"
										>点评</el-link
									>
								</span>
								-
							</span>
							<span>
								<el-link type="primary" @click="view(scope.row, scope.$index)"
									>查看点评</el-link
								>
							</span>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="table-batch">
				<span class="fl-r">
					<el-pagination
						small
						background
						:current-page="page"
						:page-sizes="[10, 20]"
						:page-size="pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						@size-change="setPageSize"
						@current-change="setPage"
						:total="total"
					>
					</el-pagination>
				</span>
				<div style="clear: both"></div>
			</div>
		</div>

		<el-dialog
			title="点评作业"
			append-to-body
			:visible.sync="dialogTableVisible"
			:close-on-click-modal="false"
			width="1040px"
			:fullscreen="true"
			:show-close="false"
		>
			<div
				class="fl-l"
				style="
					width: 48%;
					border: 1px solid #dcdfe6;
					height: 800px;
					padding: 5px;
					overflow: hidden;
				"
				@mousewheel="mousewheel"
			>
				<div class="selectPic">
					<p>{{ this.currentWorkIndex + 1 }}/{{ this.workCount }}</p>
					<p>
						<el-button
							@click="leftRotate"
							icon="el-icon-refresh-left"
							size="mini"
							>左旋转90°</el-button
						>
					</p>
					<p>
						<el-button
							@click="rightRotate"
							icon="el-icon-refresh-right"
							size="mini"
							>右旋转90°</el-button
						>
					</p>
					<p>
						<el-button @click="preWork" icon="el-icon-caret-left" size="mini"
							>上一张</el-button
						>
					</p>
					<p>
						<el-button @click="nextWork" icon="el-icon-caret-right" size="mini"
							>下一张</el-button
						>
					</p>
				</div>
				<imagePreview
					ref="imagePreview"
					:url="workImage"
					:size="this.workImageSize.toFixed(2)"
					:rotate="this.rotate"
				></imagePreview>
			</div>

			<div class="fl-l" style="width: 48%; padding-left: 20px">
				<el-form
					ref="form"
					:model="form"
					label-width="120px"
					label-position="top"
				>
					<!--                    <el-form-item label="老师评语："   >-->
					<!--                        <el-input type="textarea" rows="4" placeholder="输入评语..."  resize="none"  v-model="form.comment1"></el-input>-->
					<!--                    </el-form-item>-->

					<el-form-item label="错字纠正：">
						<el-input
							type="textarea"
							rows="4"
							placeholder="输入评语..."
							v-model="form.comment2"
							resize="none"
						></el-input>
					</el-form-item>

					<el-form-item label="句子修改：">
						<el-input
							type="textarea"
							rows="8"
							placeholder="输入评语..."
							v-model="form.comment3"
							resize="none"
						></el-input>
					</el-form-item>

					<!--                    <el-form-item label="好词好句积累：" >-->
					<!--                        <el-input type="textarea" rows="5" placeholder="输入评语..." v-model="form.comment4" resize="none"  ></el-input>-->
					<!--                    </el-form-item>-->

					<span style="margin-top: 15px; display: block">
						<cover
							ref="cover"
							@success="uploadSuccess"
							uploadPath="workComment"
						>
							<template v-slot:prompt> 点评图片 </template>
						</cover>
					</span>
					<el-checkbox
						:true-label="1"
						:false-label="0"
						v-model="form.excellent"
						style="margin-top: 15px"
						>优秀作业</el-checkbox
					>
				</el-form>
				<div style="margin-top: 10px">
					<recorder @on-success="onSuccess"></recorder>
				</div>
			</div>

			<div class="clear"></div>

			<div style="text-align: center; padding-top: 30px">
				<el-button type="primary" @click="submit" :loading="btnLoading"
					>点评</el-button
				>
				<el-button @click="closeDialog(1)">关闭</el-button>
			</div>
		</el-dialog>

		<el-dialog
			title="查看点评"
			append-to-body
			:visible.sync="viewDialog"
			width="820px"
		>
			<div style="padding: 20px">
				<div style="padding: 10px 0; line-height: 26px">
					<pre style="white-space: pre-line">{{ viewComment }}</pre>
				</div>
				<div style="padding: 10px 0" v-if="viewCommentPic != ''">
					附件图：<el-image :src="viewCommentPic"></el-image>
				</div>
				<div style="padding: 10px 0" v-if="viewCommentRecorder != ''">
					点评语音：<audio :src="viewCommentRecorder" controls></audio>
				</div>
			</div>

			<div style="text-align: center; padding-top: 18px">
				<el-button @click="closeDialog">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import Cover from '../../../components/cover'
import Recorder from '../../../components/recorder/index'
import imagePreview from '../../../components/imagePreview/index'

export default {
	name: 'List',
	components: { Recorder, Cover, imagePreview },
	data() {
		return {
			courseOptions: [],
			page: 1,
			pageSize: 10,
			total: 0,
			loading: false,
			countLoading: false,
			imageUrl: config.imageUrl,
			dialogTableVisible: false,
			viewDialog: false,
			workImage: '',
			workImages: [],
			currentWork: '',
			currentWorkIndex: 0,
			workCount: 0,
			btnLoading: false,
			viewComment: '',
			toDayPushCount: 0,
			toDayCommentCount: 0,
			monthCommentCount: 0,
			unDisposedCount: 0,
			doneCount: 0,
			commentCount: 0,
			viewCommentPic: '',
			viewCommentRecorder: '',
			form: {
				id: 0,
				comment: '',
				excellent: 0,
				comment1: '', //整体点评
				comment2: '', //错字纠正
				comment3: '', //句子修改
				comment4: '', //优美句子
				comment_pic: '',
				recorder: ''
			},
			status: [
				{
					value: 1,
					label: '待批改'
				},
				{
					value: 3,
					label: '已批改'
				}
			],
			type: [
				{
					value: 0,
					label: '首次提交'
				},
				{
					value: 1,
					label: '修改后提交'
				}
			],
			grade: [],
			course: [],
			byStatus: '',
			byGrade: '',
			byType: '',
			excellent: '',
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit('pick', [start, end])
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit('pick', [start, end])
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit('pick', [start, end])
						}
					}
				]
			},
			date1: '',
			date2: '',
			tableData: [],
			currentRow: null,
			workImageSize: 1,
			rotate: 0,
			teacherName: '',
			userName: '',
			name: ''
		}
	},
	methods: {
		changeGrade(val) {
			this.byGrade = val[1]
			console.log(val[1])
		},

		mousewheel(e) {
			if (e.deltaY < 0) {
				this.workImageSize += 0.1
				this.workImageSize = this.workImageSize > 3.0 ? 3.0 : this.workImageSize
			} else {
				this.workImageSize -= 0.1
				this.workImageSize = this.workImageSize < 0.1 ? 0.1 : this.workImageSize
			}
			e.preventDefault()
		},
		refreshCount() {
			this.getCount()
		},
		...mapActions('other', [
			'getWorkList',
			'commentWork',
			'getWorkCount',
			'isCommenting'
		]),
		...mapActions('common', ['getBaseData']),
		setCurrent(row) {
			this.$refs.singleTable.setCurrentRow(row)
		},
		handleCurrentChange(val) {
			this.currentRow = val
		},
		headeCellStyle({ row, rowIndex }) {
			return '{padding:0}'
		},
		rowItem() {
			return 'row-item'
		},
		comment(row, index) {
			this.currentWork = row
			this.currentWorkIndex = 0
			this.workCount = row.pic.length

			this.dialogTableVisible = true
			this.form.id = row.id
			this.form.comment = row.comment
			this.form.excellent = row.excellent
			this.workImage = row.pic[this.currentWorkIndex]
			this.workImages = [row.pic]

			this.workImageSize = 1.0
			this.rotate = 0
			this.form.comment_pic = ''

			this.form.comment1 = ''
			this.form.comment2 = ''
			this.form.comment3 = ''
			this.form.comment4 = ''

			console.log(this.$refs.cover)

			if (this.$refs.cover != undefined) {
				this.$refs.cover.$emit('reset')
			}

			if (this.$refs.imagePreview != undefined) {
				this.$refs.imagePreview.$emit('reset')
			}

			this.isCommenting({ id: row.id })
		},
		leftRotate() {
			this.rotate = this.rotate - 90 < 0 ? 0 : this.rotate - 90
		},
		rightRotate() {
			this.rotate = this.rotate + 90 > 360 ? 360 : this.rotate + 90
		},
		preWork() {
			this.currentWorkIndex =
				this.currentWorkIndex - 1 < 0 ? 0 : this.currentWorkIndex - 1
			this.workImage = this.currentWork.pic[this.currentWorkIndex]
			if (this.currentWorkIndex - 1 < 0) {
				this.$message.warning('已经是第一张了')
			}
		},
		nextWork() {
			this.currentWorkIndex =
				this.currentWorkIndex + 1 > this.workCount - 1
					? this.workCount - 1
					: this.currentWorkIndex + 1
			this.workImage = this.currentWork.pic[this.currentWorkIndex]

			if (this.currentWorkIndex + 1 > this.workCount - 1) {
				this.$message.warning('已经是最后一张了')
			}
		},
		view(row, index) {
			this.viewComment = row.comment
			if (row.comment_pic != '') {
				this.viewCommentPic = this.imageUrl + '' + row.comment_pic
			} else {
				this.viewCommentPic = ''
			}
			if (row.recorder != '') {
				this.viewCommentRecorder = this.imageUrl + '' + row.recorder
			} else {
				this.viewCommentRecorder = ''
			}

			this.viewDialog = true
		},
		uploadSuccess(path) {
			this.form.comment_pic = path
		},
		search() {
			this.tableData = []
			this.page = 1
			this.getList()
		},
		getList() {
			this.loading = true
			let data = {
				page: this.page,
				pageSize: this.pageSize,
				teacher_name: this.teacherName,
				type: this.byType,
				excellent: this.excellent,
				name: this.name
			}

			if (this.byGrade != '') data.title = this.byGrade

			if (this.byStatus != '') data.status = this.byStatus

			if (this.userName != '')
				data.title = this.byGrade + ' 【' + this.userName + '】'

			if (this.date1 != null && this.date1 != '') {
				data.created_start_at = this.date1[0]
				data.created_end_at = this.date1[1]
			}

			if (this.date2 != null && this.date2 != '') {
				data.comment_start_at = this.date2[0]
				data.comment_end_at = this.date2[1]
			}

			let array = []
			this.getWorkList(data).then(res => {
				this.loading = false
				res.data.list.forEach(item => {
					let picArray = []
					if (item.pic != undefined && item.pic.length > 0) {
						item.pic.forEach(pic => {
							picArray.push(this.imageUrl + pic)
						})
					}
					array.push({
						comment: item.comment,
						comment_at: item.comment_at,
						comment_pic: item.comment_pic,
						created_at: item.created_at,
						deleted_at: item.deleted_at,
						excellent: item.excellent,
						favour: item.favour,
						id: item.id,
						is_private: item.is_private,
						love: item.love,
						modify_log: item.modify_log,
						pic: picArray,
						recorder: item.recorder,
						share: item.share,
						status: item.status,
						teacher_id: item.teacher_id,
						teacher_name: item.teacher_name,
						teacher_name_avatar_url: item.teacher_name_avatar_url,
						title: item.title,
						type: item.type,
						updated_at: item.updated_at,
						user_id: item.user_id,
						user_name: item.user_name,
						user_name_avatar_url: item.user_name_avatar_url,
						view: item.view
					})
				})

				this.tableData = array
				this.total = res.data.total
			})
		},
		getCount() {
			this.countLoading = true
			this.getWorkCount().then(res => {
				this.toDayPushCount = res.data.to_day_push_count
				this.toDayCommentCount = res.data.to_day_comment_count
				this.monthCommentCount = res.data.month_comment_count
				this.unDisposedCount = res.data.un_disposed_count
				this.doneCount = res.data.done_count
				this.commentCount = res.data.comment_count
				this.countLoading = false
			})
		},
		setPageSize(pageSize) {
			this.pageSize = pageSize
			this.getList()
		},
		setPage(page) {
			this.page = page
			this.getList()
		},
		async submit() {
			this.btnLoading = true
			let comment = ''

			// if(this.form.comment1  != '')
			// {
			//     comment += '【老师评语】\n\r'+this.form.comment1
			// }

			if (this.form.comment2 != '') {
				comment += '\n\r【错字纠正】\n\r' + this.form.comment2
			}

			if (this.form.comment3 != '') {
				comment += '\n\r【句子修改】\n\r' + this.form.comment3
			}
			// if(this.form.comment4  != '')
			// {
			//     comment += '\n\r【好词好句积累】\n\r'+this.form.comment4
			// }

			if (comment == '') {
				this.$message.error('请输入评语...')
				this.btnLoading = false
				return
			}

			let data = {
				id: this.form.id,
				comment: comment,
				excellent: this.form.excellent,
				comment_pic: this.form.comment_pic,
				recorder: this.form.recorder
			}

			console.log(data)

			await this.commentWork(data).then(res => {
				if (res && res.ret == 0) {
					this.dialogTableVisible = false
					this.$message.success('点评成功')
					this.getList()
				}
			})
			this.btnLoading = false
		},
		closeDialog(isCancel) {
			this.dialogTableVisible = false
			this.viewDialog = false
			if (isCancel == 1) {
				this.isCommenting({ id: this.form.id, is_cancel: 1 })
			}
		},
		handelEndRecord(param) {
			console.log(param)
		},
		onSuccess(path) {
			this.form.recorder = path
		},
		mousedown(e) {
			console.log(e)
		}
	},
	mounted() {
		let arr = [
			'1年级',
			'2年级',
			'3年级',
			'4年级',
			'5年级',
			'6年级',
			'小升初',
			'7年级',
			'8年级',
			'9年级',
			'其他'
		]
		arr.forEach(item => {
			this.courseOptions.push({
				value: item,
				label: item,
				children: []
			})
		})
		console.log(this.courseOptions)
		this.getList()
		this.getCount()
		this.getBaseData().then(res => {
			this.course = res.data.course
			res.data.course.forEach(item => {
				if (item.is_work === 1) {
					this.courseOptions.forEach(i => {
						if (item.name.indexOf(i.label) != -1) {
							i.children.push({
								value: item.name,
								label: item.name
							})
						}
					})
				}
			})

			res.data.course.forEach(item => {
				if (item.is_work === 1) {
					if (
						item.name.indexOf('年级') == -1 &&
						item.name.indexOf('小升初') == -1
					) {
						this.courseOptions[10].children.push({
							value: item.name,
							label: item.name
						})
					}
				}
			})
		})
	}
}
</script>

<style>
.selectPic {
	position: relative;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40px;
	overflow: hidden;
}
.selectPic p {
	float: left;
	width: 20%;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
}
.selectPic p:first-child {
	cursor: default;
}
</style>
