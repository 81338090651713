<template>
    <div v-loading="detailLoading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >运营和营销</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/promotion' }">促销活动</el-breadcrumb-item>
                <el-breadcrumb-item>活动详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="course-detail-title" style="">
            <div style="padding:10px">
                <div class="fl-l" style="width: 150px;"><img width="150px" :src="pic" /></div>
                <div class="fl-l margin-l-sm">
                    <p class="title">{{detail.name}}</p>
                    <p class="price">￥ {{detail.amount}}</p>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="margin-sm">

            <el-tabs v-model="activeName" type="card" @tab-click="change" >
                <el-tab-pane label="已购用户" name="promotionUsers" path="promotionUsers"></el-tab-pane>
                <router-view  :classTotal="detail.total_class">
                </router-view>
            </el-tabs>
        </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "detail",
        data(){
            return {
                activeName: 'promotionUsers',
                pic:'',
                amount:0,
                name:'',
                imageUrl:config.imageUrl,
                id:'',
                detail:[],
                detailLoading:true
            };
        },
        methods: {
            ...mapActions('marketing',['getPromotionDetail']),
            change(tab, event) {
                let route = tab.$attrs.path
                console.log(route)
                this.$router.push('/course/detail/'+this.$route.params.id+'/'+route)
            },
            async getDetail(){
                let res = await this.getPromotionDetail(this.id)
                this.detail = res.data
                this.pic = this.imageUrl + '/' +res.data.pic
            }
        },
        async mounted() {
            this.detailLoading = true
            this.id = this.$route.params.id
            let arr = this.$route.path.split('/')
            let activeName = arr[arr.length-1]
            this.activeName = activeName

            await this.getDetail()
            this.detailLoading = false

        }
    }
</script>

<style>
    .course-detail-title{
        margin:10px;
        background: #fafafa;
        overflow: hidden;
    }
    .title{
        font-size: 18px;
        margin-top:5px;
    }
    .price{
        margin-top:45px;
        font-size: 16px;
    }
</style>