<template>
	<div>
		<div class="detail-title">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>课堂</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/course' }"
					>我的课堂</el-breadcrumb-item
				>
				<el-breadcrumb-item>编辑课程</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="detail-info" v-loading="detailLoading">
			<el-form
				ref="form"
				:model="form"
				:rules="rules"
				label-width="100px"
				size="mini"
			>
				<el-form-item
					label="名称："
					label-position="left"
					prop="name"
					class="input"
				>
					<el-input style="width: 200px" v-model="form.name"></el-input>
				</el-form-item>

				<el-form-item
					label="简介："
					label-position="left"
					prop="intro"
					class="input"
				>
					<el-input style="width: 200px" v-model="form.intro"></el-input>
				</el-form-item>

				<el-form-item label="封面：" label-position="left" prop="pic">
					<edit-cover
						:pic="imageUrl + form.pic"
						:uploadPath="uploadPath"
						@success="uploadSuccess"
					></edit-cover>
				</el-form-item>

				<el-form-item label="内容：" label-position="left" prop="content">
					<editor @onChange="onChange" :content="form.content"></editor>
				</el-form-item>

				<el-form-item label="包含服务：" label-position="left" class="input">
					<el-checkbox v-model="form.is_work">作业点评</el-checkbox>
					<el-checkbox v-model="form.pinkage">书本包邮</el-checkbox>
				</el-form-item>
				<el-form-item label="适合年级：" label-position="left" class="">
					<el-cascader
						style="width: 200px"
						v-model="selectGrade"
						:options="options"
						:props="{ expandTrigger: 'hover', multiple: true }"
						@change="handleChange"
					></el-cascader>
				</el-form-item>

				<el-form-item label="课程分类：" label-position="left" class="input">
					<el-select
						v-model="form.categoryId"
						clearable
						placeholder="选择会员卡"
						size="small"
					>
						<el-option label="全部分类" :value="0"> </el-option>
						<el-option
							v-for="item in categorys"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="更新通知：" label-position="left" class="input">
					<el-switch
						v-model="form.notification"
						:active-value="1"
						:inactive-value="0"
						@change="setPayStatus"
					>
					</el-switch>
					<span style="margin-left: 5px; font-size: 12px">{{
						form.notification === 0 ? '不开启' : '开启'
					}}</span>

					<el-tooltip
						class="item"
						effect="dark"
						content="开启后课程更新会推送微信消息给已报课程的学员"
						placement="top"
					>
						<span
							class="margin-l-sm el-icon-question"
							style="color: #909399; cursor: pointer"
						>
						</span>
					</el-tooltip>
				</el-form-item>

				<el-form-item label="获取方式：" label-position="left" class="input">
					<el-switch
						v-model="form.pay_status"
						:active-value="2"
						:inactive-value="1"
						@change="setPayStatus"
					>
					</el-switch>
					<span style="margin-left: 5px; font-size: 12px">{{
						payStatusTxt
					}}</span>
				</el-form-item>

				<div v-if="form.pay_status === 2">
					<!--                    <el-form-item label="会员折扣：" label-position="left" class="input" >-->
					<!--                        <el-select v-model="form.vipDiscount" clearable placeholder="选择会员卡" size="small">-->
					<!--                            <el-option-->
					<!--                                    label="不开启会员折扣"-->
					<!--                                    :value="0">-->
					<!--                            </el-option>-->
					<!--                            <el-option-->
					<!--                                    v-for="item in discountVip"-->
					<!--                                    :key="item.id"-->
					<!--                                    :label="item.name"-->
					<!--                                    :value="item.id">-->
					<!--                            </el-option>-->
					<!--                        </el-select>-->
					<!--                    </el-form-item>-->

					<el-form-item label="会员免费：" label-position="left" class="input">
						<el-select
							v-model="form.vipFree"
							clearable
							placeholder="选择会员卡"
							size="small"
						>
							<el-option label="不开启会员免费" :value="0"> </el-option>
							<el-option
								v-for="item in freeVip"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item
						label="价格："
						label-position="left"
						class="input"
						prop="amount"
					>
						<el-input
							style="width: 200px"
							v-model="form.amount"
							clearable
						></el-input>
					</el-form-item>

					<el-form-item label="划线价格：" label-position="left" class="input">
						<el-input
							style="width: 200px"
							v-model="form.real_amount"
							clearable
						></el-input>
					</el-form-item>

					<el-form-item label="有效期：" label-position="left" class="input">
						<el-input
							style="width: 200px"
							v-model.number="form.indate"
							clearable
						></el-input>
						（天）
					</el-form-item>
				</div>

				<el-form-item label="总课时：" label-position="left" class="input">
					<el-input
						style="width: 200px"
						v-model.number="form.total_class"
						clearable
					></el-input>
					（节）
				</el-form-item>

				<el-form-item label="排序：" label-position="left" class="input">
					<el-input
						style="width: 200px"
						v-model.number="form.sort"
						clearable
					></el-input>
				</el-form-item>

				<el-form-item label="状态：" label-position="left" class="input">
					<el-switch v-model="form.status" @change="setStatus"> </el-switch>
					<span style="margin-left: 5px; font-size: 12px">{{ statusTxt }}</span>
				</el-form-item>

				<el-form-item label="定时上架：" label-position="left" class="input">
					<el-switch v-model="form.timing"> </el-switch>
					<span style="margin-left: 5px; font-size: 12px">{{
						form.timing ? '是' : '否'
					}}</span>
				</el-form-item>

				<el-form-item>
					<el-button
						size="small"
						type="primary"
						:loading="loading"
						@click="onSubmit('form')"
						>保存</el-button
					>
					<router-link to="/course" class="router-link"
						><el-button style="margin-left: 10px" size="small"
							>取消</el-button
						></router-link
					>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import cover from '../../../components/cover'
import editor from '../../../components/editor'
import { mapActions } from 'vuex'
import EditCover from '../../../components/editCover'
export default {
	name: 'Add',
	components: { EditCover, editor },
	data() {
		return {
			imageUrl: config.imageUrl,
			detailLoading: true,
			selectGrade: [],
			courseId: 0,
			statusTxt: '上架',
			payStatusTxt: '付费',
			loading: false,
			inputVisible: false,
			inputValue: '',
			uploadPath: 'course',
			discountVip: [],
			categorys: [],
			freeVip: [],
			options: [],
			form: {
				notification: 0,
				id: 0,
				categoryId: 0,
				vipDiscount: 0,
				vipFree: 0,
				status: true,
				pay_status: 2,
				intro: '',
				pic: '',
				name: '',
				content: '',
				amount: 0,
				is_work: false,
				total_class: 0,
				real_amount: 0,
				indate: 365,
				gradeId: [],
				pinkage: false,
				sort: 0,
				timing: false
			},
			rules: {
				name: [
					{ required: true, message: '请输入课程名称', trigger: 'blur' },
					{ min: 2, max: 24, message: '长度在 2 到 24 个字符', trigger: 'blur' }
				],
				intro: [{ required: true, message: '请输入课程简介', trigger: 'blur' }],
				pic: [
					{
						type: 'string',
						required: true,
						message: '请选择图片封面',
						trigger: 'change'
					}
				],
				content: [
					{
						type: 'string',
						required: true,
						message: '请输入课程内容',
						trigger: 'blur'
					}
				],
				amount: [{ required: true, message: '请输入价格', trigger: 'blur' }]
			}
		}
	},
	methods: {
		...mapActions('course', ['createCourse', 'getCourseDetail']),
		...mapActions('marketing', ['vipCardList']),
		...mapActions('common', ['getBaseData']),
		uploadSuccess(path) {
			this.form.pic = path
		},
		handleChange(value) {
			let arr = []
			console.log(value)
			value.forEach(item => {
				arr.push(item[1])
			})

			this.form.gradeId = arr
		},
		async onSubmit(formName) {
			if (!this.validateForm(formName)) {
				this.$message.error('请填写必填选项！')
				return false
			}

			let _this = this
			this.loading = true

			console.log(this.form)

			this.createCourse(this.form).then(res => {
				this.loading = false

				if (res.ret == 0) {
					this.$message.success('修改成功！')
					this.$router.push('/course')
				}
			})
		},
		validateForm(formName) {
			let validate = false
			this.$refs[formName].validate(valid => {
				if (valid) {
					validate = true
					return true
				} else {
					validate = false
					return false
				}
			})
			return validate
		},
		setStatus(status) {
			console.log(status)
			if (this.form.status) {
				this.statusTxt = '上架'
			} else {
				this.statusTxt = '下架'
			}
			this.form.status = status
		},
		setPayStatus() {
			if (this.form.pay_status === 2) {
				this.payStatusTxt = '付费'
			} else {
				this.payStatusTxt = '免费'
				this.form.amount = 0
				this.form.real_amount = 0
				this.form.indate = 0
			}
		},
		handleClose(tag) {
			this.form.tags.splice(this.form.tags.indexOf(tag), 1)
		},

		showInput() {
			this.inputVisible = true
			this.$nextTick(() => {
				this.$refs.saveTagInput.$refs.input.focus()
			})
		},
		handleInputConfirm() {
			let inputValue = this.inputValue
			if (inputValue) {
				this.form.tags.push(inputValue)
			}
			this.inputVisible = false
			this.inputValue = ''
		},
		onChange(content) {
			this.form.content = content
		}
	},
	async mounted() {
		this.courseId = this.$route.params.id
		this.getCourseDetail(this.courseId).then(res => {
			this.form.id = this.courseId
			this.form.categoryId = res.data.category_id
			this.form.vipDiscount = res.data.vip_discount
			this.form.vipFree = res.data.vip_free
			this.form.status = res.data.status === 1 ? true : false
			this.form.pay_status = res.data.pay_status
			this.form.intro = res.data.intro
			this.form.pic = res.data.pic
			this.form.name = res.data.name
			this.form.content = res.data.content
			this.form.amount = res.data.amount
			this.form.is_work = res.data.is_work === 1 ? true : false
			this.form.total_class = res.data.total_class
			this.form.gradeId = res.data.grade_id
			this.form.indate = res.data.indate
			this.form.real_amount = res.data.real_amount
			this.form.notification = res.data.notification
			this.form.sort = res.data.sort
			this.form.pinkage = res.data.pinkage === 1 ? true : false
			this.form.timing = res.data.timing === 1 ? true : false
			console.log(this.form)
		})

		this.vipCardList({ status: 1 }).then(res => {
			if (res.ret === 0) {
				res.data.forEach(item => {
					if (item.type === 1) {
						this.discountVip.push(item)
					} else {
						this.freeVip.push(item)
					}
				})
			}
		})

		let levels = []
		let grades = []
		let subjects = []
		let options = []
		await this.getBaseData().then(res => {
			levels = res.data.level
			grades = res.data.grades
			subjects = res.data.subject
			this.categorys = res.data.category
		})

		console.log(this.form.gradeId)

		grades.forEach(grade => {
			this.form.gradeId.forEach(item => {
				if (grade.id === Number(item)) {
					console.log('--d-d-d-d-')
					this.selectGrade.push([grade.level_id, grade.id])
				}
			})
		})
		console.log('-------------------')
		console.log(this.selectGrade)

		levels.forEach(level => {
			let children = []
			grades.forEach(grade => {
				if (grade.level_id == level.id) {
					children.push({
						value: grade.id,
						label: grade.name
					})
				}
			})
			options.push({
				value: level.id,
				label: level.name,
				children: children
			})
		})

		this.options = options

		this.detailLoading = false
	}
}
</script>

<style>
.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
</style>
