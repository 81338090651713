<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >商城管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/goods' }">商品列表</el-breadcrumb-item>
                <el-breadcrumb-item>新增商品</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini" >


                <el-form-item label="名称：" label-position="left" prop="name" >
                    <el-input  style="width: 200px" v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="图片：" label-position="left" prop="pic" >
                    <cover @success="uploadSuccess" :uploadPath="uploadPath" ></cover>
                </el-form-item>

                <el-form-item label="详情：" label-position="left" prop="content" >
                    <editor @onChange="onChange" :content="form.content"></editor>
                </el-form-item>

                <el-form-item label="类型：" label-position="left" class="input" >
                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="价格：" label-position="left" class="input" prop="price">
                    <el-input style="width: 200px"  v-model="form.price"></el-input>（元/积分）
                </el-form-item>

                <el-form-item label="最大购买数量：" label-position="left" class="input">
                    <el-input style="width: 200px"  v-model="form.maxCount"></el-input>（0为无限制）
                </el-form-item>

                <el-form-item label="是否需要地址：" label-position="left" class="input" >
                    <el-switch v-model="form.isAddress" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{form.isAddress === 1 ? '是' : '否'}}</span>
                </el-form-item>


                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/goods" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import Cover from "../../../components/cover";
    import Editor from "../../../components/editor";
    export default {
        name: "template-add",
        components: {Editor, Cover},
        data() {
            return {
                statusTxt:'上架',
                loading:false,
                uploadPath:'store',
                form: {
                    name:'',
                    type: 1,
                    status:true,
                    pic:'',
                    price:0,
                    content:'',
                    maxCount:0,
                    isAddress:1
                },
                options: [
                    {
                        value: 1,
                        label: '实物商品'
                    },
                    {
                        value: 2,
                        label: '积分商品'
                    },
                ],
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                    ],
                    pic: [
                        { type: 'string', required: true, message: '请选择图片封面', trigger: 'change' }
                    ],
                    content: [
                        { type: 'string', required: true, message: '请输入课程内容', trigger: 'blur' }
                    ],
                    price: [
                        {  required: true, message: '请输入价格/积分', trigger: 'blur' },
                    ],
                },

            }
        },
        methods: {
            ...mapActions('store',['addGoods']),
            uploadSuccess(path){
                this.form.pic = path
            },
            onChange(content){
                this.form.content = content
            },
            async onSubmit() {
                let _this = this
                this.loading = true
                console.log(this.form)

                if(!this.validateForm('form'))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }

                await this.addGoods(this.form).then( res => {
                    if(res.ret == 0){
                        _this.$message.success('新增成功！')
                        _this.$router.push('/goods')
                    }
                })
                this.loading = false
            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
        }
    }
</script>

<style>

</style>
