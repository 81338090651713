import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    addSpecialColumn({commit},data){
        return (new Api()).getList(config.addSpecialColumn,data)
    },
    getSpecialColumnList({commit},data){
        return (new Api()).getList(config.getSpecialColumnList,data)
    },
    getSpecialColumnDetail({commit},id){
        return (new Api()).getList(config.getSpecialColumnDetail,{id:id})
    },
    setSpecialColumnStatus({commit},data){
        return (new Api()).setStatus('SpecialColumnModel',data.id,data.status)
    },
    delSpecialColumn({commit},id){
        let data = {model:'SpecialColumn',id:id}
        return (new Api()).delete(data)
    },
    addSpecialColumnContent({commit},data){
        return (new Api()).getList(config.addSpecialColumnContent,data)
    },
    getSpecialColumnContentList({commit},data){
        return (new Api()).getList(config.getSpecialColumnContentList,data)
    },
    setSort({commit},data){
        return (new Api()).add(config.setSort,data)
    },
    setTheStatus({commit},data){
        return (new Api()).setStatus(data.model,data.id,data.status)
    },
    getSpecialColumnContentDetail({commit},id){
        return (new Api()).getList(config.getSpecialColumnContentDetail,{id:id})
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
