<template>
    <div style="padding:10px">

        <el-row :gutter="10">
            <el-col :span="12">
                <el-card class="box-card">
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="订单号">
                            <el-input v-model="form.orderNumber" size="mini" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="商品名称">
                            <el-input v-model="form.name" size="mini" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="物流公司">
                            <el-input v-model="form.company" size="mini" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="物流单号">
                            <el-input v-model="form.companyNumber" size="mini" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="金额">
                            <el-input v-model="form.price" size="mini" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="图片">
                            <el-input v-model="form.pic" size="mini" clearable></el-input>
                        </el-form-item>
                        <el-form-item
                                v-for="(domain, index) in form.domains"
                                :label="'物流信息' + index"
                                :key="domain.key"
                                :prop="'domains.' + index + '.value'"

                        >
                            <el-input v-model="domain.content" size="mini" clearable></el-input>
                            <el-input v-model="domain.timestamp" size="mini" clearable></el-input>
                            <el-button @click.prevent="removeDomain(domain)" size="mini">删除</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="addDomain" size="mini">新增物流信息</el-button>
                        </el-form-item>
                    </el-form>

                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card">
                    <div style="display: flex;justify-items: center">
                        <el-image style="width: 130px;height: 80px" fit="cover" :src="form.pic"></el-image>
                        <div style="margin-left: 10px;padding: 10px 0">
                            <div>{{form.name}}</div>
                            <div style="color: #f00;margin-top:10px"><span>￥</span>{{form.price}}.00</div>
                        </div>
                    </div>
                    <el-divider ></el-divider>
                    <div style="display: flex;justify-items: center;margin-bottom: 10px;font-size: 12px"><span>优惠金额：<span style="color: #f00;"><span>￥</span>0.00</span></span></div>
                    <div style="display: flex;justify-items: center;margin-bottom: 10px;font-size: 12px"><span>应付金额：<span style="color: #f00;"><span>￥</span>{{form.price}}.00</span></span></div>
                    <div style="display: flex;justify-items: center;margin-bottom: 10px;font-size: 12px"><span>实付金额：<span style="color: #f00;"><span>￥</span>{{form.price}}.00</span></span></div>
                    <el-divider ></el-divider>
                    <div style="margin:10px 0">
                        <div><el-tag type="success" size="mini">订单</el-tag><span style="margin-left:10px;font-size: 13px">订单号<el-divider direction="vertical"></el-divider>{{form.orderNumber}}</span></div>
                    </div>
                    <div style="margin:10px 0">
                        <div><el-tag type="success" size="mini">物流</el-tag><span style="margin-left:10px;font-size: 13px">{{form.company}} <el-divider direction="vertical"></el-divider> {{form.companyNumber}}</span></div>
                        <div style="margin-top:20px">

                            <el-timeline>
                                <el-timeline-item
                                        style="line-height: 22px;font-size: 13px"
                                        v-for="(activity, index) in form.domains"
                                        :key="index"
                                        :icon="activity.icon"
                                        :type="activity.type"
                                        :color="(index === 0) ? '#0bbd87' : ''"
                                        :size="activity.size"
                                        :timestamp="activity.timestamp">
                                    {{activity.content}}
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "index",
        data(){
            return {

                form:{
                    domains: [{
                        content: '已经签收',
                        timestamp:'2022年5月24日 下午5:26:22',
                        color:''
                    }],
                    orderNumber:'PAY-NEW202273181c3367d9149',
                    name:'40篇记事美文批注',
                    company:'中通快递',
                    companyNumber:'75881592903397',
                    price:'1998',
                    pic:'https://resource.xxyw100.com/check/1647917768762.png',
                },
            }
        },
        methods:{
            addDomain() {
                this.form.domains.push({
                    value: '',
                    key: Date.now()
                });
            },
            removeDomain(item) {
                var index = this.form.domains.indexOf(item)
                if (index !== -1) {
                    this.form.domains.splice(index, 1)
                }
            },
        },
        mounted() {
            $(document).ready(function(){
                console.log('jquery...')
            });
            // this.getInfo()
        }
    }
</script>

<style >
    .el-divider--horizontal{
        margin:10px 0
    }
</style>