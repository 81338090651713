export const config = {
  baseUrl: process.env.VUE_APP_API,
  imageUrl: process.env.VUE_APP_IMAGE_URL,
  downloadUrl: process.env.VUE_APP_DOWNLOAD_URL,
  serviceUrl: process.env.VUE_APP_SERVICE_URL,
  login: "/v1/login",
  setStatus: "/v1/admin/setStatus",
  delete: "/v1/admin/delete",
  importTask: "/v1/admin/import",
  getSlideShowList: "/v1/admin/baseSetting/slideShow/list",
  delSlideShow: "/v1/admin/baseSetting/slideShow/del",
  addSlideShow: "/v1/admin/baseSetting/slideShow/add",
  getOssInfo: "/v1/oss/getUploadInfo",
  getVideoUploadAccess: "/v1/aliyun/getVideoUploadAccess",
  makeQrCode: "/v1/admin/makeQrCode",
  getBaseData: "/v1/admin/getBaseData",
  getExcerptList: "/v1/admin/content/excerpt/list",
  getExcerptDetail: "/v1/admin/content/excerpt/detail",
  delExcerpt: "/v1/admin/content/excerpt/del",
  editExcerpt: "/v1/admin/content/excerpt/edit",
  addExcerpt: "/v1/admin/content/excerpt/add",
  getFileList: "/v1/admin/resource/file/list",
  delFile: "/v1/admin/resource/file/del",
  addFile: "/v1/admin/resource/file/add",
  editFile: "/v1/admin/resource/file/edit",
  getFileDownloadList: "/v1/admin/content/fileDownload/list",
  getFileDownloadDetail: "/v1/admin/content/fileDownload/detail",
  delFileDownload: "/v1/admin/content/fileDownload/del",
  addFileDownload: "/v1/admin/content/fileDownload/add",
  editFileDownload: "/v1/admin/content/fileDownload/edit",
  getWorkList: "/v1/admin/other/work/list",
  getWorkCount: "/v1/admin/other/work/count",
  commentWork: "/v1/admin/other/work/comment",
  push: "/v1/admin/push",
  getUserInfo: "/v1/admin/user/getInfo",
  getUserList: "/v1/admin/user/list",
  setUserBan: "/v1/admin/user/setBan",
  getUserSocialList: "/v1/admin/user/social",
  getUserSocialDetail: "/v1/admin/user/social/detail",
  getUserSocialCommentList: "/v1/admin/user/social/comment",
  setUserCredit: "/v1/admin/user/setCredit",
  setUserRole: "/v1/admin/user/setRole",
  getVideoList: "/v1/admin/resource/video/list",
  getCourseList: "/v1/admin/classroom/course/list",
  getCourseDetail: "/v1/admin/classroom/course/detail",
  courseList: "/v1/admin/classroom/course/getList",
  bindCourse: "/v1/admin/user/bindCourse",
  isCommenting: "/v1/admin/other/work/commenting",
  addCourse: "/v1/admin/classroom/course/add",
  delCourse: "/v1/admin/classroom/course/del",
  addChapter: "/v1/admin/classroom/course/addChapter",
  getChapter: "/v1/admin/classroom/course/getChapter",
  getChapterList: "/v1/admin/classroom/course/getChapterList",
  addClass: "/v1/admin/classroom/course/addClass",
  getClassDetail: "/v1/admin/classroom/course/getClassDetail",
  setSort: "/v1/admin/classroom/course/setSort",
  createDiscount: "/v1/admin/marketing/discount/add",
  discountList: "/v1/admin/marketing/discount/list",
  createdVipCard: "/v1/admin/marketing/vipCard/add",
  vipCardList: "/v1/admin/marketing/vipCard/list",
  delVipCard: "/v1/admin/marketing/vipCard/del",
  vipCardDetail: "/v1/admin/marketing/vipCard/detail",
  getCourseStudent: "/v1/admin/classroom/course/student",
  getCourseFile: "/v1/admin/classroom/course/file",
  addCourseFile: "/v1/admin/classroom/course/addFile",
  getUserStudyLog: "/v1/admin/classroom/course/userStudyLog",
  donateVip: "/v1/admin/user/donateVip",
  getUserVipLog: "/v1/admin/user/vipLog",
  addCourseStudent: "/v1/admin/classroom/course/addUser",
  sendNotify: "/v1/admin/classroom/course/sendNotify",
  closeUserVip: "/v1/admin/user/closeVip",
  getGoodsList: "/v1/admin/store/goods/list",
  addGoods: "/v1/admin/store/goods/add",
  getStoreDetail: "/v1/admin/store/goods/detail",
  getOrderList: "/v1/admin/store/order/list",
  getOrderDetail: "/v1/admin/store/order/detail",
  createdSalesOrder: "/v1/admin/store/order/createSales",
  getSalesList: "/v1/admin/salesManage/sales/list",
  addSales: "/v1/admin/salesManage/sales/add",
  addSalesWx: "/v1/admin/salesManage/sales/addWeiXin",
  getStatistics: "/v1/admin/salesManage/sales/statistics",
  handleSales: "/v1/admin/salesManage/sales/handle",
  checkSales: "/v1/admin/salesManage/sales/check",
  manageStatistics: "/v1/admin/salesManage/sales/manageStatistics",
  exportSales: "/v1/admin/salesManage/sales/export",
  getSalesDetail: "/v1/admin/salesManage/sales/detail",
  getPromotionList: "/v1/admin/marketing/promotion/list",
  addPromotion: "/v1/admin/marketing/promotion/add",
  getPromotionDetail: "/v1/admin/marketing/promotion/detail",
  getPromotionUsers: "/v1/admin/marketing/promotion/users",
  addGroupBuying: "/v1/admin/marketing/groupBuying/add",
  getGroupBuyingList: "/v1/admin/marketing/groupBuying/list",
  getGroupBuyingDetail: "/v1/admin/marketing/groupBuying/detail",
  setWorkNumber: "/v1/admin/classroom/course/setWorkNumber",
  getExpressageList: "/v1/admin/salesManage/sales/expressage",
  getTeacherCourseList: "/v1/admin/teacher/course/list",
  addTeacherCourse: "/v1/admin/teacher/course/add",
  getTeacherCourseDetail: "/v1/admin/teacher/course/detail",
  addTeacherCourseChapter: "/v1/admin/teacher/chapter/add",
  getTeacherCourseChapter: "/v1/admin/teacher/chapter/getChapter",
  setTeacherSort: "/v1/admin/teacher/setSort",
  getTeacherChapterList: "/v1/admin/teacher/chapter/getChapterList",
  addTeacherCourseClass: "/v1/admin/teacher/class/add",
  getTeacherCourseClassDetail: "/v1/admin/teacher/class/detail",
  addTeacherCourseFile: "/v1/admin/teacher/file/add",
  getTeacherCourseFileList: "/v1/admin/teacher/file/list",
  getWxCountDetail: "/v1/admin/salesManage/sales/weixinCount",
  getSalesUserCourseDetail: "/v1/admin/salesManage/sales/userCourseDetail",
  openSalesUserCourse: "/v1/admin/salesManage/sales/openCourse",
  getStatisticsDetail: "/v1/admin/salesManage/sales/statisticsDetail",
  getStatisticsChart: "/v1/admin/salesManage/sales/statisticsChart",
  salesChangeUser: "/v1/admin/salesManage/sales/changeSalesUser",
  getPowerList: "/v1/admin/salesManage/coursePermission/list",
  getUserCourseList: "/v1/admin/salesManage/coursePermission/userCourseList",
  userCheckCourseList:
    "/v1/admin/salesManage/coursePermission/userCheckCourseList",
  userCourseOperation: "/v1/admin/salesManage/coursePermission/apply",
  userApplyDeleted: "/v1/admin/salesManage/coursePermission/delete",
  getPowerget: "/v1/admin/salesManage/coursePermission/get",
  setPowerApprove: "/v1/admin/salesManage/coursePermission/approve",
  setPowerReject: "/v1/admin/salesManage/coursePermission/reject",
  getPowerDetail: "/v1/admin/salesManage/coursePermission/detail",
  //专栏
  addSpecialColumn: "/v1/admin/classroom/specialColumn/add",
  addSpecialColumnContent: "/v1/admin/classroom/specialColumnContent/add",
  getSpecialColumnContentList: "/v1/admin/classroom/specialColumnContent/list",
  getSpecialColumnList: "/v1/admin/classroom/specialColumn/list",
  getSpecialColumnDetail: "/v1/admin/classroom/specialColumn/detail",
  getSpecialColumnContentDetail:
    "/v1/admin/classroom/specialColumnContent/detail",
  getStudentList: "/v1/admin/classManage/class/userList",
  getMomentList: "/v1/admin/user/social",
  getClassList: "/v1/admin/classManage/class/list",
  deleteClassUser: "/v1/admin/classManage/class/delUser",
  addClassRoom: "/v1/admin/classManage/class/add",
  addClassUser: "/v1/admin/classManage/class/addUser",
  getChannelList: "/v1/admin/chat/getChatList",
  getChannelDetail: "/v1/admin/chat/getChatMessageList",
  getPunchList: "/v1/admin/classroom/checkCourse/getList",
  getPunchPlan: "/v1/admin/classroom/checkCourse/getPlan",
  getPlanDateData: "/v1/admin/classroom/checkCourse/getPlanClass",
  addPlanDate: "/v1/admin/classroom/checkCourse/addPlan",
  addPunch: "/v1/admin/classroom/checkCourse/add",
  addCheck: "/v1/admin/classroom/checkCourse/addPlanClass",
  getCheckStudent: "/v1/admin/classroom/checkCourse/getUserList",
  addCheckStudent: "/v1/admin/classroom/checkCourse/addUser",
  setCheckSort: "/v1/admin/setSort",
  getCheckDetail: "/v1/admin/classroom/checkCourse/detail",
  getPunchDetail: "/v1/admin/classroom/checkCourse/planClassDetail",
  addClassNotification: "/v1/admin/classroom/course/sendNotify",

  // 题库
  getQuestionBank: "/v1/admin/resource/volume/questionBank/lists",
  getQuestionBankDetail: "/v1/admin/resource/volume/questionBank/detail",
  delQuestionBank: "/v1/admin/resource/volume/questionBank/del",
  exportQuestionBank: "/v1/admin/resource/volume/questionBank/export",
  // importQuestionBank: '/v1/admin/resource/volume/questionBank/import',
  addQuestionBank: "/v1/admin/resource/volume/questionBank/add",

  exportUsers: "/v1/admin/classroom/checkCourse/exportUsers",

  transferList: "/v1/admin/transfer/list",
  transferDetail: "/v1/admin/transfer/detail",
};
