<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>课堂</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/checkCourse' }">打卡课程</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form
                :rules="rules"
                ref="form"
                :model="form"
                style="padding: 48px 72px"
                label-position="right"
                label-width="auto"
        >
            <el-form-item label="课程名称: " prop="name">
                <el-input
                        v-model="form.name"
                        style="width: 240px"
                        autofocus
                        clearable
                />
            </el-form-item>

            <el-form-item label="类型" prop="type">
                <el-radio-group v-model="form.type" size="mini">
                    <el-radio-button :label="1">视频</el-radio-button>
                    <el-radio-button :label="2">图文</el-radio-button>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input v-model="form.sort" style="width: 240px" />
            </el-form-item>

            <template v-if="form.type === 1">
                <el-form-item label="选择视频：" prop="video_id">
                    <selectVideo @onSelect="onSelect" @onDelete="onDelete"></selectVideo>
                </el-form-item>

                <el-form-item label="视频封面：" prop="pic">
                    <el-image :src="form.pic" class="videoImage" v-if="form.pic" />
                </el-form-item>
            </template>

            <el-form-item label="状态: " prop="status">
                <el-switch
                        :active-value="1"
                        :inactive-value="0"
                        v-model="form.status"
                        style="width: 240px"
                />
            </el-form-item>

            <template v-if="form.type === 2">
                <el-form-item label="课程内容" prop="content">
                    <editor :content="form.content" @onChange="onChange" />
                </el-form-item>
            </template>

            <el-form-item>
                <el-button size="mini" @click="$router.back()">取消</el-button>
                <el-button size="mini" @click="submit" type="primary">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import selectVideo from '@/components/selectVideo'
    import editor from '@/components/editor'
    import { config } from '@/js/config'

    import { mapActions } from 'vuex'

    export default {
        data: () => ({
            loading: false,
            config,

            rules: {
                name: [{ required: true }],
                type: [{ required: true }],
                status: [{ required: true }],
                pic: [{ required: true }],
                notification: [{ required: true }],
                amount: [{ required: true }],
                indate: [{ required: true }],
                content: [{ required: true }],
                sort: [{ required: true }],
                video_id: [{ required: true }]
            },

            options: [],
            selectGrade: [],
            form: {
                name: '',
                pic: '',
                status: 1,
                check_course_plan_id: '',
                size: '',
                sort: 0,
                duration: '',
                video_id: '',
                type: 1,
                content: ''
            }
        }),

        components: { selectVideo, editor },

        methods: {
            ...mapActions('punch', ['addPunch', 'addCheck', 'getPunchDetail']),

            onChange(content) {
                this.form.content = content
            },
            submit() {
                this.$refs.form.validate(async valid => {
                    if (!valid) return
                    else {
                        this.loading = true
                        if (this.form.type === 2) {
                            delete this.form.size
                            delete this.form.duration
                            delete this.form.video_id
                        }

                        const { id } = this.$route.query

                        const params = { ...this.form }

                        if (id) params.id = id

                        const result = await this.addCheck(params)

                        this.loading = false

                        this.$message.success('添加成功')

                        this.$router.back()
                    }
                })
            },

            onSelect({ size, pic, id, duration }) {
                this.form.size = size
                this.form.pic = pic
                this.form.video_id = id
                this.form.duration = duration
            },

            uploadSuccess(path) {
                this.form.pic = path
            },

            onDelete() {
                this.form.size = ''
                this.form.pic = ''
                this.form.video_id = ''
                this.form.duration = ''
            }
        },

        async created() {
            let planId = this.$route.params.planId
            let checkCourseId = this.$route.params.checkCourseId
            this.form.check_course_plan_id = planId
            if (this.$route.query.id && this.$route.query.type !== 'new') {
                this.loading = true

                const { data } = await this.getPunchDetail({
                    id: planId
                })

                this.form = data

                this.loading = false
            }
        }
    }
</script>
