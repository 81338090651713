<template>
    <div>
        <div class="main-title">商品列表</div>
        <div class="content-list-page page-content">

            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">轮播图大小为：960*720</li>
                        <li data-v-69874ad0="">排序值越高，在小程序首页显示越靠前</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-input v-model.trim="name" placeholder="请输入名称"></el-input>
                </span>
 <span class="margin-l-sm fl-l">
                    <el-select v-model="status" clearable size="mini"  placeholder="请商品状态选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </span>
                <span class="margin-l-sm fl-l" >
                    <el-date-picker
                            size="small"
                            v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/promotion/add" class="router-link" v-if="$store.state.user.info.role === 1">
                        <el-button type="primary" size="small" >新建活动</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            label="图片"
                            width="200">
                        <template slot-scope="scope">
                            <el-image class="table-item-img " :src="imageUrl+scope.row.pic" :preview-src-list="[imageUrl+scope.row.pic]">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="名称">
                        <template slot-scope="scope">
                            <span>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="活动类型" >
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 1">组合购</span>
                            <span v-if="scope.row.type === 2">团购</span>
                            <span v-if="scope.row.type === 3">整点秒杀</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            >
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="活动时间" >
                        <template slot-scope="scope">
                            <span>{{scope.row.start_at}} <br /> {{scope.row.end_at}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="价格" >
                        <template slot-scope="scope">
                            <span>￥ {{scope.row.amount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="活动限制人数" >
                        <template slot-scope="scope">
                            <span>{{scope.row.restrict === 0 ? '无限' : scope.row.restrict}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="状态" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1" class="state-up"><i></i>已上架</span>
                            <span v-if="scope.row.status == 0" class="state-down"><i></i>已下架</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span><el-link slot="reference" type="primary" @click="share(scope.row)">分享</el-link></span> -
                            <span><el-link slot="reference" type="primary" @click="detail(scope.row)">详情</el-link></span> -
                            <span v-if="$store.state.user.info.role === 1"><el-link slot="reference" type="primary" @click="editDetail(scope.row)">编辑</el-link></span> -
                            <span>
                                <el-link v-if="scope.row.status == 1" type="primary" @click="setStatus(scope.row,scope.$index)">下架</el-link>
                                <el-link v-if="scope.row.status == 0" type="primary" @click="setStatus(scope.row,scope.$index)">上架</el-link>
                            </span> -
                            <span v-if="$store.state.user.info.role === 1">
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

        <el-dialog
                title="活动分享图"
                :visible.sync="dialogVisible"
                append-to-body
                width="30%"
                >
                <div style="display: flex;justify-content: center">
                    <div style="position: relative;width: 270px;height: 380px">
                        <img class="bg-img" :src="imageUrl + sharePic" width="270" height="380">
                        <div class="qrcode">
                            <make-share-pic :url="shareUrl"></make-share-pic>
                        </div>
                    </div>

                </div>
                <span slot="footer" class="dialog-footer">
                    <div style="display: flex;justify-content: center">
                         <el-button @click="dialogVisible = false">关闭</el-button>
                    </div>

              </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import MakeSharePic from "../../../components/makeSharePic/index";

    export default {
        name: "template-list",
        components: {MakeSharePic},
        data(){
            return {
                dialogVisible:false,
                sharePic:'',
                shareUrl:'',
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                imageUrl:config.imageUrl,
                options: [{
                value: 1,
                label: '上架'
            },
            {
                value: 0,
                label: '下架'
            }],
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [
                ],
                currentRow: null,
                name:'',
                status:''
            }
        },
        methods:{
            ...mapActions('marketing',['getPromotionList','setPromotionStatus1']),
            ...mapActions('common',['delete']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    name:this.name,
                    status:this.status,
                    page:this.page,
                    pageSize:this.pageSize
                }
                this.getPromotionList(data).then(res => {
                    this.loading = false
                    this.tableData = res.data.list
                    this.total = res.data.total
                })
            },
            setStatus(row, rowIndex){
                let _this = this
                let data = {
                    id:row.id,
                    status:!row.status,
                }

                this.loading = true
                this.setPromotionStatus1(data).then(success => {
                    _this.tableData[rowIndex].status = success.data.status

                    _this.$message({
                        message: '设置成功',
                        type: 'success'
                    });
                    this.loading = false
                },error => {
                    _this.$message({
                        message: '设置失败',
                        type: 'error'
                    });
                    this.loading = false
                })
            },
            deleted(item){
                this.delete({model:'Promotion',id:item.id}).then(res => {
                    if(res.ret === 0)
                    {
                        this.$message.success('删除成功！')
                        this.getList()
                    }
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            editDetail(row){
                this.$router.push('/promotion/edit/'+row.id)
            },
            share(row){
                this.sharePic = row.share_pic
                this.shareUrl = row.share_url
                this.dialogVisible = true
                console.log(row)
            },
            detail(row){
                this.$router.push('/promotion/detail/'+row.id+'/promotionUsers')
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>
.bg-img{

}
    .qrcode{
        position: absolute;
        bottom: 0;
        right: 0;
        padding:5px;
        background: #fff;
        margin:5px
    }
</style>
