import { Api } from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getSalesList({ commit }, data) {
        return (new Api()).getList(config.getSalesList, data)
    },
    addSales({ commit }, data) {
        return (new Api()).add(config.addSales, data)
    },
    addSalesWx({ commit }, data) {
        return (new Api()).add(config.addSalesWx, data)
    },
    getStatistics({ commit }, data) {
        return (new Api()).add(config.getStatistics, data)
    },
    handleSales({ commit }, data) {
        return (new Api()).add(config.handleSales, data)
    },
    checkSales({ commit }, data) {
        return (new Api()).add(config.checkSales, data)
    },
    manageStatistics({ commit }, data) {
        return (new Api()).add(config.manageStatistics, data)
    },
    exportSales({ commit }, data) {
        return (new Api()).service(config.exportSales, data)
    },
    getSalesDetail({ commit }, id) {
        return (new Api()).add(config.getSalesDetail, { id: id })
    },
    getExpressageList({ commit }, data) {
        return (new Api()).getList(config.getExpressageList, data)
    },
    getWxCountDetail({ commit }, data) {
        return (new Api()).getList(config.getWxCountDetail, data)
    },
    getSalesUserCourseDetail({ commit }, data) {
        return (new Api()).getList(config.getSalesUserCourseDetail, data)
    },
    openSalesUserCourse({ commit }, data) {
        return (new Api()).getList(config.openSalesUserCourse, data)
    },
    getStatisticsDetail({ commit }, data) {
        return (new Api()).getList(config.getStatisticsDetail, data)
    },
    getStatisticsChart({ commit }, data) {
        return (new Api()).getList(config.getStatisticsChart, data)
    },
    salesChangeUser({ commit }, data) {
        return (new Api()).getList(config.salesChangeUser, data)
    },
    getPowerList({ commit }, data) {
        return (new Api()).getList(config.getPowerList, data)
    },
    getUserCourseList({ commit }, data) {
        return (new Api()).getList(config.getUserCourseList, data)
    },
    userCheckCourseList({ commit }, data) {
        return (new Api()).getList(config.userCheckCourseList, data)
    },
    userCourseOperation({ commit }, data) {
        return (new Api()).add(config.userCourseOperation, data)
    },
    userApplyDeleted({ commit }, data) {
        return (new Api()).del(config.userApplyDeleted, data)
    },
    setPowerApprove({ commit }, id) {
        return (new Api()).edit(config.setPowerApprove, {id:id})
    },
    setPowerReject({ commit }, id) {
        return (new Api()).edit(config.setPowerReject, {id:id})
    },
    getPowerDetail({ commit }, id) {
        return (new Api()).edit(config.getPowerDetail, {id:id})
    },
    getPowerget({ commit }, id) {
        return (new Api()).edit(config.getPowerget, {id:id})
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
