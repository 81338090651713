import { config } from '../../js/config'
import { Api } from '../../service/Api'

const state = {}

const getters = {}

const actions = {
	getPunchList({ commit }, data) {
		return new Api().getList(config.getPunchList, data)
	},

	getPunchPlan({ commit }, data) {
		return new Api().getList(config.getPunchPlan, data)
	},

	getPlanDateData({ commit }, data) {
		return new Api().getList(config.getPlanDateData, data)
	},

	addPlanDate({ commit }, data) {
		return new Api().add(config.addPlanDate, data)
	},

	addPunch({ commit }, data) {
		return new Api().add(config.addPunch, data)
	},

	addCheck({ commit }, data) {
		return new Api().add(config.addCheck, data)
	},

	getCheckStudent({ commit }, data) {
		return new Api().getList(config.getCheckStudent, data)
	},

	addCheckStudent({ commit }, data) {
		return new Api().add(config.addCheckStudent, data)
	},

	deletePunch({ commit }, data) {
		return new Api().delete(data)
	},

	deleteCheck({ commit }, data) {
		return new Api().delete(data)
	},

	deleteCheckDate({ commit }, data) {
		return new Api().delete(data)
	},

	deleteCheckUser({ commit }, data) {
		return new Api().delete(data)
	},

	switchDetailStatus({ commit }, { model, id, status }) {
		return new Api().setStatus(model, id, status)
	},

	setSort({ commit }, data) {
		return new Api().edit(config.setCheckSort, data)
	},

	getCheckDetail({ commit }, id) {
		return new Api().getList(config.getCheckDetail, {id:id})
	},

	getPunchDetail({ commit }, id) {
		return new Api().getList(config.getPunchDetail, {id:id})
	}
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
