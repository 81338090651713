import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getGoodsList({commit},data){
        return (new Api()).getList(config.getGoodsList,data)
    },
    addGoods({commit},data){
        return (new Api()).add(config.addGoods,data)
    },
    getStoreDetail({commit},data){
        return (new Api()).getList(config.getStoreDetail,data)
    },

    getOrderList({commit},data){
        return (new Api()).getList(config.getOrderList,data)
    },
    createdSalesOrder({commit},data){
        return (new Api()).getList(config.createdSalesOrder,data)
    },
    getOrderDetail({commit},data){
        return (new Api()).getList(config.getOrderDetail,data)
    },
    setGoodsStatus({commit},data){
        return (new Api()).setStatus('StoreModel',data.id,data.status)
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
