export default {
	sToTime(s) {
		var h
		h = Math.floor(s / 60)
		s = s % 60
		h += ''
		s += ''
		h = h.length == 1 ? '0' + h : h
		s = s.length == 1 ? '0' + s : s
		return h + ':' + s
	},

	//百分比转换
	percentage(p) {
		return p.toFixed(2) * 100 + '%'
	},
	// 获得日期
	getFormatDate(){
		let now = new Date()
		let year = now.getFullYear()
		let month = now.getMonth() + 1
		let date = now.getDate()
		return year + '-' + month + '-' + date
	},
	/**
	 * 下载文件
	 * @param {Object} base64
	 * @param {Object} name
	 */
	downloadFileByBase64(base64, name) {
		var myBlob = this.dataURLtoBlob(base64)
		this.downloadFile(myBlob, name)
	},
	/**
	 * 处理数据流
	 * @param {Object} dataurl
	 */
	dataURLtoBlob(dataurl) {
		var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	},
	/**
	 * 模拟下载
	 * @param {Object} myBlob
	 * @param {Object} name
	 */
	downloadFile(myBlob, name) {
		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(myBlob, name)
		} else {
			const link = document.createElement('a')
			link.href = URL.createObjectURL(myBlob)
			link.download = name
			link.click()
			URL.revokeObjectURL(link.href)
		}
	},
}
