<template>
    <div>
        <span @click="showDialog">赠送vip</span>

        <el-dialog
                title="赠送vip"
                append-to-body
                :visible.sync="isShowDialog"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false"
                width="30%"
                >
            <span>
                赠送时长：
                <el-select class="margin-l-sm" v-model="vipId" size="mini" clearable placeholder="选择赠送时长">
                <el-option
                        v-for="item in period"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
              </el-select>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideDialog" size="mini">取 消</el-button>
                <el-button type="primary" @click="submit" size="mini" :loading="btnLoad">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "donateVip",
        props:{
            userid:{
                default:0,
                type:Number,
                required: true
            },
        },
        data(){
            return {
                btnLoad:false,
                first:false,
                vipId:'',
                period:[],
                isShowDialog:false,
            }
        },
        methods:{
            ...mapActions('marketing',['vipCardDetail']),
            ...mapActions('user',['donateVip']),
            showDialog(){
                this.isShowDialog = true
                this.vipCardDetail({id:2}).then(res => {
                    if(res.ret === 0)
                    {
                        this.period = res.data.periods
                        console.log(res.data.periods)
                    }
                })
            },
            hideDialog(){
                this.isShowDialog = false
            },
            submit(){
                this.btnLoad = true
                let data = {
                    userId:this.userid,
                    vipId:this.vipId
                }
                this.donateVip(data).then(res => {
                    if(res.ret === 0 )
                    {
                        this.$message.success('赠送成功')
                    }else{
                        this.$message.error('赠送失败')
                    }
                    this.isShowDialog = false
                    this.btnLoad = false
                })
                console.log(data)
            }
        },
        mounted() {



        }

    }
</script>

<style scoped>

</style>