<template>
    <div  v-loading="loading">
        <div>
            <span class="fl-l">
                <span class="fl-l"><el-input v-model="searchName" placeholder="请输入内容" clearable size="课时名称"></el-input></span>
                <span class="fl-l margin-l-sm"><el-button  size="mini" plain icon="el-icon-search" @click="search">筛选</el-button></span>
            </span>
            <span class="fl-r">
                <el-button  size="mini" plain icon="el-icon-plus" @click="toAddCatalogue">添加内容</el-button>

            </span>
            <p class="clear"></p>
        </div>

        <div class="table-content">

            <div>
                <div class=" table-header">
                    <div class="fl-l w26">名称</div>
                    <div class="fl-l w26">概要</div>
                    <div class="fl-l w10">状态</div>
                    <div class="fl-l w10">排序</div>
                    <div class="fl-l w26" style="text-align: right">操作</div>
                    <div class="clear"></div>
                </div>
            </div>

            <div class="padding-sm">
                <div class="margin-sm border-s padding-sm font-size-12" v-for="clazz in list" :key="clazz.id">
                    <div class="fl-l w26">
                        {{clazz.name}}
                    </div>
                    <div class="fl-l w26">
                        <p> {{ clazz.type === 1 ? '书本' : '其他'}}</p>
                    </div>
                    <div class="fl-l w10">
                        <span v-if="clazz.status == 1" class="state-up"><i></i>已上架</span>
                        <span v-if="clazz.status == 0" class="state-down"><i></i>已下架</span>
                    </div>
                    <div class="fl-l w10">{{clazz.sort}}</div>
                    <div class="fl-l w26" style="text-align: right">
                        <el-popover
                                class="margin-l-sm"
                                placement="left"
                                v-model="clazz.show">
                            <div class="padding-sm">
                                <p class="fl-l margin-r-sm" style="height: 30px;line-height: 30px">移动到第</p>
                                <p class="fl-l"><el-input-number :min="0" size="mini" v-model="clazz.sort" controls-position="right" ></el-input-number></p>
                                <div class="fl-l margin-l-sm">
                                    <el-button type="primary" size="mini" @click="changeSort(clazz,specialColumnContent)" :loading="clazz.loading">确定</el-button>
                                    <el-button size="mini" type="text" @click="clazz.show = false">取消</el-button>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <el-link slot="reference"  type="primary" >排序</el-link>
                        </el-popover>
                        -
                        <el-link type="primary" @click="editClass(clazz)">编辑</el-link>
                        -
                        <el-link v-if="clazz.status == 1" type="primary" @click="setStatus(clazz,specialColumnContent)">下架</el-link>
                        <el-link v-if="clazz.status == 0" type="primary" @click="setStatus(clazz,specialColumnContent)">上架</el-link>
                        -

                        <el-popconfirm
                                icon="el-icon-info"
                                icon-color="red"
                                title="删除后将不可恢复，确定删除吗？"
                                @confirm="deleted(clazz,'SpecialColumnContent')"
                        >
                            <el-link slot="reference" type="primary">删除</el-link>
                        </el-popconfirm>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../../../utils/tools";

    export default {
        name: "content",
        data(){
            return{
                editChapterVisible:false,
                searchName:'',
                tableData:[],
                chapterName:'',
                selectChapterId:0,
                editChapterLoading:false,
                input:'',
                loading:false,
                visible:false,
                visible2:false,
                chapter:'',
                createChapterLoading:false,
                courseId:0,
                list:[],
                specialColumnContent:'specialColumnContentModel',
            };
        },
        methods:{
            ...mapActions('specialColumn',['getSpecialColumnContentList','setSort','setTheStatus']),
            ...mapActions('common',['delete']),
            search(){
                this.getList()
            },
            showEditChapter(item){
                this.selectChapterId = item.id
                this.editChapterVisible = true
            },
            hideEditChapter(){
                this.editChapterVisible = false
            },
            editClass(item){
                this.$router.push('/specialColumn/editContent/'+this.courseId+'/'+item.id)
            },
            changeSort(item,model){
                console.log(item)
                item.loading = true

                let data = {
                    id:item.id,
                    sort:item.sort,
                    model:model
                }

                this.setSort(data).then(res => {
                    if(res.ret === 0){
                        item.loading = false
                        item.show = false
                        this.$message.success('排序成功！')
                        this.getList()
                    }
                })
            },
            setStatus(item,model){
                let data = {
                    model:model,
                    id:item.id,
                    status:!item.status
                }


                this.setTheStatus(data).then(res => {
                    if(res.ret === 0)
                    {
                        this.$message.success('设置成功！')
                        this.getList()
                    }
                })
            },
            deleted(item,model){
                console.log(item)
                this.delete({model:model,id:item.id}).then(res => {
                    if(res.ret === 0)
                    {
                        this.$message.success('删除成功！')
                        this.getList()
                    }
                })
            },
            toAddCatalogue(){
                this.$router.push('/specialColumn/addContent/'+this.courseId)
            },
            s_to_hs(s){
                return tools.sToTime(s)
            },
            getList(){
                this.loading = true
                let data = {
                    specialColumnId:this.courseId,
                    name:this.searchName,
                }

                this.getSpecialColumnContentList(data).then(res => {
                    console.log(res)
                    if(res.ret === 0){
                        this.list = res.data
                    }

                    this.loading = false
                })
            }
        },
        mounted() {
            this.courseId = this.$route.params.id
            this.getList()
        }
    }
</script>

<style >
    .table-header{
        padding:15px;
        background: #fafafa;
    }
    .font-size-12{
        font-size: 12px;
    }
    .circle{
        border:1px solid #ccc;
        padding:3px;
        border-radius: 50%;
        margin-right: 3px;
        font-size: 8px;
    }
    .section{
        font-size: 14px;
        padding: 20px;
        font-weight: bold;
    }
    .border-s{
        border-bottom:1px solid #ebebeb
    }
    .w10{
        width: 10%;
    }
    .w26{
        width: 26.3333%;
    }
</style>