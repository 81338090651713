<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >内容</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/fileDownload' }">资料下载</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info" v-loading="dataLoading">
            <el-form ref="form" :model="form" label-width="120px" size="mini" >

                <el-form-item label="名称：">
                    <el-input v-model="form.name" style="width: 200px"></el-input>
                </el-form-item>

                <el-form-item label="年级：" label-position="left" class="input" >
                    <el-cascader
                        style="width: 200px"
                        v-model="selectGrade"
                        :options="options"
                        :props="{ expandTrigger: 'hover' }"
                        @change="handleChange"></el-cascader>
                </el-form-item>

                <el-form-item label="选择分类：" >
                    <el-select v-model="form.category" clearable placeholder="选择分类" size="small">
                        <el-option
                                v-for="item in categorys"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="选择学期：" >
                    <el-select v-model="form.semester" clearable placeholder="选择学期" size="small">
                        <el-option label="上册" :value="1"></el-option>
                        <el-option label="下册" :value="2"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="选择文件：">
                    <edit-file :fileName="fileName"  @onSelect="onSelect"></edit-file>
                </el-form-item>

                <el-form-item label="预览图：">
                    <edit-cover :pic="pic" :uploadPath="uploadPath" @success="success"></edit-cover>
                </el-form-item>

                <el-form-item label="付费状态：" prop="resource">
                    <el-radio-group v-model="form.paymentStatus">
                        <el-radio label="1" >免费</el-radio>
                        <el-radio label="2" >收费</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="所需积分：" v-if="form.paymentStatus != 1">
                    <el-input v-model="form.price" style="width: 200px"></el-input>
                </el-form-item>



                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link :to="'/fileDownload?page='+page" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import SelectFile from "../../../components/selectFile";
    import Cover from "../../../components/cover";
    import EditCover from "../../../components/editCover";
    import EditFile from "../../../components/editFile";
    export default {
        name: "template-add",
        components: {EditFile, EditCover},
        data() {
            return {
                selectGrade:[],
                statusTxt:'上架',
                loading:false,
                dataLoading:false,
                uploadPath:'fileDownloadPreview',
                file:{},
                fileName:'',
                categorys:[],
                id:0,
                form: {
                    semester:'',
                    name:'',
                    type: 0,
                    status:true,
                    resource:0,
                    paymentStatus:'1',
                    price:0,
                    fileId:0,
                    category:''
                },
                options: [],
                gradeId:0,
                image:'',
                pic:'',
                page:1,
            }
        },
        methods: {
            ...mapActions('common',['getBaseData']),
            ...mapActions('fileDownload',['addFileDownload','getFileDownloadDetail']),
            onSelect(file){
                this.fileId = file.id
                this.file = file
            },
            async onSubmit() {
                let _this = this
                this.loading = true
                let data = {
                    id:this.id,
                    name:this.form.name,
                    category:this.form.category,
                    grade_id:this.gradeId,
                    file_id:this.fileId,
                    payment_status:this.form.paymentStatus,
                    price:this.form.price,
                    status:this.form.status,
                    preview_pic:this.image,
                    semester:this.form.semester,
                }


                console.log(data)
                await this.addFileDownload(data).then( res => {
                    if(res.ret == 0){
                        this.$message.success('修改成功！')
                        this.$router.push('/fileDownload?page='+this.page)
                    }else{
                        this.$message.error('修改失败！')
                    }
                    this.loading = false
                })

            },
            setStatus(status){
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
            },
            handleChange(value){
                console.log(value)
                this.gradeId = value[1]

            },
            success(path){
                this.image = path
            }
        },
        async mounted() {
            this.page =  this.$route.query.page === undefined ? 1 : this.$route.query.page

            this.dataLoading = true
            this.id =  this.$route.params.id

           await this.getFileDownloadDetail({id:this.id}).then(res => {

                if(res.ret === 0 && res.data != undefined)
                {
                    let detail = res.data
                    this.form.name  = detail.name
                    this.form.type  = detail.type
                    this.form.status  = detail.status === 1 ? true : false
                    this.form.resource  = detail.resource
                    this.form.paymentStatus  = detail.payment_status+''
                    this.form.price  = detail.price
                    this.file.id  = detail.id
                    this.form.file_id  = detail.file_id
                    this.fileId  = detail.file_id
                    this.gradeId  = detail.grade_id
                    this.form.category  = detail.category
                    this.form.semester  = detail.semester
                    this.pic  = config.imageUrl + detail.preview_pic
                    this.image  = detail.preview_pic
                    this.fileName  = detail.file_name

                    console.log(detail)
                }
            })


            let levels = [];
            let grades = [];
            let subjects = [];
            let options = [];
            await this.getBaseData().then( res => {
                levels = res.data.level
                grades = res.data.grades
                subjects = res.data.subject
                this.categorys = res.data.fileCategory
            })
            levels.forEach(level => {
                let children = []
                grades.forEach(grade => {
                    if(grade.level_id == level.id){
                        children.push({
                            value: grade.id,
                            label: grade.name
                        })
                    }
                })
                options.push({
                    value:level.id,
                    label:level.name,
                    children:children
                })
            })
            this.options = options

            grades.forEach(grade => {
                    if(this.gradeId === Number(grade.id)){
                        console.log('--d-d-d-d-')
                        this.selectGrade = [grade.level_id,grade.id]
                        console.log(this.selectGrade)
                    }
            })

            this.dataLoading = false
        }
    }
</script>

<style scoped>

</style>
