<template>
    <div>
        <div class="main-title">快递查询</div>
        <div class="content-list-page page-content">

            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">输入名称，或者手机号可以查询</li>
                    </ul>
                </div>
            </div>

            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-input v-model="name" placeholder="姓名" clearable size="small"></el-input>
                </span>

                <span class="margin-l-sm fl-l" >
                    <el-input v-model="phone" placeholder="手机号" clearable size="small"></el-input>
                </span>

<!--                <span class="margin-l-sm fl-l" >-->
<!--                    <el-date-picker-->
<!--                            size="small"-->
<!--                            v-model="value2"-->
<!--                            type="daterange"-->
<!--                            align="right"-->
<!--                            unlink-panels-->
<!--                            range-separator="至"-->
<!--                            start-placeholder="开始日期"-->
<!--                            end-placeholder="结束日期"-->
<!--                            :picker-options="pickerOptions">-->
<!--                    </el-date-picker>-->
<!--                </span>-->

                <span class="margin-l-sm fl-l">
                     <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                     <el-button v-if="this.$store.state.user.info.role === 1" plain icon="el-icon-upload2" size="small" :loading="importing" @click="importExpressage">导入</el-button>
                </span>

                <cover  ref="importFile"  style="display: none" uploadPath="import" @success="uploadSuccess"></cover>

<!--                <span class=" fl-r">-->
<!--                    <router-link to="/promotion/add" class="router-link" >-->
<!--                        <el-button type="primary" size="small" >新建活动</el-button>-->
<!--                    </router-link>-->
<!--                </span>-->

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="姓名">
                        <template slot-scope="scope">
                            <span>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="手机号" >
                        <template slot-scope="scope">
                            <span >{{scope.row.phone}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间"
                    >
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="邮寄时间" >
                        <template slot-scope="scope">
                            <span>{{scope.row.date}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="快递单号" >
                        <template slot-scope="scope">
                            <span>{{scope.row.expressage_order}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="备注" >
                        <template slot-scope="scope">
                            <span>{{scope.row.remark}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span><el-link slot="reference" type="primary" @click="openWindow(scope.row)">查看物流信息</el-link></span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

        <el-dialog
                title="活动分享图"
                :visible.sync="dialogVisible"
                append-to-body
                width="30%"
        >
            <div style="display: flex;justify-content: center">
                <div style="position: relative;width: 270px;height: 380px">
                    <img class="bg-img" :src="imageUrl + sharePic" width="270" height="380">
                    <div class="qrcode">
                        <make-share-pic :url="shareUrl"></make-share-pic>
                    </div>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
                    <div style="display: flex;justify-content: center">
                         <el-button @click="dialogVisible = false">关闭</el-button>
                    </div>

              </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import MakeSharePic from "../../../components/makeSharePic/index";
    import Cover from "../../../components/cover";

    export default {
        name: "template-list",
        components: {Cover, MakeSharePic},
        data(){
            return {
                dialogVisible:false,
                sharePic:'',
                importing:false,
                shareUrl:'',
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                imageUrl:config.imageUrl,
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [
                ],
                currentRow: null,
                name:'',
                phone:''
            }
        },
        methods:{
            ...mapActions('sales',['getExpressageList',]),
            ...mapActions('common',['importTask',]),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            importExpressage(){
                console.log( this.$refs.importFile)
                this.$refs.importFile.$el.getElementsByClassName('el-upload el-upload--picture-card')[0].click()
            },
            uploadSuccess(file){

                this.importing = true
                let data = {
                    data:file,
                    name:'快递单号导入',
                    type:'importExpresses'
                }
                this.importTask(data).then(res => {
                    console.log(res)
                    this.$message.success('导入成功！')
                    this.$refs.importFile.$refs.fileList.clearFiles()
                }).finally(()=>{
                    this.importing = false
                })
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            openWindow(row){
                console.log(row)
                window.open('https://www.baidu.com/s?wd='+row.expressage_order)
            },
            getList(){
                this.loading = true
                let search = {
                    name:this.name,
                    phone:this.phone,
                }
                this.getExpressageList(search).then(res => {

                    this.tableData = res.data.list
                }).finally(()=>{
                    this.loading = false
                })
            },
            setStatus(row, rowIndex){
                let _this = this
                let data = {
                    id:row.id,
                    status:!row.status,
                }

                this.loading = true
                this.setPromotionStatus1(data).then(success => {
                    _this.tableData[rowIndex].status = success.data.status

                    _this.$message({
                        message: '设置成功',
                        type: 'success'
                    });
                    this.loading = false
                },error => {
                    _this.$message({
                        message: '设置失败',
                        type: 'error'
                    });
                    this.loading = false
                })
            },
            deleted(item){
                this.delete({model:'Promotion',id:item.id}).then(res => {
                    if(res.ret === 0)
                    {
                        this.$message.success('删除成功！')
                        this.getList()
                    }
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            editDetail(row){
                this.$router.push('/promotion/edit/'+row.id)
            },
            share(row){
                this.sharePic = row.share_pic
                this.shareUrl = row.share_url
                this.dialogVisible = true
                console.log(row)
            },
            detail(row){
                this.$router.push('/promotion/detail/'+row.id+'/promotionUsers')
            }
        },
        mounted() {
            // this.getList()
        }
    }
</script>

<style>
    .bg-img{

    }
    .qrcode{
        position: absolute;
        bottom: 0;
        right: 0;
        padding:5px;
        background: #fff;
        margin:5px
    }
</style>
