<template>
    <div>
        <el-button @click="dialogVisible = true">添加信息</el-button>



        <el-dialog append-to-body title="添加信息" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">
            <div style="padding:20px">


                <div class="padding-sm" v-if="this.form.id === 0">
                    <el-select v-model="searchType" slot="prepend" placeholder="选择类型" size="mini"
                        @change="changeSearch">
                        <el-option label="微信昵称" :value="1"></el-option>
                        <el-option label="UID" :value="2"></el-option>
                    </el-select>

                    <el-select class="margin-l-sm" v-model="studentName" size="mini" clearable filterable remote
                        reserve-keyword @change="selectUser" @clear="clearUser" placeholder="请输入关键词"
                        :remote-method="searchStudent" :loading="loading">
                        <el-option style="height: 40px;line-height: 40px" v-for="item in users" :key="item.id"
                            :label="item.name" :value="item.id">
                            <div
                                style="display: flex;justify-content: start;align-items: center;height: 40px;line-height: 40px">
                                <span style="margin-right: 10px"> {{ item.id }} </span>
                                <el-avatar size="small" :src="item.avatar_url"></el-avatar>
                                <span style="margin-left: 10px;">{{ item.name }}</span>
                            </div>
                        </el-option>
                    </el-select>
                </div>


                <div class="margin-t-sm" v-if="notData">暂无数据</div>
                <div>
                    <div style="margin-top: 12px">
                        <div v-if="JSON.stringify(selectCurrentUser) !== '{}'" style="
					margin-top: 12px;
					display: flex;
					align-items: center;
					padding: 10px;
					border-radius: 4px;
					margin: 4px;
				">
                            <el-avatar size="small" :src="selectCurrentUser.avatar_url"></el-avatar>
                            <span style="margin-left: 4px">{{ selectCurrentUser.name }}</span>
                        </div>

                        <el-form ref="form" label-position="left" label-width="120px" :model="form" >

                            <el-form-item label="选择课程: " prop="selectCurrentCourse">
                                <select-course @onSelect="onSelectCourse" :id="form.user_id"></select-course>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveUserCourse">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { mapActions } from "vuex";
import SelectCourse from "./selectUserCourse";

export default {
    name: "salesUserCourse",
    components: { SelectCourse },
    data() {
        return {
            imageUrl: config.imageUrl,
            dialogVisible: false,
            searchType: 1,
            studentName: '',
            loading: false,
            selectCurrentUser: {},
            users: [],
            notData: '',
            form: {
                id: 0,
                start_at: '',
                is_all: 1,
                type: '',
                course_id: 0,
                user_id: 0,
                start_plan_day: 0,
                end_plan_day: 0,
                update_day: [1, 2, 4, 5],
                selectCurrentCourse: null
            },
            userCourseData: [],
            selectCurrentCourse: null,
            courseConfig: null,

            //选择的图书ID表单
            //普通课程
            courses: [],
            //打卡课程
            checkCourses: [],

        }
    },
    methods: {
        ...mapActions('punch', ['getCheckStudent', 'addCheckStudent']),
        ...mapActions('user', ['getUserList']),
        ...mapActions('common', ['delete']),
        handleClose() {

        },
        onSelectCourse(info) {
            this.courses = info.courses
            this.checkCourses = info.checkCourses
        },
        changeSearch() { },
        selectUser(id) {
            this.users.forEach(item => {
                if (id === item.id) {
                    this.selectCurrentUser.name = item.name
                    this.selectCurrentUser.id = item.id
                    this.selectCurrentUser.avatar_url = item.avatar_url
                }
            })
            this.form.user_id = id
        },
        clearUser() { },
        searchStudent(v) {
            let searchData = {}
            if (this.searchType === 1) {
                searchData.name = v
                searchData.pageSize = 1000
            } else {
                searchData.uid = v
                searchData.pageSize = 1000
            }
            if (v === '') {
                this.$message.error('请输入昵称')
                return
            }
            this.notData = false
            this.getUserList(searchData).then(res => {
                if (res.data.list.length === 0) {
                    this.notData = true
                }
                this.users = res.data.list
            })
        },
        saveUserCourse() {
            if (this.courses == '' && this.checkCourses == '') {
                this.$message.warning('至少选一个课程');
            } else {
                this.$emit('onAdd', {
                    courses: this.courses,
                    checkCourses: this.checkCourses,
                    userInfo: this.selectCurrentUser
                })
                this.dialogVisible = false
            }


        }
    }
}
</script>

<style scoped>
</style>