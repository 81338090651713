<template>
	<div class="main">
		<el-container>
			<el-aside class="layout-left" width="auto;" style="overflow: hidden">
				<Left :menuStatus="menuStatus" />
			</el-aside>

			<el-container>
				<el-header
					class="layout-top"
					style="
						padding: 0;
						box-shadow: 0 2px 30px 0 #edeef2;
						background-color: #fcfdff;
					"
				>
					<Top @setMenuStatus="setMenuStatus" />
				</el-header>

				<el-main
					class="layout-main"
					style="padding: 10px; background: #f4f5f9"
					:style="{ 'margin-left': rightWidth + 'px' }"
				>
					<div class="main-box" style="; height: 100%; width: 100%">
						<router-view />
					</div>
				</el-main>

				<el-footer class="layout-footer" style="padding: 0">
					<Footer />
				</el-footer>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import Left from './layout/Left'
import Top from './layout/Top'
import Footer from './layout/Footer'

export default {
	name: 'index',
	components: { Footer, Top, Left },
	data() {
		return {
			menuStatus: 0,
			rightWidth: 60
		}
	},

	methods: {
		setMenuStatus(status) {
			this.menuStatus = status

			this.rightWidth = status ? 60 : 230
		}
	}
}
</script>

<style scoped>
	.main-box{
		background: #fff
	}
</style>
