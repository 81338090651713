<template>
    <div>
        <div v-if="array.length === 0">无</div>
        <el-image v-else class="table-item-img " :src="firstImg" :preview-src-list="array">
            <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
            </div>
        </el-image>
    </div>
</template>

<script>
    export default {
        name: "previewImg",
        props:{
            picArray:{
                default:null,
                type:Array
            },
            http:{
                default: '',
                type:String
            }
        },
        data(){
            return{
                firstImg:'',
                array:[]
            }
        },
        methods:{

        },
        created() {
            this.picArray.forEach((item) => {
                this.array.push(this.http + item)
            })
            if(this.picArray.length > 0)
                this.firstImg = this.http + this.picArray[0]
        }
    }
</script>

<style scoped>

</style>