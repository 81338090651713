<template>
    <div>
        <!-- 表头 -->
        <div class="main-title">权限变更</div>
        <div class="content-list-page page-content">
            <!-- 提示 -->
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">流程审批通过，最终状态，已完成 完成后无法 修改 删除</li>
                    </ul>
                </div>
            </div>
            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l">
                    <el-input v-model.trim="searchData.initiatorName" placeholder="请输入申请人的名字"></el-input>
                </span>
                <span class="margin-l-sm fl-l">
                    <el-input v-model="searchData.id" placeholder="请输入权限列表的ID"></el-input>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-select v-model="searchData.status" clearable placeholder="选择类型" size="small">
                        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </span>
                <span class="margin-l-sm fl-l">
                    <el-select v-model="searchData.type" clearable placeholder="选择状态" size="small">
                        <el-option label="课程转移" :value="1"></el-option>
                        <el-option label="开通课程" :value="2"></el-option>
                        <el-option label="关闭课程" :value="3"></el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/power/add" class="router-link">
                        <el-button type="primary" size="small">新建请求</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>

            </div>

            <!-- 表格数据 -->
            <div class="table-content">
                <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" highlight-current-row
                    v-loading="loading" style="width: 100%;background: #fff;">
                    <el-table-column property="id" label="ID" width="80">
                    </el-table-column>
                    <el-table-column property="index" label="名称" width="180">
                        <template slot-scope="scope">
                            <span>{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="类型" width="180">
                        <template slot-scope="scope">
                            <span v-if="scope.row.action_type == 1">课程转移</span>
                            <span v-if="scope.row.action_type == 2">开通课程</span>
                            <span v-if="scope.row.action_type == 3">关闭课程</span>
                        </template>
                    </el-table-column>

                    <el-table-column property="initiator_name" label="发起人" width="180">
                    </el-table-column>

                    <el-table-column property="reviewer_name" label="审核人" width="180">
                    </el-table-column>

                    <el-table-column property="created_at" label="时间" width="200">
                    </el-table-column>

                    <el-table-column property="remarks" label="备注" width="200">
                    </el-table-column>
                    <el-table-column property="address" label="图片">
                        <template slot-scope="scope">
                            <el-image v-if="!scope.row.attach_files[0] == ''" style="width: 100px; height: 100px"
                                :src="scope.row.attach_files[0]" :preview-src-list="scope.row.attach_files">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column property="address" label="状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == 1" size="mini">待审批</el-tag>
                            <el-tag v-if="scope.row.status == 2" type="success" size="mini">已完成</el-tag>
                            <el-tag v-if="scope.row.status == 3" type="danger" size="mini">已驳回</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status != 2">
                                <el-link slot="reference" v-if="scope.row.can_approve" type="primary"
                                    @click="statusCheng(scope.row)">审核 -</el-link>

                            </span>
                            <span v-if="scope.row.status != 2">
                                <el-link slot="reference" type="primary" @click="editDetail(scope.row)">编辑</el-link>
                                -
                            </span>
                            <span>
                                <el-popover placement="right" width="430" trigger="click">
                                    <div v-loading="usercourseLoading"
                                        style="padding: 20px;max-height: 500px;overflow:auto;">
                                        <span style="height:20px;display: block;"></span>
                                        <el-descriptions style="margin-bottom: 10px"
                                            :title="userCourse.identity == 2 ? '' : (scope.row.action_type == 1 ? '课程转移' : (scope.row.action_type == 2 ? '开通课程' : '关闭课程'))"
                                            v-for="(userCourse, index) in userCourses" :key="index" size="mini" border
                                            :column="2">
                                            <el-descriptions-item label="用户ID">{{ userCourse.user_id }}
                                            </el-descriptions-item>
                                            <el-descriptions-item label="用户名">
                                                <div>
                                                    <el-avatar size="small" :src="userCourse.avatar_url"></el-avatar>
                                                </div>
                                                <div>{{ userCourse.username }}</div>
                                            </el-descriptions-item>
                                            <el-descriptions-item label="课程ID">{{ userCourse.course_id }}
                                            </el-descriptions-item>
                                            <el-descriptions-item label="课程名">
                                                <div>
                                                    <el-image v-if="userCourse.json_data.course_pic" style="width: 100px;"
                                                        :src="imageUrl + userCourse.json_data.course_pic" fit="contain">
                                                    </el-image>
                                                    <el-image v-if="userCourse.json_data.pic" style="width: 100px;"
                                                        :src="imageUrl + userCourse.json_data.pic" fit="contain">
                                                    </el-image>
                                                </div>
                                                <div v-if="userCourse.json_data.course_name">{{ userCourse.json_data.course_name }}</div>
                                                <div v-if="userCourse.json_data.name">{{ userCourse.json_data.name }}</div>
                                            </el-descriptions-item>
                                            <el-descriptions-item label="课程类型" :span="2">{{ userCourse.course_type ===
                                                    2 ? '打卡课程' : '普通课程'
                                            }}</el-descriptions-item>
                                            <el-descriptions-item label="更新周期" :span="2">{{
                                                    userCourse.json_data.update_day
                                            }}</el-descriptions-item>
                                            <el-descriptions-item label="是否全部计划" :span="2">
                                                <el-tag size="mini"
                                                    :type="userCourse.json_data.is_all == 1 ? 'success' : 'danger'">{{
                                                            userCourse.json_data.is_all == 1 ? '是' : '否'
                                                    }}</el-tag>
                                            </el-descriptions-item>
                                            <el-descriptions-item v-if="userCourse.json_data.is_all != 1" label="开始时间"
                                                :span="2">
                                                {{
                                                        userCourse.json_data.start_plan_day
                                                }}
                                            </el-descriptions-item>
                                            <el-descriptions-item v-if="userCourse.json_data.is_all != 1" label="结束时间"
                                                :span="2">
                                                {{
                                                        userCourse.json_data.end_plan_day
                                                }}
                                            </el-descriptions-item>

                                        </el-descriptions>
                                    </div>
                                    <el-link slot="reference" type="primary" @click="replace(scope.row)">详情</el-link>
                                </el-popover>

                            </span>
                            <span v-if="scope.row.status != 2">
                                -
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="table-batch">
                    <span class="fl-r">
                        <el-pagination small background :current-page="page" :page-sizes="[10, 20]"
                            :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize" @current-change="setPage" :total="total">
                        </el-pagination>
                    </span>
                    <div style="clear: both"></div>
                </div>
            </div>

            <!-- 对话框 -->
            <el-dialog title="审核" append-to-body :visible.sync="dialogVisible" :close-on-click-modal="false"
                :close-on-press-escape="false" :show-close="false" width="30%">
                <div style="padding:20px">
                    <div>
                        <el-radio-group v-model="status">
                            <el-radio-button label="通过"></el-radio-button>
                            <el-radio-button label="驳回"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="dialog_footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="statusChange">确 定</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "template-list",
    data() {
        return {
            //图片路径
            imageUrl: config.imageUrl,
            dialogVisible: false,
            //搜索表单数据
            searchData: {},
            //状态数据
            options: [
                {
                    id: 1,
                    name: '待审批',
                },
                {
                    id: 2,
                    name: '审批通过',
                },
                {
                    id: 3,
                    name: '审批驳回',
                },
            ],
            //表单加载
            loading: false,
            //表单数据
            tableData: [],
            //用户状态
            status: '通过',
            //修改的用户iD
            userId: null,
            //详情列表
            userCourses: [],
            //详情的加载效果
            usercourseLoading: false,
            //分页
            page: 1,
            //每页条数
            pageSize: 10,
            //总条数
            total: 0,
        }
    },
    methods: {
        ...mapActions('sales', ['getPowerList', 'userApplyDeleted', 'setPowerApprove', 'setPowerReject', 'getPowerDetail']),
        //搜索
        search() {
            this.initData()
        },
        //初始化数据
        initData() {
            this.loading = true
            this.searchData.page = this.page
            this.searchData.pageSize = this.pageSize
            this.getPowerList(this.searchData).then(res => {
                res.data.list.forEach(v => {
                    v.attach_files.forEach((c, i) => {
                        v.attach_files[i] = c ? this.imageUrl + c : ''
                    })
                });
                this.tableData = res.data.list
                this.total = res.data.total
                this.loading = false
                console.log(this.tableData);
            })
        },
        //详情
        replace(row) {
            this.userCourses = []
            this.usercourseLoading = true
            this.getPowerDetail(parseInt(row.id)).then(res => {
                this.userCourses = res.data
                this.usercourseLoading = false
            })
        },
        //编辑
        editDetail(row) {
            this.$router.push('/power/edit/' + row.id)
        },
        //删除
        deleted(row) {
            this.userApplyDeleted(row.id).then(res => {
                if (res.res_info == 'ok') {
                    this.initData()
                    this.$message.success('删除成功')
                }
            })
        },
        //显示多少条数据
        setPageSize(val) {
            this.pageSize = val
            this.initData()
        },
        //分页的第几页
        setPage(val) {
            this.page = val
            this.initData()
        },
        //打开对话框
        statusCheng(row) {
            this.dialogVisible = true
            this.userId = row.id
        },
        //编辑用户状态
        statusChange() {
            if (this.status == '通过') {
                this.setPowerApprove(this.userId).then(res => {
                    if (res.res_info == 'ok') {
                        this.initData()
                        this.dialogVisible = false
                        this.$message.success('审核完成')
                    }

                })
            } else {
                this.setPowerReject(this.userId).then(res => {
                    if (res.res_info == 'ok') {
                        this.initData()
                        this.dialogVisible = false
                        this.$message.success('审核完成')
                    }
                })
            }
        },
    },
    mounted() {
        this.initData()
    }
}
</script>
<style scoped>
.dialog_footer {
    text-align: right;
}
</style>