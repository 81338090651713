
<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>课堂</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/checkCourse' }" >打卡课程</el-breadcrumb-item>
                <el-breadcrumb-item>添加打卡</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form
                :rules="rules"
                ref="form"
                :model="form"
                style="padding: 48px 72px"
                label-position="right"
                label-width="auto"
        >
            <el-form-item label="课程名称: " prop="name">
                <el-input
                        v-model="form.name"
                        style="width: 240px"
                        autofocus
                        clearable
                />
            </el-form-item>

            <el-form-item label="是否上架: " prop="status">
                <el-switch v-model="form.status" :active-value="1" :sinactive-value="0"/>
            </el-form-item>

            <el-form-item label="封面: " prop="pic">
                <editCover
                        uploadPath="check"
                        :pic="config.imageUrl + form.pic"
                        @success="uploadSuccess"
                />
            </el-form-item>

            <el-form-item label="是否通知: " prop="notification">
                <el-switch v-model="form.notification" :active-value="1" :sinactive-value="0"/>
            </el-form-item>

            <el-form-item label="报名后几天更新: " prop="day">
                <el-input v-model="form.day" style="width: 240px" clearable />
            </el-form-item>

            <el-form-item label="更新周期: " prop="update_day">
                <el-checkbox-group v-model="form.update_day" size="small">
                    <el-checkbox-button
                            v-for="(city, index) in ['日', '一', '二', '三', '四', '五', '六']"
                            :label="index"
                            :key="index"
                    >
                        周{{ city }}
                    </el-checkbox-button>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item label="金额: " prop="amount">
                <el-input v-model="form.amount" style="width: 240px" clearable />
            </el-form-item>

            <el-form-item label="适合年级：" prop="grade_id">
                <el-cascader
                        style="width: 200px"
                        v-model="form.grade_id"
                        :options="options"
                        :props="{ expandTrigger: 'hover', multiple: true }"
                ></el-cascader>
            </el-form-item>

            <el-form-item label="有效期: " prop="indate">
                <el-input v-model="form.indate" style="width: 240px" clearable />
            </el-form-item>

            <el-form-item label="内容：" prop="content">
                <editor @onChange="onChange" :content="form.content" />
            </el-form-item>

            <el-form-item>
                <el-button @click="$router.back()" size="mini">取消</el-button>
                <el-button type="primary" size="mini" @click="submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import editCover from '@/components/editCover'
    import { mapActions } from 'vuex'
    import editor from '@/components/editor'
    import { config } from '@/js/config'

    export default {
        components: { editor,  editCover },

        data: () => ({
            config,
            loading: false,
            rules: {
                day: [{ required: true }],
                name: [{ required: true }],
                status: [{ required: true }],
                pic: [{ required: true }],
                notification: [{ required: true }],
                amount: [{ required: true }],
                indate: [{ required: true }],
                grade_id: [{ required: false }],
                content: [{ required: true }],
                update_day: [{ required: true }]
            },

            options: [],
            form: {
                id:0,
                day: 0,
                name: '',
                pic: '',
                status: true,
                amount: 0,
                indate: 365,
                notification: true,
                grade_id: [],
                content: '',
                update_day: [1, 2, 4, 5]
            }
        }),

        methods: {
            ...mapActions('punch', ['addPunch', 'getCheckDetail']),

            ...mapActions('common', ['getBaseData']),

            uploadSuccess(path) {
                this.form.pic = path
            },

            onChange(content) {
                this.form.content = content
            },

            submit() {
                this.$refs.form.validate(async valid => {
                    if (!valid) return
                    else {
                        this.loading = true

                        this.form.grade_id = this.form.grade_id.map(item => item[1])

                        const result = await this.addPunch(this.form)

                        this.loading = false

                        this.$message.success('修改成功')

                        this.$router.push('/checkCourse')
                    }
                })
            }
        },

        async mounted() {
            if (this.$route.params.id) {
                this.loading = true
                const { data } = await this.getCheckDetail(this.$route.params.id )
                this.form = data
                console.log(this.form)
            }

            const {
                data: { level, grades }
            } = await this.getBaseData()

            level.forEach(level => {
                let children = []

                grades.forEach(grade => {
                    if (grade.level_id == level.id) {
                        children.push({
                            value: grade.id,
                            label: grade.name
                        })
                    }
                })

                this.options.push({
                    value: level.id,
                    label: level.name,
                    children: children
                })
            })

            const grade_ids = []

            grades.forEach(grade => {
                if (this.form.grade_id.find(item => item == grade.id)) {
                    grade_ids.push([grade.level_id, grade.id])
                }
            })

            this.form.grade_id = grade_ids

            this.loading = false
        }
    }
</script>
