<template>
    <div>
        <el-button @click="dialogVisible = true">添加信息</el-button>



        <el-dialog
                append-to-body
                title="添加信息"
                :visible.sync="dialogVisible"
                width="600px"
                :before-close="handleClose">
            <div>


                <div class="padding-sm" v-if="this.form.id === 0">
                    <el-select v-model="searchType" slot="prepend" placeholder="选择类型" size="mini" @change="changeSearch">
                        <el-option label="微信昵称" :value="1"></el-option>
                        <el-option label="UID" :value="2"></el-option>
                    </el-select>

                    <el-select
                            class="margin-l-sm"
                            v-model="studentName"
                            size="mini"
                            clearable
                            filterable
                            remote
                            reserve-keyword
                            @change="selectUser"
                            @clear="clearUser"
                            placeholder="请输入关键词"
                            :remote-method="searchStudent"
                            :loading="loading">
                        <el-option style="height: 40px;line-height: 40px"
                                   v-for="item in users"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                            <div style="display: flex;justify-content: start;align-items: center;height: 40px;line-height: 40px">
                                <span style="margin-right: 10px"> {{ item.id }} </span>
                                <el-avatar size="small" :src="item.avatar_url"></el-avatar>
                                <span style="margin-left: 10px;">{{ item.name }}</span>
                            </div>
                        </el-option>
                    </el-select>
                </div>


                <div class="margin-t-sm" v-if="notData">暂无数据</div>
                <div>
                    <div style="margin-top: 12px" >
                        <div v-if="JSON.stringify(selectCurrentUser) !== '{}'"
                             style="
					margin-top: 12px;
					display: flex;
					align-items: center;
					padding: 10px;
					border-radius: 4px;
					margin: 4px;
				">
                            <el-avatar size="small" :src="selectCurrentUser.avatar_url"></el-avatar>
                            <span style="margin-left: 4px">{{selectCurrentUser.name}}</span>
                        </div>

                        <el-form ref="form" label-position="left" label-width="120px"  :model="form" :rules="rules">

                            <el-form-item label="选择课程: " prop="selectCurrentCourse">
                                <select-course @onSelect="onSelectCourse"></select-course>
                            </el-form-item>

                            <template v-if="form.type === 'checkCourse'">
                            <el-form-item label="开始时间: " prop="start_at">
                                <el-date-picker value-format="yyyy-MM-dd hh:mm:ss" v-model="form.start_at" />
                            </el-form-item>

                            <el-form-item label="是否全部计划: " >
                                <el-switch v-model="form.is_all" :active-value="1" :inactive-value="0"  />
                            </el-form-item>

                            <template v-if="form.is_all === 0">
                                <el-form-item label="开始计划天数: " prop="start_plan_day">
                                    <el-input v-model.number="form.start_plan_day" />
                                </el-form-item>

                                <el-form-item label="结束计划天数: " prop="end_plan_day">
                                    <el-input v-model.number="form.end_plan_day" />
                                </el-form-item>
                            </template>

                            <el-form-item label="更新周期: " prop="update_day">
                                <el-checkbox-group v-model="form.update_day" size="small">
                                    <el-checkbox-button
                                            v-for="(city, index) in ['日','一','二','三','四','五','六']"
                                            :label="index"
                                            :key="city"
                                    >
                                        周{{ city }}
                                    </el-checkbox-button>
                                </el-checkbox-group>
                            </el-form-item>
                            </template>
                        </el-form>
                    </div>
                </div>

            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveUserCourse">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import SelectCourse from "./selectCourse";

    export default {
        name: "salesCourseUser",
        components: {SelectCourse},
        data(){
            return {
                imageUrl:config.imageUrl,
                dialogVisible:false,
                searchType:1,
                studentName:'',
                loading:false,
                selectCurrentUser:{},
                users:[],
                notData:'',
                form: {
                    id:0,
                    start_at: '',
                    is_all: 1,
                    type:'',
                    course_id:0,
                    user_id: 0,
                    start_plan_day: 0,
                    end_plan_day: 0,
                    update_day: [1,2,4,5],
                    selectCurrentCourse:null
                },
                rules: {
                    start_at: [{ required: true ,message: '请选择时间',trigger: 'blur'}],
                    user_id: [{ required: true,message: '请选择用户' }],
                    start_plan_day: [{ required: true,message: '请输入开始计划',trigger: 'blur'}],
                    update_day: [{ required: true,message: '选择更新周期' }],
                    end_plan_day: [{ required: true,message: '请输入结束计划' }],
                    selectCurrentCourse: [{ required: true,message: '请选择课程' }],
                },
                userCourseData:[],
                selectCurrentCourse:null,
                courseConfig:null
            }
        },
        methods:{
            ...mapActions('punch',['getCheckStudent','addCheckStudent']),
            ...mapActions('user',['getUserList']),
            ...mapActions('common',['delete']),
            handleClose(){

            },
            onSelectCourse(info){
                this.selectCurrentCourse = info
                this.form.selectCurrentCourse = info
                this.form.type = info.type
                this.form.course_id = info.data.id
                console.log(info)
            },
            changeSearch(){},
            selectUser(id){
                this.users.forEach(item => {
                    if(id === item.id){
                        this.selectCurrentUser.name = item.name
                        this.selectCurrentUser.id = item.id
                        this.selectCurrentUser.avatar_url = item.avatar_url
                    }
                })
                this.form.user_id = id
                console.log(this.form)
            },
            clearUser(){},
            searchStudent(v){
                console.log('searchStudent')
                let searchData = {}
                if(this.searchType === 1){
                    searchData.name = v
                    searchData.pageSize = 1000
                }else{
                    searchData.uid = v
                    searchData.pageSize = 1000
                }
                if(v  === ''){
                    this.$message.error('请输入昵称')
                    return
                }
                this.notData = false
                this.getUserList(searchData).then(res => {
                    if(res.data.list.length === 0){
                        this.notData = true
                    }
                    this.users = res.data.list
                })
            },
            saveUserCourse(){
                let data = {
                    user_id:this.selectCurrentUser.id,
                    user_name:this.selectCurrentUser.name,
                    user_name_avatar_url:this.selectCurrentUser.avatar_url,
                    course_type:this.selectCurrentCourse.type,
                    course_id:this.selectCurrentCourse.data.id,
                    course_name:this.selectCurrentCourse.data.name,
                    course_pic:this.selectCurrentCourse.data.pic,
                    start_at: this.form.start_at,
                    is_all: this.form.is_all,
                    start_plan_day:  this.form.start_plan_day,
                    end_plan_day: this.form.end_plan_day,
                    update_day: this.form.update_day,
                }

                this.$refs['form'].validate( async valid => {
                    if(valid){
                        this.$emit('onAdd',data)
                        this.dialogVisible = false
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>