import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getTeacherCourseList({commit},data){
        return (new Api()).getList(config.getTeacherCourseList,data)
    },
    addTeacherCourse({commit},data){
        return (new Api()).add(config.addTeacherCourse,data)
    },
    getTeacherCourseDetail({commit},id){
        return (new Api()).getList(config.getTeacherCourseDetail,{id:id})
    },
    addTeacherCourseChapter({commit},data){
        return (new Api()).add(config.addTeacherCourseChapter,data)
    },
    getTeacherCourseChapter({commit},data)
    {
        return (new Api()).getList(config.getTeacherCourseChapter,data)
    },
    setSort({commit},data){
        return (new Api()).getList(config.setSort,data)
    },
    getTeacherChapterList({commit},data){
        return (new Api()).getList(config.getTeacherChapterList,data)
    },
    addTeacherCourseClass({commit},data){
        return (new Api()).add(config.addTeacherCourseClass,data)
    },
    getTeacherCourseClassDetail({commit},id){
        return (new Api()).add(config.getTeacherCourseClassDetail,{id:id})
    },
    addTeacherCourseFile({commit},data){
        return (new Api()).add(config.addTeacherCourseFile,data)
    },
    getTeacherCourseFileList({commit},data){
        return (new Api()).getList(config.getTeacherCourseFileList,data)
    },
    delTeacherCourseFile({commit},id){
        return (new Api()).commonDelete('TeacherCourseFile',id)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
