<template>
    <div>
      <div class="main-title">转移列表</div>
      <div class="content-list-page page-content">
        <div class="list-head-box">
          <div data-v-69874ad0="" class="list-head">
            <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
            <ul data-v-69874ad0=""></ul>
          </div>
        </div>
  
        <div class="padding-t-sm padding-b-sm">
          <span class="fl-l">
            <el-input
              class="margin-l-sm"
              v-model="searchData.transferId"
              size="mini"
              style="width: 160px"
              placeholder="转让人ID"
              clearable
            >
            </el-input>
          </span>
  
          <span class="margin-l-sm fl-l">
            <el-input
              class="margin-l-sm"
              v-model="searchData.transferNickname"
              size="mini"
              style="width: 160px"
              placeholder="转让人昵称"
              clearable
            >
            </el-input>
          </span>
  
          <span class="margin-l-sm fl-l">
            <el-input
              class="margin-l-sm"
              v-model="searchData.receiverId"
              size="mini"
              style="width: 160px"
              placeholder="接收人ID"
              clearable
            >
            </el-input>
          </span>
  
          <span class="margin-l-sm fl-l">
            <el-input
              class="margin-l-sm"
              v-model="searchData.receiverNickname"
              size="mini"
              style="width: 160px"
              placeholder="接收人昵称"
              clearable
            >
            </el-input>
          </span>
  
          <span class="margin-l-sm fl-l">
            <el-date-picker
              size="small"
              v-model="searchData.datePick"
              format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </span>
  
          <span class="margin-l-sm fl-l">
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="search"
              >搜索</el-button
            >
          </span>
  
          <span class="fl-r"> </span>
  
          <div style="clear: both"></div>
        </div>
  
        <div class="table-content">
          <el-table
            :header-cell-style="{ padding: 0 }"
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            v-loading="loading"
            style="width: 100%; background: #fff"
          >
            <el-table-column label="ID" property="id" width="80">
            </el-table-column>
  
            <el-table-column label="转让人id" property="transfer_id">
            </el-table-column>
  
            <el-table-column label="转让人昵称" property="transfer_name">
            </el-table-column>
  
            <el-table-column label="接收人id" property="receiver_id">
            </el-table-column>
  
            <el-table-column label="接收人昵称" property="receiver_name">
            </el-table-column>
  
            <el-table-column label="转移时间" property="created_at">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link type="primary" @click="details(scope.row)">详情</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-batch">
          <span class="fl-r">
            <el-pagination
              small
              background
              :current-page="page"
              :page-sizes="[10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="setPageSize"
              @current-change="setPage"
              :total="total"
            >
            </el-pagination>
          </span>
          <div style="clear: both"></div>
        </div>
      </div>
  
      <el-dialog
        title="详情"
        :visible.sync="dialogVisible"
        width="60%"
        append-to-body
      >
        <div class="roll">
          <el-table
            :data="detailsData"
            style="width: 100%"
            v-loading="dialogVisibleLoading"
          >
            <el-table-column prop="courseId" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="name" label="课程名称"> </el-table-column>
            <el-table-column label="封面">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="imageUrl + scope.row.pic"
                ></el-image>
              </template>
            </el-table-column>
  
            <el-table-column label="是否全部计划">
              <template slot-scope="scope">
               <span>{{(scope.row.is_all == 1?'是':'否')}}</span>
              </template>
            </el-table-column>
  
            <el-table-column label="更新周期">
              <template slot-scope="scope">
               <span v-if="scope.row.type == 'checkCourse'">{{scope.row.update_day}}</span>
               <span v-else>无</span>
              </template>
            </el-table-column>
  
            <el-table-column label="开始天数-结束天数">
              <template slot-scope="scope">
               <span v-if="(scope.row.type == 'checkCourse'&&scope.row.is_all == 0 )">{{scope.row.start_plan_day}}---{{scope.row.end_plan_day}}</span>
               <span v-else>无</span>
              </template>
            </el-table-column>
  
            <el-table-column label="类型">
              <template slot-scope="scope">
                <span>{{
                  scope.row.type == "checkCourse" ? "打卡课程" : "普通课程"
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  export default {
    name: "list",
    data() {
      return {
        searchData: {},
        loading: false,
        tableData: [],
        imageUrl: config.imageUrl,
        page: 1,
        pageSize: 10,
        total: 0,
        dialogVisible: false,
        detailsData: [],
        dialogVisibleLoading: false,
      };
    },
    methods: {
      ...mapActions("user", ["transferList", "transferDetail"]),
      async details(row) {
        this.dialogVisibleLoading = true;
        this.dialogVisible = true;
        const { data } = await this.transferDetail({ id: row.id });
        this.detailsData = data;
        this.dialogVisibleLoading = false;
      },
      search() {
        this.tableData = [];
        this.page = 1;
        this.getList();
      },
      async getList() {
        this.loading = true;
        let form = {
          ...this.searchData,
          page: this.page,
          pageSize: this.pageSize,
        };
        const { data } = await this.transferList(form);
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      },
      setPageSize(pageSize) {
        this.pageSize = pageSize;
        this.getList();
      },
      setPage(page) {
        this.page = page;
        this.getList();
      },
    },
    mounted() {
      this.getList();
    },
  };
  </script>
  
  <style>
  .el-popover {
    min-width: 80px;
  }
  .roll {
    width: 100%;
    height: 400px;
    overflow: auto;
  }
  </style>
  