<template>
    <div v-loading="detailLoading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >销售数据</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/sales' }">报单</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini" >

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型：" label-position="left" class="" >
                            <el-select style="width: 100%" v-model="form.type" placeholder="请选择">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户昵称：" label-position="left" prop="nickname">
                            <el-input style="width: 100%"  v-model="form.nickname"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课程名称：" label-position="left" prop="course">
                            <el-input style="width: 100%"  v-model="form.course"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地区：" label-position="left" prop="area">
                            <el-input style="width: 100%"  v-model="form.area"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="支付方式：" label-position="left"  >
                            <el-select style="width: 100%" v-model="form.payType" placeholder="请选择">
                                <el-option
                                        v-for="item in payType"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="年级：" label-position="left"  prop="grade">
                            <el-select style="width: 100%" v-model="form.grade" placeholder="请选择">
                                <el-option
                                        v-for="item in grades"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="支付金额：" label-position="left" prop="price">
                            <el-input style="width: 100%" :disabled="check === 1 && $store.state.user.info.role !== 1 ? 'disabled' : null"  v-model.number="form.price"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮寄书本：" label-position="left" class="input" >
                            <el-switch v-model="form.isAddress" :active-value="1" :inactive-value="0">
                            </el-switch>
                            <span style="margin-left: 5px;font-size: 12px">{{form.isAddress === 1 ? '需要邮寄' : '不需要邮寄'}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="邮箱：" label-position="left"  >
                            <el-input style="width: 100%" resize="none"  :rows="5" v-model="form.email"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="收件人姓名：" v-show="form.isAddress === 1"  label-position="left" v-if="form.type !== 2" >
                            <el-input style="width: 100%" resize="none"  :rows="5" v-model="form.userName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="收件人电话：" v-show="form.isAddress === 1"  label-position="left" v-if="form.type !== 2" >
                            <el-input style="width: 100%" resize="none"  :rows="5" v-model="form.phone"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="收货地址：" v-show="form.isAddress === 1"  label-position="left" v-if="form.type !== 2" >
                            <el-input style="width: 100%" resize="none" type="textarea" :rows="5" v-model="form.address"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注：" label-position="left" >
                            <el-input style="width: 100%" resize="none" type="textarea" :rows="5" v-model="form.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="附件：" label-position="left" >
                            <paste-image upload-path="sales" @onSuccess="onSuccess" @onDelete="onDelete" :picArray="picArray"></paste-image>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col :span="12">
                        <el-form-item label="状态：" label-position="left" class="input" >
                            <el-switch v-model="form.status" :active-value="0" :inactive-value="1">
                            </el-switch>
                            <span style="margin-left: 5px;font-size: 12px">{{form.status === 0 ? '需要处理' : '不需要处理' }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开通课程：" label-position="left" class="input" >
                            <el-switch v-model="form.hasCourse" :active-value="1" :inactive-value="0" :disabled="courseStatus?true:false">
                            </el-switch>
                            <span style="margin-left: 5px;font-size: 12px">{{form.hasCourse === 0 ? '不需要开通课程' : '需要开通课程权限' }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>


                <template v-if="form.hasCourse === 1">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="开通信息：" label-position="left" >
                                <sales-course-user @onAdd="addUserCourse" v-if="courseStatus === 0 "></sales-course-user>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="" label-position="left" v-for="(userCourse,index) in form.userCourse" :key="index" >
                        <el-descriptions title="" size="mini" border :column="2">
                            <template slot="extra">
                                <el-button type="danger" size="small" :disabled="courseStatus?true:false" @click="deleteUserCourse(index)">删除</el-button>
                            </template>
                            <el-descriptions-item label="用户ID" >{{userCourse.user_id}}</el-descriptions-item>
                            <el-descriptions-item label="用户名">
                                <div><el-avatar size="small" :src="userCourse.user_name_avatar_url"></el-avatar></div>
                                <div>{{userCourse.user_name}}</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="课程ID" >{{userCourse.course_id}}</el-descriptions-item>
                            <el-descriptions-item label="课程名">
                                <div>
                                    <el-image style="width: 100px;"
                                              :src="imageUrl + userCourse.course_pic"
                                              fit="contain"></el-image>
                                </div>
                                <div>{{userCourse.course_name}}</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="课程类型"  :span="2">{{userCourse.course_type === 'checkCourse' ? '阅读日程' : '录播课程'}}</el-descriptions-item>

                            <template v-if="userCourse.course_type === 'checkCourse'">
                                <el-descriptions-item label="开始更新时间"  :span="2">{{userCourse.start_at}}</el-descriptions-item>
                                <el-descriptions-item label="是否全部计划" :span="2">
                                    <el-tag size="small" v-if="userCourse.is_all">是</el-tag>
                                    <el-tag size="small" type="danger" v-else>否</el-tag>
                                </el-descriptions-item>
                                <template v-if="!userCourse.is_all">
                                    <el-descriptions-item label="开始计划天数">{{userCourse.start_plan_day}}</el-descriptions-item>
                                    <el-descriptions-item label="结束计划天数">{{userCourse.end_plan_day}}</el-descriptions-item>
                                </template>
                                <el-descriptions-item label="更新周期"  :span="2">{{userCourse.update_day}}</el-descriptions-item>
                            </template>

                        </el-descriptions>
                    </el-form-item>
                </template>

                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/sales" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import Cover from "../../../components/cover";
    import PasteImage from "../../../components/pasteImage/index";
    import SalesCourseUser from "../../../components/sales/salesCourseUser";
    export default {
        name: "template-add",
        components:{SalesCourseUser, PasteImage},
        data() {
            return {
                imageUrl:config.imageUrl,
                detailLoading:false,
                statusTxt:'上架',
                loading:false,
                picArray:[],
                check:0,
                courseStatus:0,
                form: {
                    id:0,
                    type: 1,
                    pic:[],
                    nickname:'',
                    course:'',
                    price:0,
                    phone:'',
                    email:'',
                    userName:'',
                    address:'',
                    remark:'',
                    payType:2,
                    refundAccount:'',
                    status:0,
                    hasCourse:1,
                    grade:'',
                    area:'',
                    isAddress:1,
                    userCourse:[],
                },

                grades:[
                    {
                        value: '一年级',
                        label: '一年级'
                    },
                    {
                        value: '二年级',
                        label: '二年级'
                    },
                    {
                        value: '三年级',
                        label: '三年级'
                    },
                    {
                        value: '四年级',
                        label: '四年级'
                    },
                    {
                        value: '五年级',
                        label: '五年级'
                    },
                    {
                        value: '六年级',
                        label: '六年级'
                    },
                    {
                        value: '七年级',
                        label: '七年级'
                    },
                    {
                        value: '八年级',
                        label: '八年级'
                    },
                    {
                        value: '九年级',
                        label: '九年级'
                    },
                ],
                rules: {
                    nickname: [
                        { required: true, message: '请输入用户昵称', trigger: 'blur' },
                        { min: 2, max: 24, message: '长度在 2 到 24 个字符', trigger: 'blur' }
                    ],
                    course: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' }
                    ],
                    price: [
                        {  required: true, message: '请输入支付金额', trigger: 'blur' },
                        {type: 'number', message: '请输入正确的金额', trigger: 'blur'}
                    ],
                    grade: [
                        { required: true, message: '请选择年级', trigger: 'blur' }
                    ],
                    area: [
                        { required: true, message: '请填写地区', trigger: 'blur' }
                    ],

                },
                payType:[
                    {
                        value: 1,
                        label: '小程序支付'
                    },
                    {
                        value: 2,
                        label: '微信对公账户'
                    },
                    {
                        value: 3,
                        label: '支付宝支付'
                    },
                    {
                        value: 4,
                        label: '短书购买'
                    },
                ],
                options: [
                    {
                        value: 1,
                        label: '新签'
                    },
                    {
                        value: 2,
                        label: '退费'
                    },
                    {
                        value: 3,
                        label: '转介绍'
                    },
                    {
                        value: 4,
                        label: '扩科目'
                    },
                    {
                        value: 5,
                        label: '续费'
                    },
                    {
                        value: 6,
                        label: '百度'
                    },
                    {
                        value: 7,
                        label: '头条'
                    },

                ],

            }
        },
        methods: {
            ...mapActions('sales',['addSales','getSalesDetail','getSalesUserCourseDetail']),
            uploadSuccess(path){
                this.form.pic = path
            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
            async onSubmit() {
                console.log(this.form)

                if(!this.validateForm('form'))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }

                let _this = this
                this.loading = true

                await this.addSales(this.form).then( res => {
                    if(res.ret == 0){
                        _this.$message.success('修改成功！')
                        _this.$router.push('/sales')
                    }
                }).finally(()=>{
                    this.loading = false
                })
            },
            onSuccess(pic){
                this.form.pic = pic
            },
            onDelete(pic){
                this.form.pic = pic
            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            addUserCourse(e){
                this.form.userCourse.push(e)
            },
            deleteUserCourse(i){
                this.form.userCourse.splice(i,1)
            }
        },
        async mounted() {
            this.detailLoading = true
            let id =  this.$route.params.id
            this.form.id = id
            let detail = await this.getSalesDetail(id)
            let res = await this.getSalesUserCourseDetail({id:id})

            this.form.userCourse = res.data

            if(detail.ret === 0){
                this.form.type = detail.data.type
                this.form.pic = detail.data.pic
                this.form.nickname = detail.data.nickname
                this.form.course = detail.data.course
                this.form.price = detail.data.price
                this.form.phone = detail.data.phone
                this.form.userName = detail.data.user_name
                this.form.address = detail.data.address
                this.form.remark = detail.data.remark
                this.form.payType = detail.data.pay_type
                this.form.status = detail.data.status
                this.form.grade = detail.data.grade
                this.form.area = detail.data.area
                this.form.email = detail.data.email
                this.form.hasCourse = detail.data.has_course
                this.form.isAddress = detail.data.is_address
                this.picArray = detail.data.pic
                this.check = detail.data.check
                this.courseStatus = detail.data.course_status
                console.log(detail.data.check)
            }
            this.detailLoading = false
        }
    }
</script>

<style scoped>

</style>
