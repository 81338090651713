<template>
	<div class="left-center left-nav" v-bind:style="{ width: leftWidth + 'px' }">
		<div style="display: flex; justify-content: center">
			<img v-if="!status" class="logo" width="155" height="55" src="../img/logo1.png" />

			<img v-else class="logo2" style="padding: 5px" width="50" height="60" src="../img/logo.png" />
		</div>

		<el-menu router :collapse="status" :collapse-transition="false" :default-active="activeIndex"
			class="el-menu-vertical-demo" style="border: 0" @select="selectMenu">
			<el-submenu index="1" :class="subMenuClass" v-if="this.role == 1">
				<template slot="title">
					<i class="icon-menu-overview"></i>
					<span class="item-title">基础设置</span>
				</template>
				<el-menu-item index="/slideShow"> 轮播图设置 </el-menu-item>
			</el-submenu>
			<el-submenu index="2" :class="subMenuClass" v-if="this.role == 1 || this.role == 3">
				<template slot="title">
					<i class="icon-menu-content-active"></i>
					<span class="item-title">内容</span>
				</template>
				<el-menu-item index="/excerpt"> 美文摘抄 </el-menu-item>
				<el-menu-item index="/fileDownload"> 资料下载 </el-menu-item>
			</el-submenu>
			<el-submenu index="3" :class="subMenuClass" v-if="this.role == 1">
				<template slot="title">
					<i class="icon-menu-course"></i>
					<span class="item-title">课堂</span>
				</template>
				<el-menu-item index="/course"> 我的课程 </el-menu-item>
				<el-menu-item index="/checkCourse"> 打卡课程 </el-menu-item>
			</el-submenu>
			<el-submenu index="4" :class="subMenuClass"
				v-if="this.role == 1 || this.role == 2 || this.role == 5 || this.role == 4 || this.role == 3">
				<template slot="title">
					<i class="el-icon-s-order"></i>
					<span class="item-title">教学</span>
				</template>
				<el-menu-item index="/work"> 作业点评 </el-menu-item>
			</el-submenu>
			<el-submenu index="5" :class="subMenuClass" v-if="this.role == 1 || this.role == 4 || this.role == 6">
				<template slot="title">
					<i class="icon-menu-manage"></i>
					<span class="item-title">用户</span>
				</template>
				<el-menu-item index="/userList" v-if="this.role == 1">
					用户列表
				</el-menu-item>
				<el-menu-item index="/userSocial"> 用户动态 </el-menu-item>
				<el-menu-item index="/transfer" v-if="this.role == 1">
          转移记录
        </el-menu-item>
			</el-submenu>
			<el-submenu index="6" :class="subMenuClass" v-if="this.role == 1 || this.role == 3">
				<template slot="title">
					<i class="el-icon-folder"></i>
					<span class="item-title">资源素材</span>
				</template>
				<el-menu-item index="/file"> 文件 </el-menu-item>
				<el-menu-item index="/video"> 视频 </el-menu-item>
				<!--				<el-menu-item index="/question"> 题库 </el-menu-item>-->
				<el-menu-item index="4-3">图片</el-menu-item>
			</el-submenu>
			<el-submenu index="7" :class="subMenuClass" v-if="this.role == 1">
				<template slot="title">
					<i class="icon-menu-application"></i>
					<span class="item-title">运营和营销</span>
				</template>
				<el-menu-item index="/discount"> 优惠券 </el-menu-item>
				<el-menu-item index="/vip"> 会员卡 </el-menu-item>
				<el-menu-item index="/promotion"> 组合购 </el-menu-item>
				<el-menu-item index="/groupBuying"> 团购活动 </el-menu-item>
				<!--                            <el-menu-item index="/file">-->
				<!--                                <router-link to="/file">秒杀活动</router-link>-->
				<!--                            </el-menu-item>-->
				<!--                            <el-menu-item index="/file">-->
				<!--                                <router-link to="/file">团购活动</router-link>-->
				<!--                            </el-menu-item>-->
				<!--                            <el-menu-item index="/file">-->
				<!--                                <router-link to="/file">团购</router-link>-->
				<!--                            </el-menu-item>-->
			</el-submenu>
			<el-submenu index="8" :class="subMenuClass" v-if="this.role == 1">
				<template slot="title">
					<i class="icon-menu-mobile"></i>
					<span class="item-title">商城管理</span>
				</template>
				<el-menu-item index="/goods"> 商品列表 </el-menu-item>
				<el-menu-item index="/order"> 订单列表 </el-menu-item>
				<el-menu-item index="/lose"> 丢件截图 </el-menu-item>
			</el-submenu>
			<el-submenu index="9" :class="subMenuClass" v-if="this.role == 1 || this.role == 5">
				<template slot="title">
					<i class="el-icon-data-analysis"></i>
					<span class="item-title">
						销售管理
						<i v-if="this.$store.state.user.info.untreated_nums>0" style="width: 5px;height: 5px;position: absolute;right: 10px;top: 10px;display: block;border-radius: 50%;background: #f00"></i>
					</span>
					<i v-if="this.$store.state.user.info.untreated_nums>0" style="width: 5px;height: 5px;position: absolute;right: -5px;top: 10px;display: block;border-radius: 50%;background: #f00"></i>
				</template>
				<el-menu-item index="/promotion"> 组合购 </el-menu-item>
				<el-menu-item index="/sales"> 报单 </el-menu-item>
				<el-menu-item index="/transfer">转移记录</el-menu-item>
				<el-menu-item index="/expressage"> 快递查询 </el-menu-item>
				<el-menu-item index="/salesStatistics" v-if="this.role == 1">
					统计
				</el-menu-item>
				<el-menu-item index="/salesStatisticsChart" v-if="this.role == 1">
					统计图表
				</el-menu-item>
				<el-menu-item index="/power"> 权限变更 <span style="float: right;color: red;"><el-tag type="danger" size="mini"><b>{{this.$store.state.user.info.untreated_nums>99? '99+':this.$store.state.user.info.untreated_nums}}</b></el-tag></span></el-menu-item>
			</el-submenu>
			<el-submenu index="10" :class="subMenuClass" v-if="this.role == 1 || this.role == 6 || this.role == 2">
				<template slot="title">
					<i class="el-icon-school"></i>
					<span class="item-title">教研中心</span>
				</template>
				<el-menu-item index="/teacherCourse"> 课程 </el-menu-item>
			</el-submenu>
			<!--			<el-submenu index="11" :class="subMenuClass" v-if="this.role == 1">-->
			<!--				<template slot="title">-->
			<!--					<i class="icon-menu-course"></i>-->
			<!--					<span class="item-title">专栏</span>-->
			<!--				</template>-->
			<!--				<el-menu-item index="/specialColumn"> 专栏设置 </el-menu-item>-->
			<!--			</el-submenu>-->

			<el-submenu index="12" :class="subMenuClass"
				v-if="this.role == 1 || this.role == 5 || this.role == 6 || this.role == 2">
				<template slot="title">
					<i class="el-icon-edit"></i>
					<span class="item-title">班级管理</span>
				</template>
				<el-menu-item index="/classList"> 班级列表 </el-menu-item>
			</el-submenu>
		</el-menu>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	props: {
		menuStatus: {
			default: false
		}
	},
	name: 'left',
	data() {
		return {
			activeIndex: '',
			role: 0,
			userId: 0,
			status: true,
			leftWidth: 60,
			subMenuClass: 'el-menu-item'
		}
	},
	watch: {
		menuStatus() {
			console.log(this.menuStatus)
			this.status = this.menuStatus

			if (this.status) {
				this.leftWidth = 60
				this.subMenuClass = 'el-menu-item'
			} else {
				this.leftWidth = 230
				this.subMenuClass = ''
			}
		}
	},
	methods: {
		...mapActions('user', ['getUserInfo']),
		selectMenu() {
			// console.log(index)
			// console.log(indexPath)
		}
	},
	mounted() {
		this.getUserInfo().then(res => {
			this.role = res.data.role
			this.userId = res.data.id
			this.$cookies.set('userId', this.userId)
		})
		this.activeIndex = this.$router.history.current.fullPath
	}
}
</script>

<style>
.el-menu-left-side .el-submenu .el-submenu__title {
	padding: 0;
}

.el-menu-item a {
	display: inline-block;
	width: 100%;
	color: #303133 !important;
}

.hide-menu {
	width: 60px;
}

.show-menu {
	width: 230px;
}

.item-title {
	font-size: 14px;
}

.el-aside {
	overflow: auto !important;
}

.el-aside::-webkit-scrollbar {
	width: 0 !important;
}
</style>
