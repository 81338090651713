<template>
    <div v-loading="loading">
        <div class="main-title">打卡课程</div>

        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0 class="list-head">
                    <p data-v-69874ad0 class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0>
                        <li data-v-69874ad0>XXXXXX</li>
                    </ul>
                </div>
            </div>

            <div class="margin-sm">
                <div>
                    <el-input
                            style="width: 240px"
                            v-model="name"
                    />&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-button size="mini" type="primary" @click="getPunchData">
                        筛选
                    </el-button>
                    &nbsp;&nbsp;&nbsp;&nbsp;

                    <el-button
                            size="mini"
                            type="primary"
                            @click="$router.push('/checkCourse/add')"
                    >
                        新增
                    </el-button>
                </div>

                <el-table :data="tableData">
                    <el-table-column prop="id" label="id" />

                    <el-table-column prop="name" label="名称" />

                    <el-table-column prop="amount" label="金额" />

                    <el-table-column prop="indate" label="有效期" />

                    <el-table-column label="是否上架">
                        <template slot-scope="scope">
                            <el-switch
                                    :active-value="1"
                                    :inactive-value="0"
                                    v-model="scope.row.status"
                                    @change="switchStatus(scope.row)"
                            />
                        </template>
                    </el-table-column>

                    <el-table-column label="封面">
                        <template slot-scope="scope">
                            <el-image
                                    class="table-item-img"
                                    :src="imageUrl + scope.row.pic"
                                    :preview-src-list="[imageUrl + scope.row.pic]"
                            />
                        </template>
                    </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    type="text"
                                    @click="
									$router.push({
										path: '/checkCourse/detail/'+scope.row.id+'/manage',
									})
								">详情</el-button>
                            -
                            <el-link type="primary" @click="
									$router.push({
										path: '/checkCourse/edit/'+scope.row.id,
									})">编辑</el-link>
                            -
                            <el-Popconfirm
                                    title="确定要删除吗"
                                    @confirm="deletePunchMethod(scope.row)"
                            >
                                <el-button type="text" slot="reference">删除</el-button>
                            </el-Popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="table-batch">
					<span class="fl-r">
						<el-pagination
                                small
                                background
                                :current-page="page"
                                :page-sizes="[10, 20]"
                                :page-size="pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                @size-change="setPageSize"
                                @current-change="setPage"
                                :total="total"
                        >
						</el-pagination>
					</span>
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { config } from '@/js/config'

    export default {
        data: () => ({
            tableData: [],
            total: 0,
            loading: false,
            imageUrl: config.imageUrl,
            name: '',
            pageSize: 10,
            page: 1
        }),

        methods: {
            ...mapActions('punch', [
                'getPunchList',
                'deletePunch',
                'switchDetailStatus'
            ]),

            async getPunchData() {
                this.loading = true

                const { data } = await this.getPunchList({
                    name: this.name,
                    pageSize: this.pageSize,
                    page: this.page
                })

                this.loading = false

                this.tableData = data.list
                this.total = data.total
            },

            setPageSize(value) {
                this.pageSize = value
                this.getPunchData()
            },

            setPage(value) {
                this.page = value
                this.getPunchData()
            },

            async switchStatus({ status, id }) {
                this.loading = true

                await this.switchDetailStatus({
                    model: 'CheckCourseModel',
                    id,
                    status
                })

                this.loading = false

                this.getPunchData()
            },

            async deletePunchMethod({ id }) {
                this.loading = true
                await this.deletePunch({ model: 'CheckCourse', id })

                this.loading = false
                this.getPunchData()
            }
        },

        created() {
            this.getPunchData()
        }
    }
</script>
