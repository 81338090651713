<template>
    <div>
        <div v-show="isReset === false">
            <div class="select-product">
                <el-tag class="margin-r-sm margin-b-sm" size="mini" v-for="product in selectProduct" :key="product.id">{{product.name}}</el-tag>
            </div>
            <el-button class="margin-t-sm" @click="afreshSelect">重新选择</el-button>
        </div>
        <div v-show="isReset === true">
            <select-product  ref="selectProduct" @onSelect="onSelect"></select-product>
        </div>
    </div>
</template>

<script>
    import SelectProduct from "./selectProduct";
    export default {
        name: "editProduct",
        components: {SelectProduct},
        props:{
            selectProduct:{
                type:Array,
                default(){
                   return []
                }
            }
        },
        data(){
            return {
                isReset:false
            }
        },
        methods:{
            afreshSelect(){
                this.isReset = true
                this.$refs.selectProduct.$el.getElementsByClassName('select-product-btn').item(0).click()
                this.$emit('resetSelect','')
            },
            onSelect(e){
                this.$emit('onSelect',e)
            }
        }
    }
</script>

<style>
    .select-product{
        display: flex;
        flex-wrap:wrap;
    }

</style>